import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, isRoleChecked } from '../utils/Utils';

//=========================================================================

const MySiteLine = ( { width, actionsColumnWidth=60, rowIndex, canEdit, canDelete, theSite, ownerName, ownerEmail, ownerPhone, onActionPressed, language } ) => {

    return (
        <View style={{width:width,height:34,marginTop:0,marginLeft:0}} >

            <View style={{width:width,height:32,marginTop:2,marginLeft:0,borderRadius:5,
                }} >
                
                <TouchableOpacity style={{width:width,height:32,marginTop:0,position:'absolute',flexDirection:'row'}}
                    onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit}
                    >

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theSite.siteName}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{ownerName}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{ownerPhone}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{ownerEmail}</Text>

                    </View>

                    <View style={{width:90,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: (theSite.hasStudies)?'#3f3f3f':'#f00',
                            //backgroundColor:'#0f0',
                            }} >{LocStr(theSite.hasStudies?'1 or more':'No studies',language)}</Text>

                    </View>

                    <View style={{width:actionsColumnWidth,height:32,flexDirection:'row',backgroundColor:'#fff'}} key={'actions'} >
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit} >
                            <Feather name="edit-2" style={{fontSize: 16,position: 'absolute',color: canEdit?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        {(actionsColumnWidth>30)?
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('delete',rowIndex);}} disabled={!canDelete} >
                            <Feather name="trash-2" style={{fontSize: 16,position: 'absolute',color: canDelete?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                        :null}

                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default MySiteLine;