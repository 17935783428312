import createDataContext from './createDataContext';
import healablesServer from '../api/healablesServer';
import { logout, signIn } from '../api/firebase';

const healablesServerReducer = (state, action) => {
    switch (action.type)
    {
        case 'get_all_protocols':
            return action.payload;
        case 'get_protocol_data':
            return action.payload;
        case 'post_create':
            return action.payload;
        case 'post_update_protocol':
            return action.payload;
        case 'post_delete_protocol':
            return action.payload;

        case 'post_login':
            return action.payload;    
        case 'post_logout':
            return action.payload;

        case 'get_all_users':
            return action.payload;
        case 'post_create_user':
            return action.payload;
        case 'post_update_user':
            return action.payload;
        case 'post_delete_user':
            return action.payload;
        case 'post_invite_user':
            return action.payload;
        case 'post_reset_password_user':
            return action.payload;
        case 'post_signup_user':
            return action.payload;

        case 'get_all_studies':
            return action.payload;    
        case 'post_create_study':
            return action.payload;    
        case 'post_update_study':
            return action.payload;    
        case 'post_delete_study':
            return action.payload;    

        case 'get_all_sites':
            return action.payload;    
        case 'get_my_sites':
            return action.payload;    
        case 'post_create_site':
            return action.payload;    
        case 'post_update_site':
            return action.payload;    
        case 'post_delete_site':
            return action.payload;    

        case 'post_update_site_subjects':
            return action.payload;    
        
        case 'post_create_subject':
            return action.payload;    
        case 'post_update_subject':
            return action.payload;    
        case 'get_subject_analgesics':
            return action.payload;    
            
        default:
            return state;
    }
};

//#############################################################################################
//#############################################################################################

const getAllProtocolsHeaders = (dispatch) => {

    return async (token, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/allProtocolsHeaders', options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(allProtocols): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_all_protocols', payload: response.data.allProtocolsHeaders });
                        if (callbackOk) callbackOk(response.data.allProtocolsHeaders);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get protocols, please try again...");
    }
};

//=======================================================================

const getProtocolData = (dispatch) => {

    return async (token, protocolId, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            //console.log('dbg: ### PARAM(get protocol data): '+JSON.stringify(protocolId));

            const response = await healablesServer.get('/protocolData/'+protocolId, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP: '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_protocol_data', payload: response.data.protocolData });
                        if (callbackOk) callbackOk(response.data.protocolData);
                        return;
                    } else {
                        let msg = translateError(response.data.error, protocolId);  // get protocol data
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get protocol data, please try again...");
    }
};

//=======================================================================

const createProtocol = (dispatch) => {

    return async (token, protocolInfo, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/protocol/create', 
                protocolInfo,
                options);

            //console.log('dbg: ### PARAM(create): '+JSON.stringify(protocolInfo));

            if (response !== null)
            {
                //console.log('dbg: ### RESP(create): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_create', payload: response.data.protocolId });
                        if (callbackOk) callbackOk(response.data.protocolId);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // create protocol
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not create protocol, please try again...");
    }
};

//=======================================================================

const updateProtocol = (dispatch) => {

    return async (token, protocolId, newProtocol, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            //console.log('dbg: ### PARAM(update): '+JSON.stringify(newProtocol));

            var cmd = '/protocol/update/'+protocolId;

            const response = await healablesServer.post(cmd,newProtocol,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_protocol', payload: response.data.protocol });
                        if (callbackOk) callbackOk(response.data.protocol);
                        return;
                    } else {
                        let msg = translateError(response.data.error, protocolId);  // update protocol
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update protocol, please try again...");
    }
};

//=======================================================================

const deleteProtocol = (dispatch) => {

    return async (token, protocolId, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/protocol/delete/'+protocolId, {}, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(delete): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_delete_protocol', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error, protocolId);  // delete protocol
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not delete protocol, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const loginUserAtHealables = (dispatch) => {
    return async (email, password, callbackOk, callbackErr) => {

        try {
            const user = await signIn(email, password)
            dispatch({ type: 'post_login', payload: user });
            if (callbackOk) callbackOk(user);
            return;
        } catch (error) {
            if (callbackErr) callbackErr(error.message ?? `${error}`);
            return;
        }

    }

    return async (email, password, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                }
            }

            const params = {
                email: email,
                password: password,
            }

            const response = await healablesServer.post('/user/login', params, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(loginUserAtHealables): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_login', payload: response });
                        if (callbackOk) callbackOk(response);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not login user, please try again...");
    }
};

//=============================================================================================

const logoutUserAtHealables = (dispatch) => {


    return async (token, callbackOk, callbackErr) => {

        try {
            await logout()
            dispatch({ type: 'post_logout', payload: 'ok' });
            if (callbackOk) callbackOk('ok');
            return;
        } catch (error) {
            if (callbackErr) callbackErr(error.message ?? `${error}`);
            return;
        }

    }

    return async (token, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/user/logout', {}, options);

            //console.log('dbg: ### RESP(logoutUserAtHealables): '+JSON.stringify(response));

            if (response !== null)
            {
                if (response.status !== null)
                {
                    if (response.status == 200)
                    {
                        dispatch({ type: 'post_logout', payload: 'ok' });
                        if (callbackOk) callbackOk('ok');
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {

            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not logout user, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const getAllUsersAtHealables = (dispatch) => {

    return async (token, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/users/allUsers', options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(allusers): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_all_users', payload: response.data.allUsersDocs });
                        if (callbackOk) callbackOk(response.data.allUsersDocs);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get users, please try again...");
    }
};

//=======================================================================

const createUserAtHealables = (dispatch) => {

    return async (token, userInfo, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                firstName:  userInfo.userFirstname,
                lastName:   userInfo.userLastname,
                email:      userInfo.userEmail,
                phone:      userInfo.userPhone,
                role:       userInfo.userRoles,
            }

            const response = await healablesServer.post('/user/create', params, options);

            //console.log('dbg: ### PARAM(create user): '+JSON.stringify(params));

            if (response !== null)
            {
                //console.log('dbg: ### RESP(create user): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        //console.log('dbg: ### RES(create user): '+JSON.stringify(response.data._id));
                        dispatch({ type: 'post_create_user', payload: response.data._id });
                        if (callbackOk) callbackOk(response.data._id);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not create user, please try again...");
    }
};

//=======================================================================

const updateUserAtHealables = (dispatch) => {

    return async (token, userId, newUser, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                firstName:  newUser.userFirstname,
                lastName:   newUser.userLastname,
                email:      newUser.userEmail,
                phone:      newUser.userPhone,
                role:       newUser.userRoles,
            }

            //console.log('dbg: ### PARAM(update): '+JSON.stringify(params));

            var cmd = '/user/update/'+userId;

            const response = await healablesServer.post(cmd,params,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update user): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_user', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update user, please try again...");
    }
};

//=======================================================================

const deleteUserAtHealables = (dispatch) => {

    return async (token, userId, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/user/delete/'+userId, {}, options);

            //console.log('dbg: ### PARAM(delete user): '+'/user/delete/'+userId);

            if (response !== null)
            {
                //('dbg: ### RESP(delete): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_delete_user', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not delete user, please try again...");
    }
};

//=======================================================================

const inviteUserAtHealables = (dispatch) => {

    return async (token, userId, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/user/invite/'+userId, {}, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(invite): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_invite_user', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // invite user
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not invite user, please try again...");
    }
};

//=======================================================================

const resetPasswordAtHealables = (dispatch) => {

    return async (userEmail, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    //'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/user/resetPassword', { email: userEmail }, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(resetPassword): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_reset_password_user', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // invite user
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not reset password, please try again...");
    }
};

//=======================================================================

const signupUserAtHealables = (dispatch) => {

    return async (inviteToken, newPassword, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'first-auth': inviteToken 
                }
            }

            //console.log('dbg: ### REQ(signup): '+inviteToken+', '+newPassword);

            const response = await healablesServer.post('/user/signup', { password: newPassword}, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(signup): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_signup_user', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // invite user
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not signup user, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const getAllStudiesAtHealables = (dispatch) => {

    return async (token, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/study/readAll', options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(allstudies): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_all_studies', payload: response.data.studies });
                        if (callbackOk) callbackOk(response.data.studies);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get studies, please try again...");
    }
};

//=======================================================================

const createStudyAtHealables = (dispatch) => {

    return async (token, studyInfo, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                briefTitle:       studyInfo.studyShortName,
                officialTitle:    studyInfo.studyFullName,
                uniqueProtocolId: studyInfo.studyUniqueProtocolId,
                sites:            studyInfo.studySites,
                metadata:         studyInfo.studyMetadata,
            }

            const response = await healablesServer.post('/study/create', params, options);

            //console.log('dbg: ### PARAM(create study): '+JSON.stringify(params));

            if (response !== null)
            {
                //console.log('dbg: ### RESP(create study): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        //console.log('dbg: ### RES(create user): '+JSON.stringify(response.data._id));
                        dispatch({ type: 'post_create_study', payload: response.data.studyId });
                        if (callbackOk) callbackOk(response.data.studyId);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not create study, please try again...");
    }
};

//=======================================================================

const updateStudyAtHealables = (dispatch) => {

    return async (token, studyId, newStudy, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                briefTitle:       newStudy.studyShortName,
                officialTitle:    newStudy.studyFullName,
                uniqueProtocolId: newStudy.studyUniqueProtocolId,
                sites:            newStudy.studySites,
                metadata:         newStudy.studyMetadata,
            }

            //console.log('dbg: ### PARAM(update): '+JSON.stringify(params));

            var cmd = '/study/update/'+studyId;

            const response = await healablesServer.post(cmd,params,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update study): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_study', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update study, please try again...");
    }
};

//=======================================================================

const deleteStudyAtHealables = (dispatch) => {

    return async (token, studyId, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/study/delete/'+studyId, {}, options);

            //console.log('dbg: ### PARAM(delete study): '+'/study/delete/'+userId);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(delete): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_delete_study', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not delete study, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const getMySitesAtHealables = (dispatch) => {

    return async (token, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/site/readMy', options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(my sites): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_my_sites', payload: response.data.mySites });
                        if (callbackOk) callbackOk(response.data.mySites);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get my sites, please try again...");
    }
};

//=======================================================================

const getAllSitesAtHealables = (dispatch) => {

    return async (token, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/site/readAll', options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(allSites): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_all_sites', payload: response.data.sites });
                        if (callbackOk) callbackOk(response.data.sites);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get sites, please try again...");
    }
};

//=======================================================================

const createSiteAtHealables = (dispatch) => {

    return async (token, siteInfo, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                siteName:  siteInfo.siteName,
                siteOwner: siteInfo.siteOwnerId,
                subjects:  siteInfo.siteSubjects,
            }

            const response = await healablesServer.post('/site/create', params, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(create site): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_create_site', payload: response.data.siteId });
                        if (callbackOk) callbackOk(response.data.siteId);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not create site, please try again...");
    }
};

//=======================================================================

const updateSiteAtHealables = (dispatch) => {

    return async (token, siteId, newSite, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                siteName:  newSite.siteName,
                siteOwner: newSite.siteOwnerId,
                subjects:  newSite.siteSubjects,
            }

            var cmd = '/site/update/'+siteId;

            const response = await healablesServer.post(cmd,params,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update site): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_site', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update site, please try again...");
    }
};

//=======================================================================

const deleteSiteAtHealables = (dispatch) => {

    return async (token, siteId, callbackOk, callbackErr) => {
        
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.post('/site/delete/'+siteId, {}, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(delete): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_delete_site', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not delete site, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const updateSiteSubjectsAtHealables = (dispatch) => {

    return async (token, siteId, newSite, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                subjects:  newSite.siteSubjects,
            }

            var cmd = '/site/updateSubjects/'+siteId;

            const response = await healablesServer.post(cmd,params,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update site subjects): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_site_subjects', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update site subjects, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const createSubjectAtHealables = (dispatch) => {

    return async (token, userInfo, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                firstName: userInfo.userFirstname,
                lastName:  userInfo.userLastname,
                email:     userInfo.userEmail,
                phone:     userInfo.userPhone,
                role:      'subject',   //userInfo.userRoles,
                analgesic: userInfo.userAnalgesics,
            }

            const response = await healablesServer.post('/user/createSubject', params, options);

            //console.log('dbg: ### PARAM(create subject): '+JSON.stringify(params));

            if (response !== null)
            {
                //console.log('dbg: ### RESP(create subject): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        //console.log('dbg: ### RES(create subject): '+JSON.stringify(response.data._id));
                        dispatch({ type: 'post_create_subject', payload: response.data._id });
                        if (callbackOk) callbackOk(response.data._id);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not create subject, please try again...");
    }
};

//=======================================================================

const updateSubjectAtHealables = (dispatch) => {

    return async (token, userId, newUser, callbackOk, callbackErr) => {
        try {
            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            let params = {
                firstName: newUser.userFirstname,
                lastName:  newUser.userLastname,
                email:     newUser.userEmail,
                phone:     newUser.userPhone,
                role:      newUser.userRoles,
                analgesic: newUser.userAnalgesics,
            }

            //console.log('dbg: ### PARAM(update subject): '+JSON.stringify(params));

            var cmd = '/user/updateSubject/'+userId;

            //console.log('dbg: ### cmd(update subject): '+cmd);

            const response = await healablesServer.post(cmd,params,options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(update subject): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'post_update_subject', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        let msg = translateError(response.data.error);
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not update subject, please try again...");
    }
};

//=======================================================================

const getSubjectAnalgesicsAtHealables = (dispatch) => {

    return async (token, subjectId, callbackOk, callbackErr) => {
        try {

            const options = {
                headers: { 
                    'authorization': 'Bearer ' + token 
                }
            }

            const response = await healablesServer.get('/user/analgesics/'+subjectId, options);

            if (response !== null)
            {
                //console.log('dbg: ### RESP(get subject analgesics): '+JSON.stringify(response));
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        dispatch({ type: 'get_subject_analgesics', payload: response.data.analgesics });
                        if (callbackOk) callbackOk(response.data.analgesics);
                        return;
                    } else {
                        let msg = translateError(response.data.error);  // get all headers
                        if (msg!=null)
                        {
                            if (callbackErr) callbackErr(msg);
                            return;
                        }
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data.errmsg);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not get subject analgesics, please try again...");
    }
};

//#############################################################################################
//#############################################################################################

const translateError = (src, protocolId=null) => {
    if (src.indexOf('protocol not found')!==-1)
    {
        if (protocolId===null)
        {
            return src;
        } else {
            return src+protocolId;
        }
    }
    if (src.indexOf('E11000 duplicate key')!==-1)
    {
        return 'Protocol number taken.\n\nPlease use another one.';
    }
    if (src.endsWith('is currently editing this doc'))
    {
        return 'This protocol is currently being edited by\n\n'+src.substring(0,src.length-30);
    }
    return null;
}

//=======================================================================

export const { Context, Provider } = createDataContext( 
    healablesServerReducer,
    {   
        loginUserAtHealables, logoutUserAtHealables, resetPasswordAtHealables, inviteUserAtHealables, signupUserAtHealables, 
        
        getAllUsersAtHealables, updateUserAtHealables, createUserAtHealables, deleteUserAtHealables,

        getAllStudiesAtHealables, updateStudyAtHealables, createStudyAtHealables, deleteStudyAtHealables,

        getMySitesAtHealables, getAllSitesAtHealables, updateSiteAtHealables, createSiteAtHealables, deleteSiteAtHealables, updateSiteSubjectsAtHealables,

        createSubjectAtHealables, updateSubjectAtHealables, getSubjectAnalgesicsAtHealables,

        getAllProtocolsHeaders, getProtocolData, createProtocol, updateProtocol, deleteProtocol }, 
    [] 
);