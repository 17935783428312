import React, { useContext, useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Dimensions, Image, Text, ActivityIndicator, TouchableOpacity, ShadowPropTypesIOS } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyMessagePopup from '../components/MyMessagePopup';
import MyEditText from '../components/MyEditText';
import { LocStr } from '../utils/Utils'
import { Context as flaskDataServerContext } from '../context/flaskDataServerContext';
import { Context as healablesServerContext } from '../context/healablesServerContext';

import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { expo } from '../../app.json'
import * as Linking from 'expo-linking';

//----------------------------------------------------------------------

function cacheImages(images) {
    return images.map(image => {
        if (typeof image === 'string') {
            return Image.prefetch(image);
        } else {
            return Asset.fromModule(image).downloadAsync();
        }
    });
}

function cacheFonts(fonts) {
    return fonts.map(font => Font.loadAsync(font));
}

//----------------------------------------------------------------------

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function MainScreen({ navigation }) {

    // ============= production / staging / offline ====================

    const flaskServerMode = 'backend';

    // ========================= variables =============================

    const prefKeyLoginDetails = 'prefKeyLoginDetails';

    //----------------------------------------------------------------------

    const [selectedLang, setSelectedLang] = useState('english');

    const [state, setState] = useState(
        {
            userEmail: '',
            password: '',
            rememberMe: true,
            token: '',
            userId: '',
            userType: '',

            inviteToken: '',
            newPassword1: '',
            newPassword2: '',

            isSubjectRole: false,
        });

    const [animating, setAnimating] = useState(false);

    const [getTextPopupState, setGetTextPopupState] = useState({ isGetTextVisible: false, title: '', userEmail: '' });

    const [messagePopupState, setMessagePopupState] = useState({ isMessageVisible: false, title: '' });

    //const { loginUser, readMe } = useContext(flaskDataServerContext);

    const { loginUserAtHealables, signupUserAtHealables, resetPasswordAtHealables } = useContext(healablesServerContext);

    //######################################################################

    const [dimensions, setDimensions] = useState({ window, screen });

    var screenWidth = Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    var textInput2;

    useEffect(() => {

        var inviteToken = ''

        Linking.getInitialURL()
            .then(url => {
                //console.log("dbg: ### App.js getInitialURL Triggered: "+url)
                let index = url.toLowerCase().indexOf('/onboarding?id=');
                if (index !== -1) {
                    inviteToken = url.substring(index + 15);
                    //console.log("dbg: ### INVITE id="+inviteToken);
                    setState({
                        userEmail: '',
                        password: '',
                        rememberMe: true,
                        token: '',
                        userId: '',
                        userType: '',

                        inviteToken: inviteToken,
                        newPassword1: '',
                        newPassword2: '',

                        isSubjectRole: false,
                    })
                }

                // this.handleOpenURL({ url });
            })
            .catch(error => console.error(error));

        loadLoginPrefs();

        Dimensions.addEventListener("change", onChange);
        return () => {
            Dimensions.removeEventListener("change", onChange);
        };
    }, []);

    const onChange = ({ window, screen }) => {
        setDimensions({ window, screen });
    };

    //----------------------------------------------------------------------

    const [fontsLoaded, setFontsLoaded] = useState(false);

    const _loadAssetsAsync = async () => {
        const imageAssets = cacheImages([]);

        const fontAssets = cacheFonts([
            { 'Rubik-Regular': require('../../assets/fonts/Rubik-Regular.ttf') },
            { 'Rubik-Medium': require('../../assets/fonts/Rubik-Medium.ttf') },
            { 'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.ttf') },
        ]);

        await Promise.all([...imageAssets, ...fontAssets]);
    }

    //-----------------------------------------------------------------------------

    const loadLoginPrefs = async () => {

        try {
            let item = await AsyncStorage.getItem(prefKeyLoginDetails);
            if (item !== null) {
                setSelectedLang('english');

                let jsonObj = JSON.parse(item);
                setState({
                    userEmail: jsonObj.userEmail,
                    password: '',
                    rememberMe: jsonObj.rememberMe,
                    token: jsonObj.token,
                    userId: jsonObj.userId,
                    userType: jsonObj.userType,

                    inviteToken: '',
                    newPassword1: '',
                    newPassword2: '',

                    isSubjectRole: false,
                });
            }
        } catch (err) {
        }
    };

    //========================================================================================================

    const isEmailValid = (email) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(email.toLowerCase())
    };

    //######################################################################

    const onLoginPressed = () => {

        if (!isEmailValid(state.userEmail.trim())) {
            const txt = LocStr('Please enter a valid email', selectedLang);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }

        if (state.password.trim().length < 4) {
            const txt = LocStr('Please enter a valid password\n(4 or more characters)', selectedLang);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }

        serverLoginUser(flaskServerMode);
    }

    //----------------------------------------------------------------------

    const handleResetPassword = (newtext) => {
        if (!isEmailValid(newtext)) {
            const txt = LocStr('Please enter a valid email', selectedLang);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        setGetTextPopupState({ isGetTextVisible: false, title: '', userEmail: newtext })

        serverResetPassword(newtext);
    }

    //----------------------------------------------------------------------

    const onSetNewPasswordPressed = () => {

        if (state.newPassword1.trim().length < 4) {
            const txt = LocStr('Please enter a valid password\n(4 or more characters)', selectedLang);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        if (state.newPassword2.trim() != state.newPassword1.trim()) {
            const txt = LocStr('Passwords are not identical', selectedLang);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }

        serverSetNewPassword()
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const serverLoginUser = async (serverMode) => {
        try {

            setAnimating(true);

            let obj = {
                userEmail: state.rememberMe ? state.userEmail.trim() : '',
                rememberMe: state.rememberMe,
                token: '',
                userId: '',
                userType: '',
            };

            await AsyncStorage.setItem(prefKeyLoginDetails, JSON.stringify(obj), () => {

                loginUserAtHealables(state.userEmail.trim(), state.password.trim(),
                    async (result) => {
                        console.log('called');
                        setAnimating(false);

                        const token = await result.getIdToken();

                        global.token = token;

                        setState({ ...state, token: token });

                        const paramsForAdmin = {
                            token: token,
                            loggedInUserId: result.uid,
                            loggedInUserFullname: result.displayName,
                            loggedInUserFirstname: (result.displayName ?? '').split(' ')[0],
                            loggedInUserLastname: (result.displayName ?? '').split(' ')[1] ?? '',
                            loggedInUserEmail: result.email,
                            loggedInUserPhone: result.phoneNumber,
                            loggedInUserRoles: (await result.getIdTokenResult()).claims.roles,
                            language: selectedLang,
                            serverMode: serverMode,
                        }

                        navigation.navigate('ProtocolsScreen', paramsForAdmin);
                    },
                    (errMsg) => {

                        setAnimating(false);

                        console.log(errMsg);
                        const txt = errMsg;
                        setMessagePopupState({ isMessageVisible: true, title: txt });
                        return;
                    }
                );
            });
        } catch (err) {
        }
    };

    //=============================================================================

    const serverSetNewPassword = async () => {

        try {
            setAnimating(true);

            signupUserAtHealables(state.inviteToken, state.newPassword1.trim(),
                (result) => {
                    setAnimating(false);
                    loadLoginPrefs()
                },
                (errMsg) => {

                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //=============================================================================

    const serverResetPassword = async (userEmail) => {

        try {
            setAnimating(true);

            resetPasswordAtHealables(userEmail,
                (result) => {
                    setAnimating(false);
                    setMessagePopupState({ isMessageVisible: true, title: LocStr('Instructions on how to reset your password will be sent to your email.', selectedLang) });
                },
                (errMsg) => {

                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //=============================================================================

    const openWebPage = (url) => {
        Linking.openURL(url)
    }

    //=============================================================================
    /*
    const serverReadMe = async (serverMode, token) => {
        try {

            setAnimating(true);
  
            let obj = {  
                userEmail: state.rememberMe?state.userEmail:'',  
                rememberMe: state.rememberMe,  
                token: token,
                userId: '',
                userType: '',
            };  

            await AsyncStorage.setItem(prefKeyLoginDetails, JSON.stringify(obj), () => {

                readMe(serverMode, token, 
                    (result) => {
                        setAnimating(false);

                        navigation.navigate('AdminScreen', {
                            token:              token,
                            loggedInUserId:     result.id,
                            loggedInUserFullname:   result.first_name+' '+result.last_name,
                            loggedInEmail:      (result.email=='offline.email')?state.userEmail:result.email,
                            language:           selectedLang,
                            serverMode:         serverMode,
                        });

                      },
                    (errMsg) => {
                        setAnimating(false);
                    
                        console.log(errMsg);
                        const txt = errMsg;
                        setMessagePopupState({isMessageVisible:true,title:txt});
                        return;
                    }
                );
            });
        } catch (err) {
        }
    };
    */

    //######################################################################

    // ============= render ====================

    if (!fontsLoaded) {
        return (
            <AppLoading
                startAsync={_loadAssetsAsync()}
                onFinish={() => { setFontsLoaded(true); }}
                onError={console.warn}
            />
        );
    }

    /*
    if (screenWidth<900)
    {
        return (
            <View style={styles.container}>
                <View style={{height:screenHeight, width:screenWidth,justifyContent:'center'}} >
                    <Text style={styles.loadingTitle} >{LocStr('Sorry - window size too small to display app...\n\nAre you trying to use the app on a phone?\n\nTry rotating to landscape position.',selectedLang)}</Text>
                </View>
            </View>
        );
    }
    */

    if (state.isSubjectRole) {
        return (
            <View style={styles.container}>

                <View style={styles.bodyStyle} >

                    <Image fadeDuration={0} style={styles.topBarImage} source={require('../../assets/images/healables.png')} />

                    <View style={{ marginTop: 30, justifyContent: 'center' }} >
                        <Text style={styles.loadingTitle} >{LocStr('Thanks for joining the Healables users community.\n\nTo get the Healables device/s and application -\nconsult with your practitioner.', selectedLang)}</Text>
                    </View>

                </View>

                {/* ================================   T o p   b a r   ================================ */}

                <View style={{
                    height: 60,
                    width: screenWidth,
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.12,
                    shadowRadius: 12,
                    justifyContent: 'center',
                    //alignItems: 'center',
                    backgroundColor: '#fff',
                    position: 'absolute',
                }}
                >
                    <Text style={styles.topBarTitle} >Healables Protocols {process.env.ENV} - Version {require('../../package.json').version}</Text>

                </View>

                <Text style={{
                    marginLeft: screenWidth - 100 - 4,
                    marginTop: screenHeight - 15,
                    width: 100, height: 11, position: 'absolute',
                    textAlign: 'right',
                    fontFamily: "Rubik-Medium",
                    fontSize: 10,
                    color: "#008cff",
                }} >{expo.version}</Text>

            </View>

        );
    }

    let isLogin = (state.inviteToken.length == 0)

    return (
        <View style={styles.container}>

            <View style={styles.bodyStyle} >

                <Image fadeDuration={0} style={styles.topBarImage} source={require('../../assets/images/healables.png')} />

                {/* -----   U s e r n a m e   ----- */}

                <View style={{ alignSelf: 'center', marginTop: 20, flexDirection: 'row' }} >

                    <View style={{ width: 300, height: 36, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9' }} >

                        <TextInput style={{
                            height: 36, margin: 1, borderRadius: 2, backgroundColor: '#fff',
                            borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                        }}
                            value={(isLogin) ? state.userEmail : state.newPassword1} multiline={false} selectTextOnFocus={true} maxLength={100} secureTextEntry={!isLogin}
                            placeholder={LocStr((isLogin) ? 'Email' : 'Enter new password', selectedLang)}
                            onChangeText={(newText) => {
                                //if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/))
                                {
                                    if (isLogin) {
                                        setState({ ...state, userEmail: newText })
                                    } else {
                                        setState({ ...state, newPassword1: newText })
                                    }
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key == "Enter") {
                                    textInput2.focus();
                                }
                            }}
                        />
                    </View>

                </View>

                {/* -----   P a s s w o r d   ----- */}

                <View style={{ alignSelf: 'center', marginTop: 20, flexDirection: 'row' }} >

                    <View style={{ width: 300, height: 36, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9' }} >

                        <TextInput style={{
                            height: 36, margin: 1, borderRadius: 2, backgroundColor: '#fff',
                            borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                        }}
                            value={(isLogin) ? state.password : state.newPassword2} multiline={false} selectTextOnFocus={true} maxLength={100} secureTextEntry={true}
                            placeholder={LocStr((isLogin) ? 'Password' : 'Re-enter new password', selectedLang)}
                            onChangeText={(newText) => {
                                //if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/))
                                {
                                    if (isLogin) {
                                        setState({ ...state, password: newText })
                                    } else {
                                        setState({ ...state, newPassword2: newText })
                                    }
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key == "Enter") {
                                    //onValueChanged(textId,state);
                                }
                            }}
                            ref={(input) => { textInput2 = input; }}
                        />

                    </View>

                </View>

                {/* -----   F o r g o t   P a s s w o r d    ----- */}

                <View style={{ marginLeft: 10, marginTop: 0, width: 300, alignSelf: 'center' }} >

                    {isLogin ?
                        <TouchableOpacity style={{ marginLeft: 0, marginTop: 6, height: 30 }} onPress={() => {
                            setGetTextPopupState({
                                isGetTextVisible: true,
                                title: LocStr('Instructions will be sent to the email below:', selectedLang),
                                userEmail: state.userEmail.trim()
                            })
                        }} >
                            <Text style={styles.buttonLabelBlue} >{LocStr('Forgot password?', selectedLang)}</Text>
                        </TouchableOpacity> : null}

                    <TouchableOpacity style={[styles.btnSend, { marginTop: isLogin ? 40 : 20, position: 'absolute' }]} onPress={() => {
                        if (isLogin) {
                            onLoginPressed()
                        } else {
                            onSetNewPasswordPressed()
                        }
                    }} >
                        {animating ?
                            <ActivityIndicator
                                animating={animating}
                                color='#ffffff'
                                size="small"
                                style={styles.activityIndicator}
                            /> :
                            <Text style={styles.buttonLabelWhite} >{LocStr((state.inviteToken.length > 0) ? 'Set password' : 'Login', selectedLang)}</Text>
                        }
                    </TouchableOpacity>


                    {isLogin ?
                        <View style={{ width: 300, height: 70, marginLeft: 0, marginTop: 45, borderRadius: 3 }} >
                            <Text style={styles.lblPrivacy} >{LocStr('By logging in I accept the Healables', selectedLang)}</Text>
                            <View style={{ alignSelf: 'center', flexDirection: 'row' }} >
                                <TouchableOpacity style={{ marginLeft: 0, marginTop: 3, height: 20 }} onPress={() => {
                                    openWebPage('https://app.flaskdata.io/privacy.html')
                                }} >
                                    <Text style={styles.buttonLabelBlueSmall} >{LocStr('Privacy policy', selectedLang)}</Text>
                                </TouchableOpacity>

                                <Text style={[styles.lblPrivacySep, { marginTop: 3 }]} >{LocStr('and', selectedLang)}</Text>

                                <TouchableOpacity style={{ marginLeft: 0, marginTop: 3, height: 20 }} onPress={() => {
                                    openWebPage('https://app.flaskdata.io/tos.html')
                                }} >
                                    <Text style={styles.buttonLabelBlueSmall} >{LocStr('Terms of use', selectedLang)}</Text>
                                </TouchableOpacity>
                            </View>
                        </View> : null}

                </View>

            </View>

            {/* ================================   T o p   b a r   ================================ */}

            <View style={{
                height: 60,
                width: screenWidth,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.12,
                shadowRadius: 12,
                justifyContent: 'center',
                //alignItems: 'center',
                backgroundColor: '#fff',
                position: 'absolute',
            }}
            >
                <Text style={styles.topBarTitle} >Healables Protocols {process.env.ENV} - Version {require('../../package.json').version}</Text>

            </View>

            {getTextPopupState.isGetTextVisible ?
                <MyEditText
                    parentWidth={screenWidth}
                    text={getTextPopupState.userEmail}
                    placeHolder={LocStr('Enter your email', selectedLang)}
                    onOkPressed={(newtext) => handleResetPassword(newtext.trim())}
                    onCancelPressed={() => setGetTextPopupState({ ...getTextPopupState, isGetTextVisible: false, title: '' })}
                    okTitle={LocStr('Reset', selectedLang)}
                    cancelTitle={LocStr('Cancel', selectedLang)}
                    message={getTextPopupState.title}
                    language={selectedLang}
                /> : null
            }

            {messagePopupState.isMessageVisible ?
                <MyMessagePopup
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({ isMessageVisible: false, title: '' })}
                    okTitle={LocStr('Ok', selectedLang)}
                    message={messagePopupState.title}
                    language={selectedLang}
                /> : null
            }

            <Text style={{
                marginLeft: screenWidth - 100 - 4,
                marginTop: screenHeight - 15,
                width: 100, height: 11, position: 'absolute',
                textAlign: 'right',
                fontFamily: "Rubik-Medium",
                fontSize: 10,
                color: "#008cff",
            }} >{expo.version}</Text>

        </View>
    );
};

// ============= styles ====================

MainScreen.navigationOptions = {
    headerShown: false
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',//'#f1f5f9',
        // prevent user-select on texts
        userSelect: 'none',
    },

    loadingTitle: {
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        //alignSelf: 'center',
        //backgroundColor: '#f00',
    },

    topBarTitle: {
        height: 25,
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        backgroundColor: '#fff',
    },
    topBarImage: {
        width: 170,
        height: 60,
        resizeMode: 'contain',
        //marginLeft: 47,
        marginTop: 0,
        //position: 'absolute',
    },

    bodyStyle: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //flexDirection: 'flex-end',
        //backgroundColor: '#f00',
    },
    lblTitle: {
        width: 90,
        marginTop: 2,
        fontFamily: "Rubik-Regular",
        fontSize: 18,
        color: "#001a2f",
        //position: 'absolute',
    },
    btnSend: {
        height: 36,
        width: 160,
        marginLeft: 70,
        borderRadius: 7,
        justifyContent: 'center',
        backgroundColor: '#008cff'
    },
    buttonLabelWhite: {
        //height: 70,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#fff",
        position: 'absolute',
        //marginTop: 52,
        alignSelf: 'center',
        //backgroundColor: '#f00',
    },
    buttonLabelBlue: {
        //height: 70,
        width: 300,
        fontFamily: "Rubik-Regular",
        fontSize: 13,
        color: "#008cff",
        position: 'absolute',
        //marginTop: 52,
        textAlign: 'right',
        //backgroundColor: '#f00',
    },
    lblPrivacy: {
        //height: 70,
        width: 300,
        fontFamily: "Rubik-Regular",
        fontSize: 11,
        color: "#001a2f",
        //position: 'absolute',
        textAlign: 'center',
        //backgroundColor: '#f00',
    },
    lblPrivacySep: {
        height: 20,
        marginLeft: 5,
        marginRight: 5,
        fontFamily: "Rubik-Regular",
        fontSize: 11,
        color: "#001a2f",
        //position: 'absolute',
        textAlign: 'center',
        //backgroundColor: '#f00',
    },
    buttonLabelBlueSmall: {
        height: 20,
        fontFamily: "Rubik-Regular",
        fontSize: 11,
        color: "#008cff",
        //position: 'absolute',
        textAlign: 'center',
        //backgroundColor: '#f00',
    },
    activityIndicator: {
        position: 'absolute',
        alignSelf: 'center',
        height: 30
    },

});
