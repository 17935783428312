import axios from 'axios';

export default axios.create({
    // Our Heroku    / Our MongoDB
    // baseURL: 'https://healables-talshnitzer.herokuapp.com/',

    // Our AWS       / Healables MongoDB
    //baseURL: 'https://api.shsh.com/',

    // Healables AWS / Healables MongoDB
    //baseURL: 'http://HealablesApi-env.eba-b9azqsfu.us-west-2.elasticbeanstalk.com/',
    //baseURL: 'https://healsone.shsh.com',

    // Cloud Run Endpoint
    baseURL: process.env.ENV === 'prod' ? 'https://hpm-tshfe32w3q-ew.a.run.app/' : 'https://hpm-rf4wuwyc3q-ew.a.run.app/',

    headers: {
        'Content-Type': 'application/json',
        //'Access-Control-Expose-Headers': 'X-Auth'
    }
});