import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, ActivityIndicator, FlatList } from 'react-native';
import { LocStr, getTimeNow, defaultAnalgesics, validateJSONArray, validateStr } from '../utils/Utils';
import MyListSearch from './MyListSearch';
import MyEditSubjectPopup from '../components/MyEditSubjectPopup';
import MyMessagePopup from '../components/MyMessagePopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import MyUsersHeader from '../components/MyUsersHeader';
import MySubjectLine from '../components/MySubjectLine';
import { Feather } from '@expo/vector-icons';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MyEditSiteAndStudyPopup = ({ 
                                    popupWidth, popupHeight, popupX, popupY, 
                                    siteAndStudy, 
                                    onSiteSubjectsChanged, onCancelPressed, 
                                    onRefreshPressed,
                                    token, language 
                                }) => {

    var screenWidth = Dimensions.get('window').width;

    const [state, setState] = useState({
        ourSite:         siteAndStudy,
        displaySubjects: siteAndStudy.siteSubjects,
        hasChanges:      false,
    });

    const [editSubjectState, setEditSubjectState] = useState({
        isVisible:false, 
        isNew: true, 
        subjectIndex:-1, 
        subjectItem:{},
    });
             
    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false, 
        questionId: '', 
        itemId: '',
        title: '',
    });

    const [animating, setAnimating] = useState(false);
    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});
        
    const { 
        updateSiteSubjectsAtHealables,

        updateSubjectAtHealables,
        createSubjectAtHealables,
        deleteUserAtHealables,
        inviteUserAtHealables,
        } = useContext(healablesServerContext);

    var screenHeight = Dimensions.get('window').height;
    var leftTablesMargin = 50;
    var rightPanelMargin = 50;
    var tableWidth       = screenWidth-leftTablesMargin-rightPanelMargin;

    //======================================================================

    useEffect(() => {

        setState({ 
            ...state, 
            ourSite:         siteAndStudy,
            displaySubjects: siteAndStudy.siteSubjects,
        })

        return () => {
         };
      }, [siteAndStudy]);

    //======================================================================

    const onSubjectChanged = (subjectIndex, subjectItem, isNew) => {
        setEditSubjectState({
            isVisible:false, 
            isNew: true, 
            subjectIndex:-1, 
            subjectItem:{},
        });

        if (isNew)
        {
            serverCreateSubject(subjectItem);
        } else {
            serverUpdateSubject(subjectItem.userId, subjectItem)
        }
    }

    //----------------------------------------------------------------------

    const onSubjectEditCanceled = () => {
        setEditSubjectState({
            isVisible:false, 
            isNew: true, 
            subjectIndex:-1, 
            subjectItem:{},
        });
    }

    //======================================================================

    const handleSearch = (newText) => {

        const filteredSubjects = state.ourSite.siteSubjects.filter((user) => {
            return ((newText.length == 0) ||
                    (user.userFullname.toLowerCase().includes(newText)))
        })

        setState({ ...state, displaySubjects: filteredSubjects })
    }

    //######################################################################

    const onQuestionAnswerWasNo = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});
    }

    const onQuestionAnswerWasYes = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});


        switch (questionId)
        {
        case 'delete_subject':
            serverDeleteSubject(itemId)
            break;
        case 'invite_subject':
            let theSubject = state.ourSite.siteSubjects.filter((item) => {
                return (item.userId==itemId)
            })
            if (theSubject.length>0)
            {
                serverInviteSubject(itemId,theSubject[0])
            }
            break;
        }
    }

    //######################################################################

    const onSubjectActionPressed = (actionPressed, rowIndex) => {

        var txt;

        let theUser = state.displaySubjects[rowIndex];
        let now = getTimeNow();

        switch (actionPressed)
        {
            case 'edit':
                var theAnalgesics = JSON.parse(validateJSONArray(theUser.userAnalgesics));
                if (theAnalgesics.length==0)
                {
                    theAnalgesics = defaultAnalgesics()
                }
                setEditSubjectState({
                    isVisible:true, 
                    isNew: false, 
                    subjectIndex:rowIndex, 
                    subjectItem:{
                        userId:         validateStr(theUser.userId),
                        userFullname:   validateStr(theUser.userFullname),
                        userFirstname:  validateStr(theUser.userFirstname),
                        userLastname:   validateStr(theUser.userLastname),
                        userEmail:      validateStr(theUser.userEmail),
                        userPhone:      validateStr(theUser.userPhone),
                        userRoles:      ['subject'],
                        userAnalgesics: theAnalgesics,
                        userStatus:     validateStr(theUser.userStatus),
                        lastUpdate:     now,
                        createdAt:      theUser.createdAt,
                    },
                });
                break;
            case 'delete':
                txt = LocStr('Are you sure you want to delete this user?',language);
                setQuestionPopupState({isQuestionVisible:true,questionId:'delete_subject',itemId:theUser.userId,title:txt});
                break;
            case 'invite':
                txt = LocStr('Send invitation to this user?',language);
                setQuestionPopupState({isQuestionVisible:true,questionId:'invite_subject',itemId:theUser.userId,title:txt});
                break;
            }
    }

    const renderSubject = ({ item, index }) => {

        let canEdit   = true
        let canDelete = true
        let canInvite = true

        return (
            <View style={{width:tableWidth-2,height:34,marginTop:0}} key={item.key} >
                <MySubjectLine style={{width:tableWidth-2,height:42,marginTop:0}}
                    width={tableWidth-2} 
                    rowIndex={index}
                    canEdit={canEdit} canDelete={canDelete} canInvite={canInvite}
                    theUser={item}
                    onActionPressed={(action,rowIndex)=>{onSubjectActionPressed(action,rowIndex)}}
                    language={language}
                    />
            </View>
        );
    }

    //######################################################################

    const onAddSubjectPressed = () => {

        let now = getTimeNow();

        setEditSubjectState({
            isVisible:true, 
            isNew: true, 
            subjectIndex:-1, 
            subjectItem:{
                userId:         '',
                userFullname:   '',
                userFirstname:  '',
                userLastname:   '',
                userEmail:      '',
                userPhone:      '',
                userRoles:      ['subject'],
                userAnalgesics: defaultAnalgesics(),
                userStatus:     'new',
                lastUpdate:     now,
                createdAt:      now,
            },
        });
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const updateStateSubjectAfterCreate = (newSubject, createdSubjectId) => {

        let now = getTimeNow();
        newSubject.lastUpdate = now;
        newSubject.createdAt  = now;
        newSubject.userId     = createdSubjectId;

        var newDisplayArray = [ ...state.displaySubjects ];
        newDisplayArray.push(newSubject);

        var newOurSite  = { ...state.ourSite  }
        newOurSite.siteSubjects.push(newSubject)

        serverUpdateSiteSubjects(newOurSite.siteId, newOurSite)

        setState({ ourSite: newOurSite, displaySubjects: newDisplayArray, hasChanges: true })
    }

    const updateStateSubjectAfterUpdate = (subjectId, newSubject) => {

        let now = getTimeNow();
        newSubject.lastUpdate = now;

        let newDisplayArray = [];
        state.displaySubjects.map((item)=>{
            if (item.userId === subjectId)
            {
                newDisplayArray.push(newSubject);
            } else {
                newDisplayArray.push(item);
            }
        });

        let newOurArray = [];
        state.ourSite.siteSubjects.map((item)=>{
            if (item.userId === subjectId)
            {
                newOurArray.push(newSubject);
            } else {
                newOurArray.push(item);
            }
        });
        var newOurSite  = { ...state.ourSite, siteSubjects: newOurArray  }

        serverUpdateSiteSubjects(newOurSite.siteId, newOurSite)

        setState({ ourSite: newOurSite, displaySubjects: newDisplayArray, hasChanges: true })
    }

    const updateStateSubjectAfterDelete = (deletedSubjectId) => {

        let newDisplayArray = state.displaySubjects.filter((item)=>{
            return (item.userId !== deletedSubjectId);
        });

        let newOurArray = state.ourSite.siteSubjects.filter((item)=>{
            return (item.userId !== deletedSubjectId);
        });
        var newOurSite  = { ...state.ourSite, siteSubjects: newOurArray  }

        serverUpdateSiteSubjects(newOurSite.siteId, newOurSite)

        setState({ ourSite: newOurSite, displaySubjects: newDisplayArray, hasChanges: true })
    }

    const updateStateSubjectAfterInvite = (subjectId, newSubject) => {

        let pendingUser = { ...newSubject, userStatus: 'pending' }
        var foundMatch = false;

        foundMatch = false;
        let newDisplayArray = [];
        state.displaySubjects.map((item)=>{
            if (item.userId === subjectId)
            {
                newDisplayArray.push(pendingUser);
                foundMatch = true
            } else {
                newDisplayArray.push(item);
            }
        });
        if (!foundMatch)
        {
            newDisplayArray.push(pendingUser);
        }

        foundMatch = false;
        let newOurArray = [];
        state.ourSite.siteSubjects.map((item)=>{
            if (item.userId === subjectId)
            {
                newOurArray.push(pendingUser);
                foundMatch = true
            } else {
                newOurArray.push(item);
            }
        });
        if (!foundMatch)
        {
            newOurArray.push(pendingUser);
        }
        var newOurSite  = { ...state.ourSite, siteSubjects: newOurArray  }

        setState({ ourSite: newOurSite, displaySubjects: newDisplayArray, hasChanges: true })
    }

    //######################################################################
    //######################################################################

    function makeSureSubjectsAreID(srcSite)
    {
        var arrRes = []
        srcSite.map((item) => {
            if (typeof(item)=='string')
            {
                arrRes.push(item)
            } else {
                arrRes.push(item.userId)
            }
        })
        return arrRes
    }

    const serverUpdateSiteSubjects = async (siteId, newSite) => {
        try {

            setAnimating(true);

            let siteToSend = { ...newSite, siteSubjects: makeSureSubjectsAreID(newSite.siteSubjects) }

            updateSiteSubjectsAtHealables(token, siteId, siteToSend, 
                (result) => {
                    setAnimating(false);
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################
    //######################################################################
    
    const serverCreateSubject = async (subjectInfo) => {
        try {

            setAnimating(true);

            createSubjectAtHealables(token, subjectInfo, 
                (result) => {
                    updateStateSubjectAfterCreate(subjectInfo, result);

                    serverInviteSubject(result, subjectInfo)
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################

    const serverUpdateSubject = async (subjectId, newSubject) => {
        try {

            setAnimating(true);

            updateSubjectAtHealables(token, subjectId, newSubject, 
                (result) => {
                    setAnimating(false);
                    updateStateSubjectAfterUpdate(subjectId, newSubject);
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################

    const serverDeleteSubject = async (subjectId) => {
        try {

            setAnimating(true);

            deleteUserAtHealables(token, subjectId, 
                (result) => {
                    updateStateSubjectAfterDelete(subjectId);
                    setAnimating(false);
                  },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################

    const serverInviteSubject = async (subjectId, newSubject) => {
        try {
            setAnimating(true);

            inviteUserAtHealables(token, subjectId, 
                (result) => {
                    setAnimating(false);
                    updateStateSubjectAfterInvite(subjectId, newSubject);
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################
    //######################################################################

    return (
        <View style={{
            width: popupWidth,
            height: popupHeight,
            marginLeft: popupX,
            marginTop: popupY,
            justifyContent: 'center',
            position: 'absolute',
            backgroundColor:'#ff0'
        }}
        >
            <View style={{
                marginTop:0, 
                marginLeft:0, 
                height:popupHeight, 
                width: popupWidth, position:'absolute', backgroundColor: '#d9d9d9', borderRadius: 8,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
            }}>
                <View style={{ width: popupWidth-2, height: popupHeight-2, position: 'absolute', flexDirection: 'column', margin: 1, backgroundColor: '#fff', borderRadius: 6 }}>

                    <Text style={{marginLeft:20,marginTop:20,position:'absolute',fontFamily:"Rubik-Medium",fontSize:16,color:"#001a2f",}} >{LocStr('Site:',language)}</Text>
                    <Text style={{marginLeft:90,marginTop:20,position:'absolute',fontFamily:"Rubik-Regular",fontSize:16,color:"#001a2f",}} >{siteAndStudy.siteName}</Text>

                    <Text style={{marginLeft:20,marginTop:50,position:'absolute',fontFamily:"Rubik-Medium",fontSize:16,color:"#001a2f",}} >{LocStr('Study:',language)}</Text>
                    <Text style={{marginLeft:90,marginTop:50,position:'absolute',fontFamily:"Rubik-Regular",fontSize:16,color:"#001a2f",}} >{siteAndStudy.siteStudyShortName}</Text>

                    <View style={{marginLeft:20,marginTop:86,width:popupWidth-40,height:1,position:'absolute',backgroundColor:'#a9a9a9'}} /> 

                    <MyListSearch
                        width={popupWidth/2}
                        offY={106}
                        handleSearch={handleSearch}
                        language={language}
                    />

                    <TouchableOpacity style={{marginLeft:popupWidth-2-45,marginTop:104,width:30,height:30,position:'absolute'}} 
                        onPress={() => {onAddSubjectPressed();}} 
                        disabled={false}
                        >
                        <Feather name="plus-circle" style={{fontSize: 30,position: 'absolute',color: '#6a707e'}} />
                    </TouchableOpacity>

                    <TouchableOpacity style={{marginLeft:popupWidth-2-45-10-30,marginTop:104,width:30,height:30,position:'absolute'}} 
                        onPress={() => {onRefreshPressed();}} >
                        <Feather name="refresh-ccw" style={{fontSize: 30,position: 'absolute',color: '#6a707e'}} />
                    </TouchableOpacity>

                    <View style={{ width: popupWidth-2, marginTop: 20 }} >

                        <MyUsersHeader style={{width:popupWidth-2,height:32}} 
                                    width={popupWidth-2} 
                                    actionsColumnWidth={90}
                                    sortByName={true}
                                    arrTitles={['Name','Email','Phone', 'Analgesics', 'Status']}
                                    onHeaderPressed={(headerName)=>{
                                    }}
                                    language={language}
                                    />

                        <FlatList style={{ width: popupWidth-2, height: 320 }}
                            data={state.displaySubjects}
                            keyExtractor={(item) => { return item.userId; }}
                            renderItem={renderSubject}
                        />

                    </View>

                    <View style={{ marginLeft: (popupWidth-240)/2, marginTop: popupHeight-54, width: 220, height: 36, position: 'absolute', flexDirection: 'row' }} >

                        {/*
                        <TouchableOpacity style={{ width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onCancelPressed() }}
                            >
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Cancel', language)}</Text>
                        </TouchableOpacity>
                        */}

                        <TouchableOpacity style={{ width: 100, marginLeft: 70, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { 
                                if (state.hasChanges)
                                {
                                    onSiteSubjectsChanged(state.ourSite.siteSubjects,siteAndStudy) 
                                } else {
                                    onCancelPressed()
                                }
                            }}>
                            {animating?
                            <ActivityIndicator
                                animating = {animating}
                                color = '#ffffff'
                                size = "small"
                                style = {styles.activityIndicator}
                            />:
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Close', language)}</Text>
                            }
                        </TouchableOpacity>

                    </View>

                </View>
            </View>

            {editSubjectState.isVisible?
            <MyEditSubjectPopup 
                parentWidth={popupWidth}
                parentHeight={popupHeight}
                onValueChanged={(subjectIndex, subjectItem, isNew) => {onSubjectChanged(subjectIndex, subjectItem, isNew)}}
                onCanceled={() => onSubjectEditCanceled()}
                subjectIndex={editSubjectState.subjectIndex}
                subjectItem={editSubjectState.subjectItem}
                isNew={editSubjectState.isNew}
                onUserAdded={(newUser) => { 
                    //@@@onUserAdded(newUser)
                }}
                token={token}
                language={language}
                />:null
            }


            {questionPopupState.isQuestionVisible?
            <MyAskQuestionPopup 
                parentWidth={popupWidth}
                onYesPressed={(questionId,itemId) => {onQuestionAnswerWasYes(questionId,itemId)}}
                onNoPressed={(questionId,itemId) => {onQuestionAnswerWasNo(questionId,itemId)}}
                questionId={questionPopupState.questionId}
                auxString={questionPopupState.itemId}
                yesTitle={LocStr('Yes',language)}
                noTitle={LocStr('No',language)}
                question={questionPopupState.title}
                language={language}
                />:null
                }

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={popupWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

        </View>
    );
};

const styles = StyleSheet.create({
    lblListItem: {
        //width: 338,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        textAlign: 'left',
        color: "#001a2f",
        //position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16White: {
        width: 338,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        textAlign: 'center',
        color: "#fff",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    activityIndicator: {
        marginTop: 4,
        position: 'absolute',
        alignSelf: 'center',
        height: 30
    },
    lblDialogTitle: {
        width:800,
        marginTop:20,
        fontFamily: "Rubik-Regular",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
});

export default MyEditSiteAndStudyPopup;