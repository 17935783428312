import moment from 'moment';

const textsDict = require('../../assets/tables/system_texts.json');

export function LocStr(src, lang) {
    //console.log(src+' * '+lang);

    //if (lang==='english') return src+'$';

    if (textsDict !== null) {
        const rec = textsDict[src];
        if ((rec !== null) && (rec !== undefined) && (rec.length > 0)) {
            const item = rec[0];
            if ((item !== null) && (item !== undefined)) {
                const res = item[lang];
                if ((res !== null) && (res != undefined)) {
                    return res;
                }
            }
        }
    }
    return src;
}

//-----------------------------------------------------------------------------

export function strToArray(src) {

    let arrFields = src.split(',');

    for (let i = arrFields.length; i < 6; i++) {
        arrFields.push('');
    }

    return arrFields;
}

export function strToArrayWithValidation(src) {

    let arrFields = src.split(',');

    for (let i = arrFields.length; i < 6; i++) {
        arrFields.push('');
    }

    arrFields[1] = validateFreq(arrFields[1], '', '');
    arrFields[2] = validateFreq(arrFields[2], '', '');
    arrFields[3] = validateCurrent(arrFields[3], '', '');
    arrFields[4] = validateDuration(arrFields[4], '', '');

    return arrFields;
}

//-----------------------------------------------------------------------------

export function strToNumWaveform(strVal) {
    switch (strVal.toLowerCase()) {
        case 'gentle': return '0';
        case 'mild': return '2';
        case 'sharp': return '3';
        default: return '';
    }
}

export function strToNumPolarity(strVal) {
    if (strVal.toLowerCase().startsWith('positive')) return '0'
    if (strVal.toLowerCase().startsWith('alternating')) return '1'
    return '';
}

//-----------------------------------------------------------------------------

export function numToStrWaveform(numVal, defVal = 'Illegal') {
    switch (numVal) {
        case '0': return 'Gentle';
        case '2': return 'Mild';
        case '3': return 'Sharp';
        default: return defVal;
    }
}

export function numToStrPolarity(numVal, defVal = 'Illegal') {
    switch (numVal) {
        case '0': return 'Positive';
        case '1': return 'Alternating';
        default: return defVal;
    }
}

export function numToStrFreq(val, suffix = '', defVal = 'Illegal') {
    let num = Number(val);
    if ((!isNaN(num)) && (0.1 <= num) && (num <= 999999.0)) {
        let res = ConvertNumber(num, 2, '.', '');
        console.log(val, res);
        if (res.endsWith('.00')) {
            res = res.substring(0, res.length - 3);
        }
        return res + suffix;
    }
    return defVal;
}

export function validateFreq(val, suffix = '', defVal = 'Illegal') {
    let num = Number(val);
    if ((!isNaN(num)) && (0.1 <= num) && (num <= 999999.0)) {
        let res = ConvertNumber(num, 2, '.', '');
        return res + suffix;
    }
    return defVal;
}


export function validateCurrent(val, suffix = '', defVal = 'Illegal') {
    let num = Number(val);
    if ((!isNaN(num)) && (0 <= num) && (num <= 600)) return ConvertNumber(num, 0, '.', '') + suffix;
    return defVal;
}

export function validateDuration(val, suffix = '', defVal = 'Illegal') {
    let num = Number(val);
    if ((!isNaN(num)) && (0 <= num) && (num <= 60)) return ConvertNumber(num, 0, '.', '') + suffix;
    return defVal;
}


//-----------------------------------------------------------------------------

function ConvertNumber(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    return number;
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

//---------------------------------------------------------------------

export function isRoleChecked(roleName, arrRoles) {
    let filteredData = arrRoles.filter(x => String(x).includes(roleName));
    return (filteredData.length > 0)
}

// 'superAdmin', 'studyAdmin', 'usersAdmin', 'siteAdmin', 'subject'
export function setAvailableTabs(arrRoles) {

    return [];

    if (isRoleChecked('superAdmin', arrRoles)) {
        return ['Users', 'Studies', 'Sites', 'Protocols']
    }
    if (isRoleChecked('studyAdmin', arrRoles)) {
        return ['Users', 'Studies', 'Sites', 'Protocols']
    }
    if (isRoleChecked('usersAdmin', arrRoles)) {
        return ['Users', 'Protocols']
    }
    if (isRoleChecked('siteAdmin', arrRoles)) {
        return ['Sites']
    }

    if (isRoleChecked('subject', arrRoles)) {
        return []
    }
}

export function getDefaultTab(arrRoles) {
    if (isRoleChecked('superAdmin', arrRoles)) {
        return 'Users';
    }
    if (isRoleChecked('studyAdmin', arrRoles)) {
        return 'Studies';
    }
    if (isRoleChecked('usersAdmin', arrRoles)) {
        return 'Users';
    }
    if (isRoleChecked('siteAdmin', arrRoles)) {
        return 'Sites';
    }
    return '';
}

//-----------------------------------------------------------------------------

export function calcFreqDesc(val) {
    if (val == 0) {
        return 'None';
    } else {
        if (val == 99) {
            return 'As needed';
        } else {
            return `${val} per day`;
        }
    }
}

export function defaultAnalgesics() {
    let res = [
        { id: "1", name: "Acetaminophen", dose: "500mg", freq: 0, freq_desc: "None", },
        { id: "2", name: "Acamol", dose: "500mg", freq: 0, freq_desc: "None", },
        { id: "3", name: "Optalgin", dose: "500mg", freq: 0, freq_desc: "None", },
        { id: "4", name: "Ibuprofen", dose: "500mg", freq: 0, freq_desc: "None", },
        { id: "5", name: "Codeine", dose: "500mg", freq: 0, freq_desc: "None", },
        { id: "6", name: "Oxicodone", dose: "500mg", freq: 0, freq_desc: "None", },
    ]
    /*
    return JSON.stringify(
        [
            { id:"1", name:"Acetaminophen", dose: "500mg", freq: "99", freq_desc: "As needed", },
            { id:"2", name:"Acamol"       , dose: "500mg", freq: "99", freq_desc: "As needed", },
            { id:"3", name:"Optalgin"     , dose: "500mg", freq: "99", freq_desc: "As needed", },
            { id:"4", name:"Ibuprofen"    , dose: "500mg", freq: "99", freq_desc: "As needed", },
            { id:"5", name:"Codeine"      , dose: "500mg", freq: "99", freq_desc: "As needed", },
            { id:"6", name:"Oxicodone"    , dose: "500mg", freq: "99", freq_desc: "As needed", },
        ]
    );
    */
    return res;
}

//-----------------------------------------------------------------------------

export function isDefaultMetadataKey(theKey) {
    let idx = defaultMetadataArray().findIndex((item) => {
        return (item.key == theKey)
    })
    return (idx >= 0)
}

export function validateMetadata(in_metadata) {
    if ((in_metadata == null) || (in_metadata == undefined)) return '';

    var in_str = '' + in_metadata
    let in_arr = JSON.parse(in_str.split('HEAL-ENABLE-IOS').join('EnableIOS'))

    let sorted = sortedMetadataString(in_arr)
    return '' + sorted
}

export function sortedMetadataString(in_arr) {
    return JSON.stringify(sortedMetadataArray(in_arr))
}

export function sortedMetadataArray(in_arr) {

    var sortedArr = []
    let defArr = defaultMetadataArray()

    // first we merge all items in default array:
    defArr.map((def_item) => {

        let idx = in_arr.findIndex((in_item) => {
            return (in_item.key == def_item.key)
        })
        if (idx >= 0) {
            // found match in in_arr:
            sortedArr.push(in_arr[idx])
        } else {
            // no match in in_arr - take default
            sortedArr.push(def_item)
        }
    })

    // now we add all items in in_arr that are not default:
    in_arr.map((in_item) => {

        let idx = defArr.findIndex((def_item) => {
            return (in_item.key == def_item.key)
        })
        if (idx == -1) {
            sortedArr.push(in_item)
        }
    })
    //console.log('dbg: EEE: '+JSON.stringify(sortedArr))

    return sortedArr;
}

export function defaultMetadataString() {
    return JSON.stringify(defaultMetadataArray())
}

export function defaultMetadataArray() {
    return [
        // displayed as radio buttons now: { key:"OperationMode"    , value:"care",       description:"care / trial / home / pro", },

        // Operation mode:
        { key: "OperationMode", value: "", description: "care / trial / home / pro", },

        // general:
        { key: "EnableIOS", value: "Y", description: "Y / N", },
        { key: "EnableANDROID", value: "N", description: "Y / N", },
        { key: "AppVersion", value: "", description: "", },
        { key: "FWVersion", value: "122.1.4", description: "", },
        { key: "DefaultLanguage", value: "", description: "", },

        // trial mode
        { key: "_sep_trial_", value: "Trial parameters", description: "", },
        { key: "ProtocolStartDate", value: "", description: "Start date for CT (”yyyy-mm-dd”)", },
        { key: "ProtocolWeekly", value: "", description: "Weeks for questionnaires (”0,1,..”)", },
        { key: "StudyID", value: "", description: "Study ID for CT", },
        { key: "ProtocolID", value: "", description: "Force protocolID for CT", },

        // operation configs
        { key: "_sep_config_", value: "Misc. parameters", description: "", },
        { key: "CheckWaterMinutes", value: "60", description: "", },
        { key: "ProtocolCyclic", value: "Y", description: "Y / N", },
        { key: "CancelLogout", value: "Y", description: "Y / N", },
        { key: "ProtocolCurrent", value: "100", description: "Lock the output current", },
        { key: "ImpThreshold", value: "", description: "", },
        { key: "ProtocolTags", value: "", description: "", },
        { key: "AppPowerMenu", value: "", description: "Y / N", },
    ]
}

export function getOperationMode(arr) {
    let res = ''
    let opMode = arr.filter((item) => {
        return (item.key == 'OperationMode')
    })
    if (opMode.length != 0) {
        res = opMode[0].value.toLowerCase()
    }
    return res
}

export function metadataDescription(item, language) {
    try {
        let arr = JSON.parse(item)

        var tot = 0

        var opMode = ''
        if (arr.length > 0) {
            opMode = getOperationMode(arr)

            if (opMode.length > 0) {
                arr.map((item) => {
                    var skip = false
                    if (item.key.startsWith('_sep_')) {
                        skip = true
                    }
                    if (item.key.startsWith('OperationMode')) {
                        skip = true
                    }
                    if ((opMode != 'trial') &&
                        ((item.key == 'ProtocolStartDate') ||
                            (item.key == 'ProtocolWeekly') ||
                            (item.key == 'StudyID') ||
                            (item.key == 'ProtocolID'))) {
                        skip = true
                    }

                    if (!skip) {
                        tot = tot + 1
                    }
                })
            }
        }

        if (tot > 0) {
            var t1 = ''
            if (tot == 1) {
                t1 = '1 ' + LocStr('item', language)
            } else {
                t1 = '' + tot + ' ' + LocStr('items', language)
            }
            if (opMode == '') {
                return t1
            } else {
                return t1 + ' (' + opMode + ')'
            }
        }
    } catch (err) {
    }
    return ''
}

//-----------------------------------------------------------------------------

export function validateStr(src) {
    if ((src == null) || (src == undefined)) return '';
    return '' + src;
}

export function validateBool(src) {
    if ((src == null) || (src == undefined)) return false;
    return src;
}

export function validateArray(src) {
    if ((src == null) || (src == undefined)) return [];
    return src;
}

export function validateJSONArray(src) {
    if ((src == null) || (src == undefined) || (src.length == 0)) return '[]';
    return '' + src;
}

//-----------------------------------------------------------------------------

export function foundInTags(arrTags, lookFor) {
    let idx = arrTags.findIndex((item) => {
        return (item.toLowerCase().includes(lookFor))
    })
    return (idx != -1)
}

//-----------------------------------------------------------------------------

export function formatDate(formated_Date) {
    //return moment(formated_Date).format('MMM Do YYYY, hh:mm:ss a');
    return moment(formated_Date).format('MMM Do YYYY');
}

export function getTimeNow() {
    var now = new Date();//moment().format('YYYY-MM-DDThh:mm:ss')+'.000Z';
    //now.format('YYYY-MM-DDThh:mm:ss');//+'.000Z';
    //var clientTimezoneOffset = new Date().getTimezoneOffset()/60;//offset in hours
    return now.toISOString();
}

export function IsSafari() {
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    return isSafari;
}
