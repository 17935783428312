import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, ActivityIndicator, FlatList } from 'react-native';
import { LocStr, getTimeNow } from '../utils/Utils';
import MyListSearch from './MyListSearch';
import MyEditSitePopup from '../components/MyEditSitePopup';
import MyMessagePopup from '../components/MyMessagePopup';
import { Feather } from '@expo/vector-icons';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MyEditStudySitesPopup = ({ parentWidth = 560, currStudyId, studySites, arrUsers, onUserAdded, arrSites, onSiteAdded, arrStudies, onSitesChanged, onCancelPressed, token, language }) => {

    var screenWidth = Dimensions.get('window').width;

    const [state, setState] = useState({
        selectedSites: studySites,
        displaySites: arrSites,
    });

    const [editSiteState, setEditSiteState] = useState({
        isVisible:false, 
        isNew: true, 
        siteIndex:-1, 
        siteItem:{},
    });
                            
    const [animating, setAnimating] = useState(false);
    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});
        
    const { 
        createSiteAtHealables,
        } = useContext(healablesServerContext);

    var screenHeight = Dimensions.get('window').height;

    const popupW      = (screenWidth>=1000)?screenWidth-100:900;
    const popupInnerW = popupW-40;
    const popupH      = 550;

    //======================================================================

    const onSiteChanged = (siteIndex, siteItem, isNew) => {
        setEditSiteState({
            isVisible:false, 
            isNew: true, 
            siteIndex:-1, 
            siteItem:{},
        });

        serverCreateSite(siteItem);
    }

    //----------------------------------------------------------------------

    const onSiteEditCanceled = () => {
        setEditSiteState({
            isVisible:false, 
            isNew: true, 
            studyIndex:-1, 
            studyItem:{},
        });
    }

    //======================================================================

    function isSiteAlreadyUsed(theSite) {
        let isUsed = false;
        arrStudies.map((studyItem) => {

            if ((currStudyId=='') || (currStudyId!=studyItem.studyId))
            {
                studyItem.studySites.map((siteItem)=> {

                    if (siteItem.siteId==theSite.siteId)
                    {
                        isUsed = true;
                        return true;
                    }
                })
            }
        })
        return isUsed
    }

    //======================================================================

    function isSiteSelected(theSite) {

        let index = state.selectedSites.findIndex((item) => {
            return (theSite.siteId === item.siteId)
        })
        return (index!=-1)
    }

    const toggleSiteSelection = (theSite) => {
        var newSelectedArray = []
        if (isSiteSelected(theSite))
        {
            newSelectedArray = state.selectedSites.filter((item) => {
                return (theSite.siteId != item.siteId)
            })
        } else {
            if (isSiteAlreadyUsed(theSite))
            {
                const txt = LocStr('Sorry - this site is already used on another study',language);
                setMessagePopupState({isMessageVisible:true,title:txt});
                return;
            }

            newSelectedArray = [ ...state.selectedSites ];
            newSelectedArray.push(theSite)
        }

        setState({ ... state, selectedSites: newSelectedArray })
    }

    function sortDisplayArray(src) {

        var newDisplayArray = [ ...src ];
        
        newDisplayArray.sort((a,b) => {

            /*
            let aSelected = isSiteSelected(a);
            let bSelected = isSiteSelected(b);
            if ((aSelected) && (!bSelected))
            {
                return -1;
            }
            if ((!aSelected) && (bSelected))
            {
                return 1;
            }
            */

            var res = a.siteName.localeCompare(b.siteName);
            return res;
        });

        return newDisplayArray
    }

    //======================================================================

    const handleSearch = (newText) => {

        const filteredSites = arrSites.filter((user) => {
            return ((newText.length == 0) ||
                    (user.siteName.toLowerCase().includes(newText)))
        })

        setState({ ...state, displaySites: filteredSites })
    }

    //======================================================================

    const _renderItem = ({ item, index }) => {
        let width = popupW-40;
        let height = 30;

        const isSelected = isSiteSelected(item)
        const isEnabled  = !isSiteAlreadyUsed(item)
        
        return (
            <TouchableOpacity 
                style={{width: width,height: height,marginTop: 1,marginLeft: 1,backgroundColor: '#FFFFFF'}} 
                onPress={() => { toggleSiteSelection(item) }}
                >
                
                <View style={{width: width,height: height, flexDirection:'row'}} >
                    <View style={{width:height,height:height,alignItems:'center',justifyContent:'center'}} >
                        {(isSelected)?<Feather name="check" style={{fontSize: 16,position: 'absolute',color:'#00aa00',marginLeft:2,marginTop:1}} />:null}
                    </View>

                    <Text style={[styles.lblListItem,{color:isEnabled?'#001a2f':'#a9a9a9', flex:1}]} >{item.siteName}</Text>
                    <Text style={[styles.lblListItem,{color:isEnabled?'#001a2f':'#a9a9a9', flex:1}]} >{getOwnerNameFromId(item.siteOwnerId)}</Text>
                    <Text style={[styles.lblListItem,{color:isEnabled?'#001a2f':'#a9a9a9', width:140}]} >{getOwnerPhoneFromId(item.siteOwnerId)}</Text>
                    <Text style={[styles.lblListItem,{color:isEnabled?'#001a2f':'#a9a9a9', width:240}]} >{getOwnerEmailFromId(item.siteOwnerId)}</Text>
                </View>

            </TouchableOpacity >
        )
    }

    //======================================================================

    const getOwnerNameFromId = (theId) => {
        if (theId==='') return '';

        let idx = arrUsers.findIndex((item)=> {
            return (item.userId===theId);
        })
        if (idx === -1)
        {
            return '';
        } else {
            return arrUsers[idx].userFullname;
        }
    }                    

    const getOwnerEmailFromId = (theId) => {
        if (theId==='') return '';

        let idx = arrUsers.findIndex((item)=> {
            return (item.userId===theId);
        })
        if (idx === -1)
        {
            return '';
        } else {
            return arrUsers[idx].userEmail;
        }
    }                    

    const getOwnerPhoneFromId = (theId) => {
        if (theId==='') return '';

        let idx = arrUsers.findIndex((item)=> {
            return (item.userId===theId);
        })
        if (idx === -1)
        {
            return '';
        } else {
            return arrUsers[idx].userPhone;
        }
    }                    
    
    //======================================================================

    const onAddSitePressed = () => {

        let now = getTimeNow();

        setEditSiteState({
            isVisible:true, 
            isNew: true, 
            siteIndex:-1, 
            siteItem:{
                siteId:       '',
                siteName:     '',
                siteOwnerId:  '',
                siteSubjects: [],
                lastUpdate:   now,
                createdAt:    now,
            },
        });
    }

    //========================================================================================================

    function filterByRole(theRole) {
        let res = arrUsers.filter((item) => {
            return (item.userRoles.includes(theRole));
        });
        return res
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const updateStateSiteAfterCreate = (newSite, createdSiteId) => {

        let now = getTimeNow();
        newSite.lastUpdate = now;
        newSite.createdAt  = now;
        newSite.siteId     = createdSiteId;

        let newDisplayArray = state.displaySites.filter((item)=>{
            return true;
        });
        newDisplayArray.push(newSite);

        var newSelectedArray = [ ...state.selectedSites ];
        newSelectedArray.push(newSite)

        onSiteAdded(newSite)

        setState({ selectedSites: newSelectedArray, displaySites: newDisplayArray })
    }

    //-----------------------------------------------------
    
    const serverCreateSite = async (siteInfo) => {
        try {

            setAnimating(true);

            createSiteAtHealables(token, siteInfo, 
                (result) => {
                    setAnimating(false);
                    updateStateSiteAfterCreate(siteInfo, result);
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################

    return (
        <View style={{
            width: parentWidth,
            height: screenHeight - 60 - 73,
            justifyContent: 'center',
            position: 'absolute'
        }}
        >
            <View style={{
                width: popupW, height: popupH, alignSelf: 'center', backgroundColor: '#d9d9d9', borderRadius: 8,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
            }}>
                <View style={{ width: popupW-2, height: popupH-2, position: 'absolute', flexDirection: 'column', margin: 1, backgroundColor: '#fff', borderRadius: 6 }}>

                    <Text style={[styles.lblDialogTitle,{width:popupW,position:'absolute'}]} >{LocStr('Study sites',language)}</Text>

                    <MyListSearch
                        width={popupInnerW-2}
                        offY={60}
                        handleSearch={handleSearch}
                        language={language}
                    />

                    <TouchableOpacity style={{marginLeft:popupW-2-45,marginTop:60,width:30,height:30,position:'absolute'}} 
                        onPress={() => {onAddSitePressed();}} 
                        disabled={false}
                        >
                        <Feather name="plus-circle" style={{fontSize: 30,position: 'absolute',color: '#6a707e'}} />
                    </TouchableOpacity>

                    {(state.displaySites.length > 0) ?
                        <View style={{ width: 460 + 15, marginLeft: 20, marginTop: 20 }} >

                            <FlatList style={{ width: popupW-2 - 2 - 40, height: 300, borderRadius: 4, borderColor: '#a9a9a9', borderWidth: 1, }}
                                data={sortDisplayArray(state.displaySites)}
                                keyExtractor={(item) => { return item.siteId; }}
                                renderItem={_renderItem}
                            />

                        </View> :
                        <Text style={[styles.lblTitle,{textAlign: 'center',color: "#a9a9a9",width: popupInnerW,height: 22,marginLeft: 20,marginTop: 180,}]} >
                            {LocStr('No results', language)}</Text>
                    }

                    <View style={{ marginLeft: (popupW-240)/2, marginTop: popupH-54, width: 220, height: 36, position: 'absolute', flexDirection: 'row' }} >

                        <TouchableOpacity style={{ width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onCancelPressed() }}
                            >
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Cancel', language)}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ width: 100, marginLeft: 40, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onSitesChanged(state.selectedSites) }}
                            >
                            {animating?
                            <ActivityIndicator
                                animating = {animating}
                                color = '#ffffff'
                                size = "small"
                                style = {styles.activityIndicator}
                            />:
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Update', language)}</Text>
                            }
                        </TouchableOpacity>

                    </View>

                </View>
            </View>

            {editSiteState.isVisible?
            <MyEditSitePopup 
                parentWidth={screenWidth}
                onValueChanged={(siteIndex, siteItem, isNew) => {onSiteChanged(siteIndex, siteItem, isNew)}}
                onCanceled={() => onSiteEditCanceled()}
                siteIndex={editSiteState.siteIndex}
                siteItem={editSiteState.siteItem}
                isNew={editSiteState.isNew}
                arrUsers={filterByRole('siteAdmin')}
                onUserAdded={(newUser) => { 
                    onUserAdded(newUser)
                }}
                token={token}
                language={language}
                />:null
            }

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={parentWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

        </View>
    );
};

const styles = StyleSheet.create({
    lblListItem: {
        //width: 338,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        textAlign: 'left',
        color: "#001a2f",
        //position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16White: {
        width: 338,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        textAlign: 'center',
        color: "#fff",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    activityIndicator: {
        marginTop: 4,
        position: 'absolute',
        alignSelf: 'center',
        height: 30
    },
    lblDialogTitle: {
        width:800,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
});

export default MyEditStudySitesPopup;