import createDataContext from './createDataContext';
import flaskDataServer from '../api/flaskDataServer';
import flaskStagingServer from '../api/flaskStagingServer';

const flaskDataServerReducer = (state, action) => {
    switch (action.type)
    {
        case 'post_login':
            return action.payload;
        case 'get_me':
            return action.payload;
        case 'post_logout':
            return action.payload;
        default:
            return state;
    }
};

//=======================================================================

const loginUser = (dispatch) => {

    return async (serverMode, email, password, callbackOk, callbackErr) => {
        try {
            var response

            if (serverMode == 'offline')
            {
                if (password == 'Harkness!')
                {
                    let payload = { token: 'offline' };  
                    if (callbackOk) callbackOk(payload);
                } else {
                    if (callbackErr) callbackErr('Password incorrect');
                }
                return;
            }

            if (serverMode == 'production')
            {
                response = await flaskDataServer.post('/auth/authorize', 
                    {   email: email, 
                        password: password 
                    },{ timeout: 20000 });
            } else {
                response = await flaskStagingServer.post('auth/mobile-form-authorization',
                    {   email: email, 
                        password: password 
                    },{ timeout: 20000 });
            }

            if (response !== null)
            {
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        let payload = { token: response.data.token };  
                        dispatch({ type: 'post_login', payload: payload });
                        if (callbackOk) callbackOk(payload);
                        return;
                    } else {
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            console.log(e.code)
            console.log(e.message)
            console.log(e.stack)
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not login, please try again...");
    }
};

//=======================================================================

const readMe = (dispatch) => {

    return async (serverMode, token, callbackOk, callbackErr) => {
        try {

            if (serverMode == 'offline')
            {
                let payload = { 
                    token:              'offline.token',
                    id:                 'offline.id',
                    first_name:         'Offline',
                    last_name:          'User',
                    email:              'offline.email',
                    };  

                if (callbackOk) callbackOk(payload);
                return;
            }

            const options = {
                headers: { 
                    'Authorization': 'JWT '+token,
                }
            }

            var response
            if (serverMode == 'production')
            {
                response = await flaskDataServer.get('/flask/users/self', options,{ timeout: 20000 });
            } else {
                response = await flaskStagingServer.get('auth/self', options,{ timeout: 20000 });
            }

            if (response !== null)
            {
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        //console.log(JSON.stringify(response.data))

                        dispatch({ type: 'get_me', payload: response.data });
                        if (callbackOk) callbackOk(response.data);
                        return;
                    } else {
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not complete request, please try again...");
    }
};

//=======================================================================

const logoutUser = (dispatch) => {

    return async (serverMode, token, callbackOk, callbackErr) => {
        try {

            if (serverMode == 'offline')
            {
                let payload = { 
                    token:              'offline.token',
                    };  

                if (callbackOk) callbackOk(payload);
                return;
            }

            const options = {
                headers: { 
                    'Authorization': 'JWT '+token,
                }
            }

            var response
            if (serverMode == 'production')
            {
                response = await flaskDataServer.post('/auth/logout', {}, options);
            } else {
                response = await flaskStagingServer.post('/auth/logout', {}, options);
            }

            if (response !== null)
            {
                if (response.data !== null)
                {
                    if ((response.data.error === null) || (response.data.error === undefined))
                    {
                        let payload = {  
                            userDetails: response.data.userDetails,
                            token: response.headers['x-auth'],
                        };  
        
                        dispatch({ type: 'post_logout', payload: payload });
                        if (callbackOk) callbackOk(payload);
                        return;
                    } else {
                        if (callbackErr) callbackErr(response.data.error);
                        return;
                    }
                }
            }
        } catch (e) {
            if ((e !== null) && (e !== undefined))
            {
                if ((e.response !== null) && (e.response !== undefined))
                {
                    if ((e.response.data !== null) && (e.response.data !== undefined))
                    {
                        if (callbackErr) callbackErr(e.response.data);
                        return;
                    }
                }
            }
        }

        if (callbackErr) callbackErr("Could not login, please try again...");
    }
};

//=======================================================================

export const { Context, Provider } = createDataContext( 
    flaskDataServerReducer,
    { loginUser, readMe, logoutUser }, 
    [] 
);