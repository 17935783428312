import React, { useContext, useState } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, FlatList, StyleSheet } from 'react-native';
import MyMessagePopup from '../components/MyMessagePopup';
import MyAnalgesicsLine from '../components/MyAnalgesicsLine';
import MyAnalgesicsHeader from '../components/MyAnalgesicsHeader';
import { LocStr, calcFreqDesc } from '../utils/Utils';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MyEditSubjectPopup = ( { parentWidth, 
                            parentHeight,
                            onValueChanged, 
                            onCanceled, 
                            subjectIndex, 
                            subjectItem, 
                            isNew, 
                            forceRole='',
                            token,
                            language } ) => {

    var screenWidth  = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;
    let popupWidth   = 900

    var textInput1;
    var textInput2;
    var textInput3;
    var textInput4;

    const [state, setState] = useState(subjectItem);

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

    //========================================================================================================

    const validateEmail = (val) => {
        //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return (pattern.test(val))
    }

    //========================================================================================================

    const validateRequiredFields = (calledFor) => {

        if (state.userFirstname.trim().length==0)
        {
            const txt = LocStr('Please enter first name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.userLastname.trim().length==0)
        {
            const txt = LocStr('Please enter last name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (!validateEmail(state.userEmail.trim()))
        {
            const txt = LocStr('Please enter a valid email address',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.userPhone.trim().length==0)
        {
            const txt = LocStr('Please enter a valid phone number',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        return true;
    }

    //========================================================================================================

    const handleActionPress = (action, rowIndex) => {

        let newArr = [ ...state.userAnalgesics ];
        let curVal = newArr[rowIndex].freq;
        var newVal = curVal;
        switch (action)
        {
        case 'minus':
            if (curVal==99)
            {
                newVal = 6;
            } else {
                if (curVal>0)
                {
                    newVal = curVal - 1;
                }
            }
            break;
        case 'reset':
            if (curVal>0)
            {
                newVal = 0;
            }
            break;
        case 'plus':
            if (curVal<6)
            {
                newVal = curVal + 1;
            } else {
                if (curVal==6)
                {
                    newVal = 99;
                }
            }
            break;
        }
        if (newVal!=curVal)
        {
            newArr[rowIndex].freq      = newVal
            newArr[rowIndex].freq_desc = LocStr(calcFreqDesc(newVal),language)
            setState({ ...state, userAnalgesics: newArr })
        }
    }

    //========================================================================================================

    const onUpdatePressed = () => {

        if (!validateRequiredFields('update')) return;

        let newUser = { 
            ...state,
            userFirstname: state.userFirstname.trim(),
            userLastname:  state.userLastname.trim(),
            userEmail:     state.userEmail.trim(),
            userPhone:     state.userPhone.trim(),
            userFullname:  state.userFirstname.trim() + ' ' + state.userLastname.trim(),
        }

        newUser.userAnalgesics = JSON.stringify(newUser.userAnalgesics)

        onValueChanged(subjectIndex,newUser,isNew);
    }

    //========================================================================================================
//const MyAnalgesicsLine = ( { width, actionsColumnWidth=60, rowIndex, canDecrement, canIncrement, theItem, onActionPressed, language } ) => {

    const renderAnalgesics = ({ item, index }) => {
        return (
            <div>
                <View style={{ width: popupWidth - 40, height: 1, marginLeft:20, marginTop: 1, backgroundColor: '#e2e8f0' }} />
                <MyAnalgesicsLine
                    width={popupWidth - 40}
                    offX={20}
                    actionsColumnWidth={90}
                    rowIndex={index}
                    canDecrement={true}
                    canIncrement={true}
                    theItem={item}
                    onActionPressed={(action,rowIndex) => { handleActionPress(action,rowIndex) }}
                    language={language}
                />
            </div>
        )
    }

    //######################################################################

    return (
        <TouchableOpacity style={{  width: parentWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        //backgroundColor: '#ff0',
                        position:'absolute'}} 
                        activeOpacity={1}
                        onPress={() => {
                            onCanceled();
                        }}
            >
            <TouchableOpacity style={{ width:popupWidth,height:parentHeight, alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                                    }}
                                    activeOpacity={1}
                                    onPress={() => {
                                    }}
                >
                <View style={{ flexDirection:'column',height:parentHeight, margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblDialogTitle,{width:popupWidth}]} >{LocStr(isNew?'Add subject':'Edit subject',language)}</Text>

                    {/* -----   first & last names   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        {/* -----   first name   ----- */}
        
                        <Text style={styles.lblTitle} >{LocStr('First name:',language)}</Text>
    
                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userFirstname} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=50)
                                    {
                                        setState({...state, userFirstname:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput2.focus();
                                    }
                                }}
                                ref={(input) => { textInput1 = input; }}
                                />

                        </View>

                        {/* -----   last name   ----- */}

                        <Text style={[styles.lblTitle,{marginLeft:50}]} >{LocStr('Last name:',language)}</Text>
    
                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userLastname} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=50)
                                    {
                                        setState({...state, userLastname:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput3.focus();
                                    }
                                }}
                                ref={(input) => { textInput2 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:popupWidth-40,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   email & phone fields   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        {/* -----   Email   ----- */}
        
                        <Text style={styles.lblTitle} >{LocStr('Email:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userEmail} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=30)
                                    {
                                        setState({...state, userEmail:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput4.focus();
                                    }
                                }}
                                ref={(input) => { textInput3 = input; }}
                                />

                        </View>

                        {/* -----   Phone   ----- */}

                        <Text style={[styles.lblTitle,{marginLeft:50}]} >{LocStr('Phone:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userPhone} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=30)
                                    {
                                        setState({...state, userPhone:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                    }
                                }}
                                ref={(input) => { textInput4 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:popupWidth-40,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   analgesics   ----- */}

                    <Text style={[styles.lblDialogTitle,{width:popupWidth}]} >{LocStr('Analgesics',language)}</Text>

                    <MyAnalgesicsHeader style={{width:popupWidth-40,height:32,mrginTop:20,marginTop:15}} 
                            width={popupWidth-40} 
                            offX={20}
                            actionsColumnWidth={90}
                            sortByName={true}
                            arrTitles={['Name','Dose','Frequency']}
                            onHeaderPressed={(headerName)=>{
                            }}
                            language={language}
                            />

                    <FlatList 
                        style={{ 
                            height: screenHeight - 60 - 73 - 100, 
                            width: popupWidth-2,
                            //marginTop: 5, 
                            marginLeft: 1 
                        }}
                        data={state.userAnalgesics}
                        renderItem={renderAnalgesics}
                    />

                    {/* -----   buttons   ----- */}

                    <View style={{alignSelf:'center',marginTop:parentHeight-36-20,height:36,position:'absolute',flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCanceled()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Cancel',language)}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{marginLeft:40,width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onUpdatePressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr(isNew?'Save':'Update',language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                    <View style={{width:1,height:20}} />

                </View> 
            </TouchableOpacity> 

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width:90,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //backgroundColor: '#ff0',
        //position: 'absolute',
    },
    lblSubTitle: {
        width:100,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width:500,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditSubjectPopup;