import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, formatDate } from '../utils/Utils'

//=========================================================================

const MyHeaderLine = ( { width, height, rowIndex, item, isSelected, onLinePressed, onActionPressed, language } ) => {

    const lblTitle = {
        width:width-30-72-16,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#d9d9d9",
        //backgroundColor: '#ff0',
        //position: 'absolute',
    };
    const lblSubTitle = {
        width:width-20,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#d9d9d9",
        //position: 'absolute',
    };
    const lblDates = {
        width:width-20,
        textAlign:'right',
        fontFamily: "Rubik-Regular",
        fontSize: 12,
        color: "#d9d9d9",
        //position: 'absolute',
    };

    //-------------------------------------------------------------------------------

    let wasDeleted = item.authorName.startsWith('*#*');
    let authorName = wasDeleted?'Protocol was deleted':item.authorName;

    return (
        <View style={{width:width,height:height,marginTop:0,marginLeft:0,backgroundColor:isSelected?'#5c6270':'#2d323e'}} >

            <View style={{width:width,height:1,marginLeft:0,marginTop:0,position:'absolute',backgroundColor:(rowIndex==0)?'#6a707e':'#2d323e'}} />

            <TouchableOpacity style={{width:width,height:height-2,marginTop:1,position:'absolute'}} onPress={() => {onLinePressed(rowIndex);}} >

                <Text style={[lblTitle,{marginTop:4,marginLeft:10,position:'absolute'}]} >{item.protocolName}</Text>

                <Text style={[lblSubTitle,{marginTop:54,marginLeft:10,position:'absolute',color:wasDeleted?'#f00':'#d9d9d9'}]} >{authorName}</Text>

                <TouchableOpacity style={{marginLeft:width-30,marginTop:6,width:20,height:20,position:'absolute'}} 
                    onPress={() => {onActionPressed('download',rowIndex);}} disabled={wasDeleted} >
                    <Feather name="download" style={{fontSize: 16,position: 'absolute',color:wasDeleted?'#797979':'#d9d9d9',marginLeft:2,marginTop:1}} />
                </TouchableOpacity>

                <TouchableOpacity style={{marginLeft:width-30-24,marginTop:6,width:20,height:20,position:'absolute'}} 
                    onPress={() => {onActionPressed('delete',rowIndex);}} >
                    <Feather name="trash-2" style={{fontSize: 16,position: 'absolute',color:wasDeleted?'#f00':'#d9d9d9',marginLeft:2,marginTop:1}} />
                </TouchableOpacity>

                <TouchableOpacity style={{marginLeft:width-30-48,marginTop:6,width:20,height:20,position:'absolute'}} 
                    onPress={() => {onActionPressed('duplicate',rowIndex);}} disabled={wasDeleted} >
                    <Feather name="copy" style={{fontSize: 16,position: 'absolute',color:wasDeleted?'#797979':'#d9d9d9',marginLeft:2,marginTop:1}} />
                </TouchableOpacity>

                <TouchableOpacity style={{marginLeft:width-30-72,marginTop:6,width:20,height:20,position:'absolute'}} 
                    onPress={() => {onActionPressed('edit',rowIndex);}} disabled={wasDeleted} >
                    <Feather name="edit-2" style={{fontSize: 16,position: 'absolute',color:wasDeleted?'#797979':'#d9d9d9',marginLeft:2,marginTop:1}} />
                </TouchableOpacity>

                <Text style={[lblDates,{marginTop:36+7,marginLeft:10,position:'absolute'}]} >{`Updated: ${formatDate(item.lastUpdate)}`}</Text>
                <Text style={[lblDates,{marginTop:52+7,marginLeft:10,position:'absolute'}]} >{`Created: ${formatDate(item.CreatedAt)}`}</Text>

            </TouchableOpacity>

            <View style={{width:width,height:1,marginLeft:0,marginTop:height-1,position:'absolute',backgroundColor:'#6a707e'}} />

        </View>
    );    
};

export default MyHeaderLine;

