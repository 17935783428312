import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, TextInput, FlatList } from 'react-native';
import { LocStr, foundInTags } from '../utils/Utils';
import MyListSearch from './MyListSearch';

//=========================================================================

const MySelectProtocolPopup = ({ parentWidth = 560, arrProtocols, onProtocolSelected, onCancelPressed, language }) => {

    const [selectedProtocol, setSelectedProtocol] = useState({});
    const [protocols, setProtocols] = useState(arrProtocols)

    var screenHeight = Dimensions.get('window').height;

    const _renderItem = ({ item, index }) => {
        let width = 460;
        let height = 70;
        const isSelected = index === selectedProtocol.rowIndex
        return (
        
            <TouchableOpacity style={{ width: width, height: height, marginTop: 0, marginLeft: 1, backgroundColor: isSelected ?'#E0E0E0':'#FFFFFF' }} 
                onPress={() => {setSelectedProtocol({ ...item, rowIndex: index })}}
                >

                <Text style={[styles.lblListItem   ,{width: width-20,marginLeft:10,marginTop:4,position:'absolute'}]} >{item.protocolName}</Text>

                <Text style={[styles.lblListSubItem,{width: width-20,marginLeft:10,marginTop:48,position:'absolute'}]} >{item.authorName}</Text>

                <View style={{width:width-10,height:1,marginLeft:0,marginTop:69,position:'absolute',backgroundColor:'#E0E0E0'}} />

            </TouchableOpacity >            
        )
    }

    const handleSearch = (newText) => {

        const filteredProtocols = arrProtocols.filter((protocol) => {
            return ((newText.length == 0) ||
                    (protocol.protocolName.toLowerCase().includes(newText)) ||
                    (protocol.protocolNum.toLowerCase().includes(newText)) ||
                    (protocol.authorName.toLowerCase().includes(newText)) ||
                    (foundInTags(protocol.field_tags,newText)))
                })

        setProtocols(filteredProtocols)
    }

    return (
        <View style={{
            width: parentWidth,
            height: screenHeight - 60 - 73,
            justifyContent: 'center',
            position: 'absolute'
        }}
        >
            <View style={{
                width: 500, height: 500, alignSelf: 'center', backgroundColor: '#d9d9d9', borderRadius: 8,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
            }}>
                <View style={{ width: 498, height: 498, position: 'absolute', flexDirection: 'column', margin: 1, backgroundColor: '#fff', borderRadius: 6 }}>

                    <Text style={[styles.lblTitle16, { width: 438, marginTop: 30, marginLeft: 30, marginRight: 30 }]}>{LocStr('Select protocol from the list below, and click \'Select\'.',language) }</Text>

                    <MyListSearch
                        handleSearch={handleSearch}
                        language={language}
                    />

                    {(protocols.length > 0) ?
                        <View style={{ width: 460 + 15, marginLeft: 20, marginTop: 20 }} >

                            <FlatList style={{ width: 498 - 2 - 40, height: 300, borderRadius: 4, borderColor: '#a9a9a9', borderWidth: 1, }}
                                data={protocols}
                                keyExtractor={(item) => { return item.protocolId; }}
                                renderItem={_renderItem}
                            />

                        </View> :
                        <Text style={[
                            styles.lblTitle,
                            {
                                textAlign: 'center',
                                color: "#d9d9d9",
                                width: 460,
                                height: 22,
                                marginLeft: 20,
                                marginTop: 40
                            }
                        ]} >
                            {LocStr('No results', language)}</Text>
                    }

                    <View style={{ marginLeft: 139, marginTop: 446, width: 220, height: 36, position: 'absolute', flexDirection: 'row' }} >

                        <TouchableOpacity style={{ width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onCancelPressed() }}
                            >
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Cancel', language)}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ width: 100, marginLeft: 20, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onProtocolSelected(selectedProtocol) }}
                            >
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Select', language)}</Text>
                        </TouchableOpacity>

                    </View>

                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    lblListItem: {
        width: 338,
        marginLeft:10,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        textAlign: 'left',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblListSubItem: {
        width: 338,
        marginTop: 40,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        textAlign: 'left',
        color: "#6a707e",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16White: {
        width: 338,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        textAlign: 'center',
        color: "#fff",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
});

export default MySelectProtocolPopup;