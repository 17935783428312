import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, ActivityIndicator, FlatList } from 'react-native';
import { LocStr, getTimeNow } from '../utils/Utils';
import MyListSearch from './MyListSearch';
import MyEditUserPopup from '../components/MyEditUserPopup';
import MyMessagePopup from '../components/MyMessagePopup';
import { Feather } from '@expo/vector-icons';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MySelectUserPopup = ({ parentWidth = 560, arrUsers, forceRole='', onUserSelected, onCancelPressed, token, language }) => {

    const [selectedUser, setSelectedUser] = useState({rowIndex:-1});
    const [users, setUsers] = useState(arrUsers)
    const [editUserState, setEditUserState] = useState({
        isVisible:false, 
        isNew: true, 
        userIndex:-1, 
        userItem:{},
    });
    const [animating, setAnimating] = useState(false);
    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});
        
    const { 

        createUserAtHealables,
        inviteUserAtHealables,

        } = useContext(healablesServerContext);

    var screenHeight = Dimensions.get('window').height;

    //======================================================================

    const _renderItem = ({ item, index }) => {
        let width = 460;
        let height = 30;
        const isSelected = index === selectedUser.rowIndex
        return (
            
                <TouchableOpacity style={
                    {
                        width: width,
                        height: height,
                        marginTop: 1,
                        marginLeft: 1,
                        backgroundColor: isSelected ? '#E0E0E0' : '#FFFFFF'
                    }
                } onPress={
                    () => {setSelectedUser({ ...item, rowIndex: index })}
                }>
                    <Text style={styles.lblListItem} >{item.userFullname}</Text>
                </TouchableOpacity >
            
        )
    }

    //======================================================================

    const onAddUserPressed = () => {

        let now = getTimeNow();

        setEditUserState({
            isVisible:true, 
            isNew: true, 
            userIndex:-1, 
            userItem:{
                userId:         '',
                userFullname:   '',
                userFirstname:  '',
                userLastname:   '',
                userEmail:      '',
                userPhone:      '',
                userRoles:      ['siteAdmin'],
                userAnalgesics: '[]',
                userStatus:     'new',
                lastUpdate:     now,
                createdAt:      now,
            },
        });
    }

    //----------------------------------------------------------------------

    const onUserChanged = (userIndex, userItem, isNew) => {
        setEditUserState({
            isVisible:false, 
            isNew: true, 
            userIndex:-1, 
            userItem:{},
        });

        serverCreateUser(userItem);
    }

    //----------------------------------------------------------------------

    const onUserEditCanceled = () => {
        setEditUserState({
            isVisible:false, 
            isNew: true, 
            userIndex:-1, 
            userItem:{},
        });
    }

    //======================================================================

    const handleSearch = (newText) => {

        const filteredUsers = arrUsers.filter((user) => {
            return ((newText.length == 0) ||
                    (user.userFullname.toLowerCase().includes(newText)))
        })

        setUsers(filteredUsers)
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const serverCreateUser = async (userInfo) => {
        try {

            setAnimating(true);

            createUserAtHealables(token, userInfo, 
                (result) => {
                    serverInviteUser(result, userInfo)
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverInviteUser = async (userId, newUser) => {
        try {
            setAnimating(true);

            inviteUserAtHealables(token, userId, 
                (result) => {
                    setAnimating(false);
                    onUserSelected({ ...newUser, userId: userId, userStatus: 'pending' },true)
                  },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    };
    
    //######################################################################

    return (
        <View style={{
            width: parentWidth,
            height: screenHeight - 60 - 73,
            justifyContent: 'center',
            position: 'absolute'
        }}
        >
            <View style={{
                width: 500, height: 500, alignSelf: 'center', backgroundColor: '#d9d9d9', borderRadius: 8,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
            }}>
                <View style={{ width: 498, height: 498, position: 'absolute', flexDirection: 'column', margin: 1, backgroundColor: '#fff', borderRadius: 6 }}>

                    <Text style={[styles.lblTitle16, { width: 438, marginTop: 30, marginLeft: 30, marginRight: 30 }]}>{LocStr('Select user from the list below, and click \'Select\'.',language) }</Text>

                    <MyListSearch
                        width={498-40}
                        handleSearch={handleSearch}
                        language={language}
                    />

                    <TouchableOpacity style={{marginLeft:498-55,marginTop:80,width:30,height:30,position:'absolute'}} 
                        onPress={() => {onAddUserPressed();}} 
                        disabled={false}
                        >
                        <Feather name="plus-circle" style={{fontSize: 30,position: 'absolute',color: '#6a707e'}} />
                    </TouchableOpacity>

                    {(users.length > 0) ?
                        <View style={{ width: 460 + 15, marginLeft: 20, marginTop: 20 }} >

                            <FlatList style={{ width: 498 - 2 - 40, height: 300, borderRadius: 4, borderColor: '#a9a9a9', borderWidth: 1, }}
                                data={users}
                                keyExtractor={(item) => { return item.userId; }}
                                renderItem={_renderItem}
                            />

                        </View> :
                        <Text style={[
                            styles.lblTitle,
                            {
                                textAlign: 'center',
                                color: "#d9d9d9",
                                width: 460,
                                height: 22,
                                marginLeft: 20,
                                marginTop: 40
                            }
                        ]} >
                            {LocStr('No results', language)}</Text>
                    }

                    <View style={{ marginLeft: 139, marginTop: 446, width: 220, height: 36, position: 'absolute', flexDirection: 'row' }} >

                        <TouchableOpacity style={{ width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onCancelPressed() }}
                            >
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Cancel', language)}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ width: 100, marginLeft: 20, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onUserSelected(selectedUser,false) }}
                            >
                            {animating?
                            <ActivityIndicator
                                animating = {animating}
                                color = '#ffffff'
                                size = "small"
                                style = {styles.activityIndicator}
                            />:
                            <Text style={[styles.lblTitle16White, { marginTop: 9, width: 96 }]}>{LocStr('Select', language)}</Text>
                            }
                        </TouchableOpacity>

                    </View>

                </View>
            </View>

            {editUserState.isVisible?
            <MyEditUserPopup 
                parentWidth={parentWidth}
                onValueChanged={(userIndex, userItem, isNew) => {onUserChanged(userIndex, userItem, isNew)}}
                onCanceled={() => onUserEditCanceled()}
                userIndex={editUserState.userIndex}
                userItem={editUserState.userItem}
                isNew={editUserState.isNew}
                forceRole={forceRole}
                token={token}
                language={language}
                />:null
            }

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={parentWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

        </View>
    );
};

const styles = StyleSheet.create({
    lblListItem: {
        width: 338,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'left',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblTitle16White: {
        width: 338,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        textAlign: 'center',
        color: "#fff",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    activityIndicator: {
        marginTop: 4,
        position: 'absolute',
        alignSelf: 'center',
        height: 30
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
});

export default MySelectUserPopup;