import React from 'react';
import { Image, View, Text, TouchableOpacity } from 'react-native';

//=========================================================================

const img_check = require('../../assets/images/icon_check.png');
  
//=========================================================================

const MyCheckbox = ( { offX = 0, offY = 8, onValueChanged, value, title } ) => {
    return (
        <TouchableOpacity style={{marginLeft:offX,marginTop:offY,position:'absolute',height:24,flexDirection:'row'}} onPress={() => {onValueChanged(!value)}} >

            <View style={{width:20,height:20,marginTop:2,borderRadius:5,backgroundColor:'#008cff'}} >
                {value?<Image fadeDuration={0} style={{width:16,height:16,marginLeft:2,marginTop:2}} source={img_check} />:
                    <View style={{width:16,height:16,marginLeft:2,marginTop:2,borderRadius:3,backgroundColor:'#f1f5f9'}} />
                }
            </View>

            <Text style={{marginLeft:8,marginTop:4,fontFamily:"Rubik-Regular",fontSize:14,color:"#001a2f"}} >{title}</Text>

        </TouchableOpacity>
    );
};

export default MyCheckbox;