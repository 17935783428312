import React, { useReducer } from 'react';

// params are:
// reducer - the reducer func
// actions - an array of all 'action types'
// initialState - the initial state value
export default ( reducer, actions, initialState ) => {

    const Context = React.createContext();

    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        // actions === { addBlogPost: {dispatch} => {return ()=> {} } }
        const boundActions = {};
        for (let key in actions)
        {
            boundActions[key] = actions[key](dispatch);
        }

        return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
    };

    return { Context, Provider };
};