import React, { useContext, useState, useEffect } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import MySelectUserPopup from '../components/MySelectUserPopup';
import MyMessagePopup from '../components/MyMessagePopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import { FontAwesome } from '@expo/vector-icons';
import { LocStr } from '../utils/Utils';

//=========================================================================

const MyEditSitePopup = ( { parentWidth, 
                            onValueChanged, 
                            onCanceled, 
                            siteIndex, 
                            siteItem, 
                            isNew, 
                            arrUsers,
                            onUserAdded,
                            token,
                            language } ) => {

    var screenWidth  = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    const popupW      = 600;
    const popupInnerW = popupW-200;

    var textInput2;

    const [state, setState] = useState({

        siteId:       siteItem.siteId,
        siteName:     siteItem.siteName,
        siteOwnerId:  siteItem.siteOwnerId,
        siteSubjects: siteItem.siteSubjects,

        siteOwners:   arrUsers,
    });

    const [userPopupState, setUserPopupState] = useState({isUserPopupVisible: false, title: ''});

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false, 
        questionId: '', 
        itemId: '',
        title: '',
    });

        /*
                    siteItem:{
                        siteId:       '',
                        siteName:     '',
                        siteOwnerId:  '',
                        siteSubjects: [],
                        lastUpdate:   now,
                        createdAt:    now,
                    },
        */


    //========================================================================================================

    const handleSelectUser = () => {
        setUserPopupState({isUserPopupVisible:true,title:''});
    }

    const handleSelectedUser = (newVal,isNew) => {
        setUserPopupState({isUserPopupVisible:false, title:''})

        if (isNew)
        {
            let newArray = state.siteOwners.filter((item)=>{
                return true;
            });
            newArray.push(newVal);

            setState({ ...state, siteOwnerId:newVal.userId, siteOwners: newArray});

            onUserAdded(newVal)

        } else {
            setState({ ...state, siteOwnerId:newVal.userId});
        }
    }

    //========================================================================================================

    const getOwnerNameFromId = (theId) => {
        if (theId==='') return '';

        let idx = state.siteOwners.findIndex((item)=> {
            return (item.userId===theId);
        })
        if (idx === -1)
        {
            return '';
        } else {
            return state.siteOwners[idx].userFullname;
        }
    }
                
    //========================================================================================================

    const validateRequiredFields = (calledFor) => {

        if (state.siteName.length==0)
        {
            const txt = LocStr('Please enter site name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.siteOwnerId.length==0)
        {
            const txt = LocStr('Please select site manager',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        return true;
    }

    //========================================================================================================

    const onQuestionAnswerWasNo = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});
    }

    const onQuestionAnswerWasYes = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});

        switch (questionId)
        {
        }
    }

    //========================================================================================================

    const onUpdatePressed = () => {

        if (!validateRequiredFields('update')) return;

        let newSite = state

        onValueChanged(siteIndex,newSite,isNew);
    }

    //######################################################################

    return (
        <TouchableOpacity style={{  width: screenWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
                        activeOpacity={1}
                        onPress={() => {
                            //onCanceled();
                        }}
            >
            <TouchableOpacity style={{ width:popupW,/*height:200,*/ alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                                    }}
                                    activeOpacity={1}
                                    onPress={() => {
                                    }}
                >
                <View style={{ /*width:398,height:198, height:198,position:'absolute'*/flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblDialogTitle,{width:popupW}]} >{LocStr(isNew?'Add site':'Edit site',language)}</Text>

                    {/* -----   site name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Site name:',language)}</Text>
    
                        <View style={{width:popupInnerW,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.siteName} multiline={false} selectTextOnFocus={true} maxLength={200} multiline={true}
                                onChangeText={(newText)=>{
                                    if (newText.length<=200)
                                    {
                                        setState({...state, siteName:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput2.focus();
                                    }
                                }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   owner   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Site manager:',language)}</Text>
    
                        <TouchableOpacity style={{width:popupInnerW,height:27,marginLeft:10,borderRadius:3,borderWidth:1,borderColor:'#a9a9a9',backgroundColor:'#ffffff',flexDirection:'row'}} 
                            onPress={() => {handleSelectUser()}}
                            >

                            <Text style={{ margin:4,width:popupInnerW-2,}} >{getOwnerNameFromId(state.siteOwnerId)}</Text>

                            <View style={{width:1,height:25,marginLeft:popupInnerW-29,position:'absolute',backgroundColor:'#a9a9a9'}} />

                            <View style={{width:26,height:25,marginLeft:popupInnerW-28,position:'absolute',borderTopRightRadius:3,backgroundColor:'#f1f5f9'}} >
                                <FontAwesome name="chevron-right" style={{fontSize: 14, position: 'absolute',color:'#6a707e',marginLeft:9,marginTop:6,}} />
                            </View>

                        </TouchableOpacity>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   buttons   ----- */}

                    <View style={{alignSelf:'center',marginTop:40,height:36,flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCanceled()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Cancel',language)}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{marginLeft:40,width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onUpdatePressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr(isNew?'Save':'Update',language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                    <View style={{width:1,height:20}} />

                </View> 
            </TouchableOpacity> 

            {userPopupState.isUserPopupVisible?
                <MySelectUserPopup 
                    parentWidth={screenWidth}
                    arrUsers={state.siteOwners}
                    forceRole={'siteAdmin'}
                    onUserSelected={(newVal,isNew) => {handleSelectedUser(newVal,isNew)}}
                    onCancelPressed={() => setUserPopupState({isUserPopupVisible:false, title:''})}
                    token={token}
                    language={language}
                    />:null
                }

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

            {questionPopupState.isQuestionVisible?
                <MyAskQuestionPopup 
                    parentWidth={screenWidth}
                    onYesPressed={(questionId,itemId) => {onQuestionAnswerWasYes(questionId,itemId)}}
                    onNoPressed={(questionId,itemId) => {onQuestionAnswerWasNo(questionId,itemId)}}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.itemId}
                    yesTitle={LocStr('Yes',language)}
                    noTitle={LocStr('No',language)}
                    question={questionPopupState.title}
                    language={language}
                    />:null
                }

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width:100,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width:800,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditSitePopup;