import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { numToStrWaveform, numToStrPolarity, numToStrFreq, validateCurrent, validateDuration, strToArray } from '../utils/Utils';

//=========================================================================

const MyTableLine = ( { width, rowIndex, arrValues, onActionPressed, nowDragging, language } ) => {

    const renderLine = () => {

        let arrFields = strToArray(arrValues);

        let renderList = arrFields.map((item, index) => {

            var txt;

            switch (index)
            {
                case 0:
                    txt = numToStrWaveform(item);
                    break;
                case 1:
                    txt = numToStrFreq(item,'Hz');
                    break;
                case 2:
                    txt = numToStrFreq(item,'Hz');
                    break;
                case 3:
                    txt = validateCurrent(item,'μA');
                    break;
                case 4:
                    txt = validateDuration(item,' Min');
                    break;
                case 5:
                    txt = numToStrPolarity(item);
                    break;
                default:
                    txt = item;
                    break;
            }

            return(
                <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} key={index} >

                    <Text numberOfLines={1} ellipsizeMode='tail' style={{
                        //flex:1,
                        textAlign:'center',
                        //alignSelf:'center',
                        fontFamily: "Rubik-Regular",
                        fontSize: 14,
                        color: "#3f3f3f",
                        //backgroundColor:'#0f0',
                        }} >{txt}
                    </Text>

                </View>
            );
        });

        return renderList;
    }

    //-------------------------------------------------------------------------------

    return (
        <View style={{width:width,height:42,marginTop:0,marginLeft:0}} >

            <TouchableOpacity style={{width:width,height:42,marginTop:0,position:'absolute'}} 
                            activeOpacity={1}
                            disabled={nowDragging}
                            />

            <View style={{width:width,height:32,marginTop:10,marginLeft:0,borderRadius:5,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
                }} >
                
                <View style={{width:width,height:32,marginTop:0,position:'absolute',flexDirection:'row'}}>

                    <View style={{width:30,height:32,justifyContent:'center',backgroundColor:'#fff'}} key={'index'} >

                        {nowDragging?
                            <Feather name="menu" style={{fontSize: 20,color: '#a9a9a9',marginLeft:8}} />:
                            <Text numberOfLines={1} ellipsizeMode='tail' style={{
                                //flex:1,
                                textAlign:'center',
                                //alignSelf:'center',
                                fontFamily: "Rubik-Regular",
                                fontSize: 14,
                                color: "#3f3f3f",
                                //backgroundColor:'#0f0',
                                }} >{rowIndex+1}
                            </Text>}

                    </View>

                    {
                        renderLine()
                    }

                    <TouchableOpacity style={{width:width,height:32,marginTop:0,position:'absolute'}} 
                        activeOpacity={1}
                        disabled={nowDragging}
                        />

                    <View style={{width:90,height:32,flexDirection:'row',backgroundColor:'#fff'}} key={'actions'} >
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('edit',rowIndex);}} disabled={nowDragging} >
                            <Feather name="edit-2" style={{fontSize: 16,position: 'absolute',color: '#6a707e',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('duplicate',rowIndex);}} disabled={nowDragging} >
                            <Feather name="copy" style={{fontSize: 16,position: 'absolute',color: '#6a707e',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('delete',rowIndex);}} disabled={nowDragging} >
                            <Feather name="trash-2" style={{fontSize: 16,position: 'absolute',color: '#6a707e',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </View>
    );
};

export default MyTableLine;