import React, { useContext, useState, useEffect, useRef } from 'react';
import { View, ScrollView, StyleSheet, Dimensions, Image, Text, TextInput, ActivityIndicator, TouchableOpacity, FlatList } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import MyTopMenu from '../components/MyTopMenu';

import MyMessagePopup from '../components/MyMessagePopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';

import MyEditUserPopup from '../components/MyEditUserPopup';
import MyEditStudyPopup from '../components/MyEditStudyPopup';
import MyEditSitePopup from '../components/MyEditSitePopup';
import MyEditSiteAndStudyPopup from '../components/MyEditSiteAndStudyPopup';

import MyUsersHeader from '../components/MyUsersHeader';
import MyStudiesHeader from '../components/MyStudiesHeader';
import MySitesHeader from '../components/MySitesHeader';
import MySiteAndStudyHeader from '../components/MySiteAndStudyHeader';

import MyUserLine from '../components/MyUserLine';
import MyStudyLine from '../components/MyStudyLine';
import MySiteLine from '../components/MySiteLine';
import MySiteAndStudyLine from '../components/MySiteAndStudyLine';

import MySwitch from '../components/MySwitch';
import { Feather, Fontisto } from '@expo/vector-icons';
import { LocStr, getTimeNow, setAvailableTabs, getDefaultTab, isRoleChecked, defaultMetadataString, validateBool, validateJSONArray, validateArray, validateStr, validateMetadata, foundInTags } from '../utils/Utils'
import { Context as healablesServerContext } from '../context/healablesServerContext';
//import { DraggableGrid } from 'react-native-draggable-grid';
import { expo } from '../../app.json'

import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';

//=========================================================================

const img_search = require('../../assets/images/icon_search.png');
const img_cancel = require('../../assets/images/icon_close_x.png');

const prefKeyShowScrollbars = 'prefKeyShowScrollbars';

//=========================================================================

function cacheImages(images) {
    return images.map(image => {
        if (typeof image === 'string') {
            return Image.prefetch(image);
        } else {
            return Asset.fromModule(image).downloadAsync();
        }
    });
}

function cacheFonts(fonts) {
    return fonts.map(font => Font.loadAsync(font));
}

//----------------------------------------------------------------------

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

let calledFirstGetAllUsersQuery = false;

var reloadResult = {
    isReloading: false,
    reloadedUsers: [],
    reloadedSubjects: [],
    reloadedSortBy: '',
    usersReloaded: false,
    reloadedStudies: [],
    studiesReloaded: false,
    reloadedSites: [],
    sitesReloaded: false,
    reloadedProtocols: [],
    protocolsReloaded: false,
};

export default function AdminScreen({ navigation }) {

    // ============= Incoming params ====================

    let token = navigation.state.params.token;

    let loggedInUserId = navigation.state.params.loggedInUserId;
    var loggedInUserFullname = navigation.state.params.loggedInUserFullname;
    var loggedInUserFirstname = navigation.state.params.loggedInUserFirstname;
    var loggedInUserLastname = navigation.state.params.loggedInUserLastname;
    let loggedInUserEmail = navigation.state.params.loggedInUserEmail;
    let loggedInUserPhone = navigation.state.params.loggedInUserPhone;
    let loggedInUserRoles = navigation.state.params.loggedInUserRoles;

    let language = navigation.state.params.language;

    let serverMode = navigation.state.params.serverMode;

    let loggedInUserIsSiteAdmin = isRoleChecked('siteAdmin', loggedInUserRoles)

    // ============= variables ====================

    const [state, setState] = useState(
        {
            currentTab: '',

            allUsers: [],
            sortUsersBy: 'Name',

            allStudies: [],

            allSites: [],

            allProtocols: [],

            allSubjects: [],
        });

    const [animating, setAnimating] = useState(false);

    const [showScrollbars, setShowScrollbars] = useState(false);

    const [searchText, setSearchText] = useState({
        text: '',
        typing: false,
        typingTimeout: 0,
    });

    const [messagePopupState, setMessagePopupState] = useState({ isMessageVisible: false, title: '' });

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false,
        questionId: '',
        itemId: '',
        title: '',
    });

    const [editUserState, setEditUserState] = useState({
        isVisible: false,
        isNew: true,
        userIndex: -1,
        userItem: {},
    });

    const [editStudyState, setEditStudyState] = useState({
        isVisible: false,
        isNew: true,
        studyIndex: -1,
        studyItem: {},
    });

    const [editSiteState, setEditSiteState] = useState({
        isVisible: false,
        isNew: true,
        siteIndex: -1,
        siteItem: {},
    });

    const [editSiteAndStudyState, setEditSiteAndStudyState] = useState({
        isVisible: false,
        isNew: true,
        siteAndStudyIndex: -1,
        siteAndStudyItem: {},
    });

    //=====================================================================

    const { logoutUserAtHealables,

        getAllUsersAtHealables,
        updateUserAtHealables,
        createUserAtHealables,
        deleteUserAtHealables,
        inviteUserAtHealables,

        getAllProtocolsHeaders,

        getAllStudiesAtHealables,
        updateStudyAtHealables,
        createStudyAtHealables,
        deleteStudyAtHealables,

        getAllSitesAtHealables,
        updateSiteAtHealables,
        createSiteAtHealables,
        deleteSiteAtHealables,
        getMySitesAtHealables,

    } = useContext(healablesServerContext);

    //const { logoutUser,
    //    } = useContext(flaskDataServerContext);

    //######################################################################

    const [fontsLoaded, setFontsLoaded] = useState(false);

    const _loadAssetsAsync = async () => {
        const imageAssets = cacheImages([]);

        const fontAssets = cacheFonts([
            Feather.font,
            Fontisto.font,
            { 'Rubik-Regular': require('../../assets/fonts/Rubik-Regular.ttf') },
            { 'Rubik-Medium': require('../../assets/fonts/Rubik-Medium.ttf') },
            { 'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.ttf') },
        ]);

        await Promise.all([...imageAssets, ...fontAssets]);
    }

    //######################################################################

    const [dimensions, setDimensions] = useState({ window, screen });

    var screenWidth = Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;
    var leftTablesMargin = 50;
    var rightPanelMargin = 50;
    var tableWidth = screenWidth - leftTablesMargin - rightPanelMargin;

    useEffect(() => {
        Dimensions.addEventListener("change", onChange);

        // read settings:
        readSettings();

        return () => {
            Dimensions.removeEventListener("change", onChange);
        };
    }, []);


    const onChange = ({ window, screen }) => {
        setDimensions({ window, screen });
    };

    //----------------------------------------------------------------------

    const readSettings = async () => {
        try {
            let item = await AsyncStorage.getItem(prefKeyShowScrollbars);
            setShowScrollbars(((item !== null) && (item === 'true')));
        } catch (err) {
        }
    }

    //---------------------------------------------------------------------

    const onShowScrollbarsValueChanged = (value) => {
        setShowScrollbars(value)
        AsyncStorage.setItem(prefKeyShowScrollbars, value ? 'true' : 'false');
    }

    //----------------------------------------------------------------------

    const handleSearch = (newText) => {
        switch (state.currentTab) {
            case 'Users':
                serverGetAllUsers(newText.toLowerCase()); // Search
                break;
            case 'Studies':
                serverGetAllStudies(newText.toLowerCase()); // Search
                break;
            case 'Sites':
                if (loggedInUserIsSiteAdmin) {
                    serverGetMySites(newText.toLowerCase()); // Search
                } else {
                    serverGetAllSites(newText.toLowerCase()); // Search
                }
                break;
        }
    }

    //---------------------------------------------------------------------

    const onRefreshPressed = () => {
        setState({
            ...state,
            allUsers: [],
            allStudies: [],
            allSites: [],
            allProtocols: [],
        });
        reloadAllArraysFromServer();
    }

    //----------------------------------------------------------------------

    const reloadAllArraysFromServer = () => {

        reloadResult = {

            isReloading: true,

            reloadedUsers: [],
            reloadedSubjects: [],
            reloadedSortBy: state.sortUsersBy,
            usersReloaded: false,
            reloadedStudies: [],
            studiesReloaded: false,
            reloadedSites: [],
            sitesReloaded: false,
            reloadedProtocols: [],
            protocolsReloaded: false,
        };

        //serverGetAllUsers  ((state.currentTab=='Users'  )?searchText.text:'');    // first call ever
        serverGetAllStudies((state.currentTab == 'Studies') ? searchText.text : '');    // first call ever
        if (loggedInUserIsSiteAdmin) {
            serverGetMySites((state.currentTab == 'Sites') ? searchText.text : '');    // first call ever
        } else {
            serverGetAllUsers((state.currentTab == 'Users') ? searchText.text : '');    // first call ever
            serverGetAllSites((state.currentTab == 'Sites') ? searchText.text : '');    // first call ever
        }
        serverGetAllProtocols();
    }

    const checkIfReloadDone = () => {

        if (loggedInUserIsSiteAdmin) {
            if ((reloadResult.studiesReloaded) &&
                (reloadResult.sitesReloaded)) {
                reloadResult.usersReloaded = true;
            }
        }

        if (reloadResult.usersReloaded &&
            reloadResult.studiesReloaded &&
            reloadResult.sitesReloaded &&
            reloadResult.protocolsReloaded) {

            if (editSiteAndStudyState.isVisible) {
                let oldSiteAndStudiesId = editSiteAndStudyState.siteAndStudyItem.siteAndStudyId;
                let newSiteAndStudiesArray = populateStudiesAndSubjectsForSites(loggedInUserId, reloadResult.reloadedSites);

                let theNewSiteAndItem = newSiteAndStudiesArray.filter((item) => {
                    return (item.siteAndStudyId == oldSiteAndStudiesId)
                })
                if (theNewSiteAndItem.length > 0) {
                    setEditSiteAndStudyState({
                        ...editSiteAndStudyState,
                        siteAndStudyItem: theNewSiteAndItem[0],
                    });
                }
            }

            setState({
                ...state,
                allUsers: reloadResult.reloadedUsers,
                allSubjects: reloadResult.reloadedSubjects,
                sortUsersBy: reloadResult.reloadedSortBy,
                allStudies: reloadResult.reloadedStudies,
                allSites: reloadResult.reloadedSites,
                allProtocols: reloadResult.reloadedProtocols,
            })

            reloadResult.isReloading = false;
        }
    }

    //----------------------------------------------------------------------

    const onLogoutPressed = () => {
        const txt = LocStr('Do you want to logout?', language);
        setQuestionPopupState({ isQuestionVisible: true, questionId: 'logout', title: txt });
    }

    const onMenuPressed = (optionName) => {

        if (reloadResult.isReloading) return;

        switch (optionName) {
            case 'Users':
                break;
            case 'Studies':
                break;
            case 'Sites':
                break;
            case 'Protocols':
                gotoProtocols()
                break;
        }

        calledFirstGetAllUsersQuery = false;

        setState({ ...state, currentTab: optionName })
    }

    const gotoProtocols = () => {

        const paramsForAdmin = {
            token: token,
            loggedInUserId: loggedInUserId,
            loggedInUserFullname: loggedInUserFullname,
            loggedInUserFirstname: loggedInUserFirstname,
            loggedInUserLastname: loggedInUserLastname,
            loggedInUserEmail: loggedInUserEmail,
            loggedInUserPhone: loggedInUserPhone,
            loggedInUserRoles: loggedInUserRoles,
            language: language,
            serverMode: serverMode,
            onSelectTab: onMenuPressed,
        }

        navigation.navigate('ProtocolsScreen', paramsForAdmin);
    }

    //---------------------------------------------------------------------

    const onQuestionAnswerWasNo = (questionId, itemId) => {

        setQuestionPopupState({
            isQuestionVisible: false,
            questionId: '',
            itemId: '',
            title: ''
        });
    }

    const onQuestionAnswerWasYes = (questionId, itemId) => {

        setQuestionPopupState({
            isQuestionVisible: false,
            questionId: '',
            itemId: '',
            title: ''
        });

        switch (questionId) {
            case 'logout':
                serverLogout();
                break;
            case 'delete_site':
                serverDeleteSite(itemId)
                break;
            case 'delete_study':
                serverDeleteStudy(itemId)
                break;
            case 'delete_user':
                serverDeleteUser(itemId)
                break;
            case 'invite_user':
                let theUser = state.allUsers.filter((item) => {
                    return (item.userId == itemId)
                })
                if (theUser.length > 0) {
                    serverInviteUser(itemId, theUser[0])
                }
                break;
        }
    }

    //######################################################################

    const updateStateWithProtocols = (headers) => {

        headers.sort((a, b) => {
            var res = a.protocolName.localeCompare(b.protocolName);
            if (res === 0) {
                res = b.lastUpdate.localeCompare(a.lastUpdate);
            }
            return res;
        });

        if (reloadResult.isReloading) {
            reloadResult.reloadedProtocols = headers
            reloadResult.protocolsReloaded = true
            checkIfReloadDone()
            return
        }

        setState({ ...state, allProtocols: headers });
    }

    //######################################################################

    const roleNameToImportance = (role) => {
        switch (role) {
            case 'superAdmin':
                return 1;
            case 'usersAdmin':
                return 2;
            case 'studyAdmin':
                return 3;
            case 'siteAdmin':
                return 4;
            case 'subject':
                return 5;
        }
    }

    const updateStateWithUsers = (gotUsers, gotSubjects, sortBy = state.sortUsersBy) => {

        if (sortBy == 'Name') {
            gotUsers.sort((a, b) => {
                var res = a.userFullname.localeCompare(b.userFullname);
                if (res === 0) {
                    res = b.lastUpdate.localeCompare(a.lastUpdate);
                }
                return res;
            });
        } else {
            gotUsers.sort((a, b) => {
                let roleA = roleNameToImportance(a.userRoles[0])
                let roleB = roleNameToImportance(b.userRoles[0])
                if (roleA < roleB) {
                    return -1
                } else {
                    if (roleA > roleB) {
                        return 1
                    } else {
                        var res = a.userFullname.localeCompare(b.userFullname);
                        if (res === 0) {
                            res = b.lastUpdate.localeCompare(a.lastUpdate);
                        }
                        return res;
                    }
                }
            });
        }

        if (reloadResult.isReloading) {
            reloadResult.reloadedUsers = gotUsers
            if (gotSubjects != null) {
                reloadResult.reloadedSubjects = gotSubjects
            }
            reloadResult.usersReloaded = true
            checkIfReloadDone()
            return
        }

        if (gotSubjects != null) {
            setState({ ...state, allUsers: gotUsers, sortUsersBy: sortBy, allSubjects: gotSubjects });
        } else {
            setState({ ...state, allUsers: gotUsers, sortUsersBy: sortBy });
        }
    }

    //-----------------------------------------------------

    const updateStateUserWithSort = (sortBy) => {

        updateStateWithUsers(state.allUsers, null, sortBy);
    }

    const updateStateUserAfterDelete = (deletedUserId) => {

        let newArray = state.allUsers.filter((item) => {
            return (item.userId !== deletedUserId);
        });

        updateStateWithUsers(newArray, null);
    }

    const updateStateUserAfterCreate = (newUser, createdUserId) => {

        let now = getTimeNow();
        newUser.lastUpdate = now;
        newUser.createdAt = now;
        newUser.userId = createdUserId;

        let newArray = state.allUsers.filter((item) => {
            return true;
        });
        newArray.push(newUser);

        updateStateWithUsers(newArray, null);
    }

    const updateStateUserAfterUpdate = (userId, newUser) => {

        let now = getTimeNow();
        newUser.lastUpdate = now;

        let newArray = [];
        state.allUsers.map((item) => {
            if (item.userId === userId) {
                newArray.push(newUser);
            } else {
                newArray.push(item);
            }
        });

        updateStateWithUsers(newArray, null);
    }

    const updateStateUserAfterInvite = (userId, newUser) => {

        let pendingUser = { ...newUser, userStatus: 'pending' }
        var foundMatch = false;

        let newArray = [];
        state.allUsers.map((item) => {
            if (item.userId === userId) {
                newArray.push(pendingUser);
                foundMatch = true
            } else {
                newArray.push(item);
            }
        });

        if (!foundMatch) {
            newArray.push(pendingUser);
        }

        updateStateWithUsers(newArray, null);
    }

    //######################################################################

    const updateStateWithStudies = (gotStudies) => {

        gotStudies.sort((a, b) => {
            var res = a.studyShortName.localeCompare(b.studyShortName);
            return res;
        });

        if (reloadResult.isReloading) {
            reloadResult.reloadedStudies = gotStudies
            reloadResult.studiesReloaded = true
            checkIfReloadDone()
            return
        }

        setState({ ...state, allStudies: gotStudies });
    }

    //-----------------------------------------------------

    const updateStateStudyAfterCreate = (newStudy, createdStudyId) => {

        let now = getTimeNow();
        newStudy.lastUpdate = now;
        newStudy.createdAt = now;
        newStudy.studyId = createdStudyId;

        let newArray = state.allStudies.filter((item) => {
            return true;
        });
        newArray.push(newStudy);

        updateStateWithStudies(newArray);
    }

    //-----------------------------------------------------

    const updateStateStudyAfterUpdate = (studyId, newStudy) => {

        let now = getTimeNow();
        newStudy.lastUpdate = now;

        let newArray = [];
        state.allStudies.map((item) => {
            if (item.studyId === studyId) {
                newArray.push(newStudy);
            } else {
                newArray.push(item);
            }
        });

        updateStateWithStudies(newArray);
    }

    //-----------------------------------------------------

    const updateStateStudyAfterDelete = (deletedStudyId) => {

        let newArray = state.allStudies.filter((item) => {
            return (item.studyId !== deletedStudyId);
        });

        updateStateWithStudies(newArray);
    }

    //######################################################################

    const updateStateWithSites = (gotSites) => {

        gotSites.sort((a, b) => {
            var res = a.siteName.localeCompare(b.siteName);
            return res;
        });

        if (reloadResult.isReloading) {
            reloadResult.reloadedSites = gotSites
            reloadResult.sitesReloaded = true
            checkIfReloadDone()
            return
        }

        setState({ ...state, allSites: gotSites });
    }

    //-----------------------------------------------------

    const updateStateSiteAfterCreate = (newSite, createdSiteId) => {

        let now = getTimeNow();
        newSite.lastUpdate = now;
        newSite.createdAt = now;
        newSite.siteId = createdSiteId;

        let newArray = state.allSites.filter((item) => {
            return true;
        });
        newArray.push(newSite);

        updateStateWithSites(newArray);
    }

    //-----------------------------------------------------

    const updateStateSiteAfterUpdate = (siteId, newSite) => {

        let now = getTimeNow();
        newSite.lastUpdate = now;

        let newArray = [];
        state.allSites.map((item) => {
            if (item.siteId === siteId) {
                newArray.push(newSite);
            } else {
                newArray.push(item);
            }
        });

        updateStateWithSites(newArray);
    }

    //-----------------------------------------------------

    const updateStateSiteAfterDelete = (deletedSiteId) => {

        let newArray = state.allSites.filter((item) => {
            return (item.siteId !== deletedSiteId);
        });

        updateStateWithSites(newArray);
    }

    //######################################################################

    const onUserChanged = (userIndex, userItem, isNew) => {
        setEditUserState({
            isVisible: false,
            isNew: true,
            userIndex: -1,
            userItem: {},
        });

        if (isNew) {
            // NEW user - use CSV?
            serverCreateUser(userItem);
        } else {
            // update record on server
            serverUpdateUser(userItem.userId, userItem);  // user changed
        }
    }

    //----------------------------------------------------------------------

    const onUserEditCanceled = () => {
        setEditUserState({
            isVisible: false,
            isNew: true,
            userIndex: -1,
            userItem: {},
        });
    }

    //======================================================================

    const onStudyChanged = (studyIndex, studyItem, isNew) => {
        setEditStudyState({
            isVisible: false,
            isNew: true,
            studyIndex: -1,
            studyItem: {},
        });

        var studyItemForServer = { ...studyItem }
        var sitesArrayForServer = []
        studyItemForServer.studySites.map((item) => {
            if ((item != null) && (item.siteId != null) && (item.siteId != undefined)) {
                sitesArrayForServer.push(item.siteId)
            }
        })
        studyItemForServer.studySites = [...sitesArrayForServer]

        if (isNew) {
            // NEW study - use CSV?
            serverCreateStudy(studyItemForServer, studyItem);
        } else {
            // update record on server
            serverUpdateStudy(studyItemForServer.studyId, studyItemForServer, studyItem);  // study changed
        }
    }

    //----------------------------------------------------------------------

    const onStudyEditCanceled = () => {
        setEditStudyState({
            isVisible: false,
            isNew: true,
            studyIndex: -1,
            studyItem: {},
        });
    }

    //======================================================================

    const onSiteChanged = (siteIndex, siteItem, isNew) => {
        setEditSiteState({
            isVisible: false,
            isNew: true,
            siteIndex: -1,
            siteItem: {},
        });

        if (isNew) {
            // NEW site - use CSV?
            serverCreateSite(siteItem);
        } else {
            // update record on server
            serverUpdateSite(siteItem.siteId, siteItem);  // site changed
        }
    }

    //----------------------------------------------------------------------

    const onSiteEditCanceled = () => {
        setEditSiteState({
            isVisible: false,
            isNew: true,
            studyIndex: -1,
            studyItem: {},
        });
    }

    //======================================================================

    const onSiteAndStudyChanged = (updatedSubjects, forSiteAndStudy) => {
        setEditSiteAndStudyState({
            isVisible: false,
            isNew: true,
            siteAndStudyIndex: -1,
            siteAndStudyItem: {},
        });

        onRefreshPressed()
    }

    //----------------------------------------------------------------------

    const onSiteAndStudyEditCanceled = () => {
        setEditSiteAndStudyState({
            isVisible: false,
            isNew: true,
            studyAndStudyIndex: -1,
            studyAndStudyItem: {},
        });
    }

    //======================================================================

    const onAddItemPressed = () => {

        let now = getTimeNow();

        switch (state.currentTab) {
            case 'Users':
                setEditUserState({
                    isVisible: true,
                    isNew: true,
                    userIndex: -1,
                    userItem: {
                        userId: '',
                        userFullname: '',
                        userFirstname: '',
                        userLastname: '',
                        userEmail: '',
                        userPhone: '',
                        userRoles: [],
                        userAnalgesics: '[]',
                        userStatus: 'new',
                        lastUpdate: now,
                        createdAt: now,
                    },
                });
                break;
            case 'Studies':
                setEditStudyState({
                    isVisible: true,
                    isNew: true,
                    studyIndex: -1,
                    studyItem: {
                        studyId: '',
                        studyShortName: '',
                        studyFullName: '',
                        studyUniqueProtocolId: '',
                        studySites: [],
                        studyMetadata: defaultMetadataString(),
                        lastUpdate: now,
                        createdAt: now,
                    },
                });
                break;
            case 'Sites':
                setEditSiteState({
                    isVisible: true,
                    isNew: true,
                    siteIndex: -1,
                    siteItem: {
                        siteId: '',
                        siteName: '',
                        siteOwnerId: '',
                        siteSubjects: [],
                        lastUpdate: now,
                        createdAt: now,
                    },
                });
                break;
        }
    }

    //######################################################################

    const onUserActionPressed = (actionPressed, rowIndex) => {

        var txt;

        let theUser = state.allUsers[rowIndex];
        let now = getTimeNow();

        switch (actionPressed) {
            case 'edit':
                setEditUserState({
                    isVisible: true,
                    isNew: false,
                    userIndex: rowIndex,
                    userItem: {
                        userId: validateStr(theUser.userId),
                        userFullname: validateStr(theUser.userFullname),
                        userFirstname: validateStr(theUser.userFirstname),
                        userLastname: validateStr(theUser.userLastname),
                        userEmail: validateStr(theUser.userEmail),
                        userPhone: validateStr(theUser.userPhone),
                        userRoles: validateArray(theUser.userRoles),
                        userAnalgesics: '[]',
                        userStatus: validateStr(theUser.userStatus),
                        lastUpdate: now,
                        createdAt: theUser.createdAt,
                    },
                });
                break;
            case 'delete':
                txt = LocStr('Are you sure you want to delete this user?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'delete_user', itemId: theUser.userId, title: txt });
                break;
            case 'invite':
                txt = LocStr('Send invitation to this user?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'invite_user', itemId: theUser.userId, title: txt });
                break;
        }
    }

    const renderUser = ({ item, index, separators }) => {
        let loggedInUserIsAdmin = isRoleChecked('superAdmin', loggedInUserRoles)
        let thisUserIsAdmin = isRoleChecked('superAdmin', item.userRoles)
        let canEdit = loggedInUserIsAdmin || !thisUserIsAdmin
        let canDelete = loggedInUserIsAdmin || !thisUserIsAdmin
        let canInvite = loggedInUserIsAdmin || !thisUserIsAdmin

        return (
            <View style={{ width: tableWidth - 2, height: 34, marginTop: 0 }} key={item.key} >
                <MyUserLine style={{ width: tableWidth - 2, height: 42, marginTop: 0 }}
                    width={tableWidth - 2}
                    rowIndex={index}
                    canEdit={canEdit} canDelete={canDelete} canInvite={canInvite}
                    theUser={item}
                    onActionPressed={(action, rowIndex) => { onUserActionPressed(action, rowIndex) }}
                    language={language}
                />
            </View>
        );
    }

    //######################################################################

    const onStudyActionPressed = (actionPressed, rowIndex) => {

        var txt;

        let theStudy = state.allStudies[rowIndex];
        let now = getTimeNow();

        switch (actionPressed) {
            case 'edit':
                setEditStudyState({
                    isVisible: true,
                    isNew: false,
                    studyIndex: -1,
                    studyItem: {
                        studyId: validateStr(theStudy.studyId),
                        studyShortName: validateStr(theStudy.studyShortName),
                        studyFullName: validateStr(theStudy.studyFullName),
                        studyUniqueProtocolId: validateStr(theStudy.studyUniqueProtocolId),
                        studySites: validateArray(theStudy.studySites),
                        studyMetadata: validateStr(theStudy.studyMetadata),
                        lastUpdate: now,
                        createdAt: theStudy.createdAt,
                    },
                });
                break;
            case 'delete':
                txt = LocStr('Are you sure you want to delete this study?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'delete_study', itemId: theStudy.studyId, title: txt });
                break;
        }
    }

    //-----------------------------------------------------

    const getProtocolNameFromUniqueId = (theId) => {
        if (theId === '') return '';

        let idx = state.allProtocols.findIndex((item) => {
            return (item.protocolNum === theId);
        })
        if (idx === -1) {
            return '';
        } else {
            return state.allProtocols[idx].protocolName;
        }
    }

    const renderStudy = ({ item, index, separators }) => {
        let loggedInUserSuperOrStudies =
            isRoleChecked('superAdmin', loggedInUserRoles) ||
            isRoleChecked('studyAdmin', loggedInUserRoles);

        let canEdit = loggedInUserSuperOrStudies
        let canDelete = loggedInUserSuperOrStudies
        let canInvite = loggedInUserSuperOrStudies

        return (
            <View style={{ width: tableWidth - 2, height: 34, marginTop: 0 }} key={item.key} >
                <MyStudyLine style={{ width: tableWidth - 2, height: 42, marginTop: 0 }}
                    width={tableWidth - 2}
                    rowIndex={index}
                    canEdit={canEdit} canDelete={canDelete} canInvite={canInvite}
                    theStudy={item}
                    protocolName={getProtocolNameFromUniqueId(item.studyUniqueProtocolId)}
                    onActionPressed={(action, rowIndex) => { onStudyActionPressed(action, rowIndex) }}
                    language={language}
                />
            </View>
        );
    }

    //######################################################################

    const onSiteAndStudyActionPressed = (theItem) => {

        if (theItem.siteStudyId == 'no_studies') {
            let txt = LocStr('No studies assigned to this site yet', language)
            setMessagePopupState({ isMessageVisible: true, title: txt })
            return;
        }

        setEditSiteAndStudyState({
            isVisible: true,
            isNew: false,
            siteAndStudyIndex: -1,
            siteAndStudyItem: theItem,
        });
    }

    //######################################################################

    const onSiteActionPressed = (actionPressed, rowIndex) => {

        var txt;

        let theSite = state.allSites[rowIndex];
        let now = getTimeNow();

        switch (actionPressed) {
            case 'edit':
                setEditSiteState({
                    isVisible: true,
                    isNew: false,
                    siteIndex: -1,
                    siteItem: {
                        siteId: validateStr(theSite.siteId),
                        siteName: validateStr(theSite.siteName),
                        siteOwnerId: validateStr(theSite.siteOwnerId),
                        siteSubjects: validateArray(theSite.siteSubjects),
                        lastUpdate: now,
                        createdAt: theSite.createdAt,
                    },
                });
                break;
            case 'delete':
                txt = LocStr('Are you sure you want to delete this site?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'delete_site', itemId: theSite.siteId, title: txt });
                break;
        }
    }

    //-----------------------------------------------------

    const renderSite = ({ item, index, separators }) => {
        let loggedInUserSuperOrStudies =
            isRoleChecked('superAdmin', loggedInUserRoles) ||
            isRoleChecked('studyAdmin', loggedInUserRoles);

        let canEdit = loggedInUserSuperOrStudies || loggedInUserIsSiteAdmin
        let canDelete = loggedInUserSuperOrStudies
        let canInvite = loggedInUserSuperOrStudies

        return (
            <View style={{ width: tableWidth - 2, height: 34, marginTop: 0 }} key={item.key} >
                <MySiteLine style={{ width: tableWidth - 2, height: 42, marginTop: 0 }}
                    width={tableWidth - 2}
                    actionsColumnWidth={30/*60*/}
                    rowIndex={index}
                    canEdit={canEdit} canDelete={canDelete} canInvite={canInvite}
                    theSite={item}
                    ownerName={getOwnerNameFromId(item.siteOwnerId)}
                    ownerPhone={getOwnerPhoneFromId(item.siteOwnerId)}
                    ownerEmail={getOwnerEmailFromId(item.siteOwnerId)}
                    onActionPressed={(action, rowIndex) => { onSiteActionPressed(action, rowIndex) }}
                    language={language}
                />
            </View>
        );
    }

    const getOwnerNameFromId = (theId) => {
        if (theId === '') return '';

        let idx = state.allUsers.findIndex((item) => {
            return (item.userId === theId);
        })
        if (idx === -1) {
            return '';
        } else {
            return state.allUsers[idx].userFullname;
        }
    }

    const getOwnerEmailFromId = (theId) => {
        if (theId === '') return '';

        let idx = state.allUsers.findIndex((item) => {
            return (item.userId === theId);
        })
        if (idx === -1) {
            return '';
        } else {
            return state.allUsers[idx].userEmail;
        }
    }

    const getOwnerPhoneFromId = (theId) => {
        if (theId === '') return '';

        let idx = state.allUsers.findIndex((item) => {
            return (item.userId === theId);
        })
        if (idx === -1) {
            return '';
        } else {
            return state.allUsers[idx].userPhone;
        }
    }

    //========================================================================================================

    function countStudiesInEachSite(in_allSites, in_allStudies) {
        var sitesIdsWithStudies = [];

        in_allStudies.map((studyItem) => {
            studyItem.studySites.map((item) => {
                sitesIdsWithStudies.push(item.siteId)
            })
        })

        var resArray = [];

        in_allSites.map((siteItem) => {

            let newRec = {
                ...siteItem,
                hasStudies: (sitesIdsWithStudies.includes(siteItem.siteId))
            }
            resArray.push(newRec)
        })

        return resArray;
    }

    //========================================================================================================

    function populateStudiesAndSubjectsForSites(userId, in_allSites = state.allSites) {

        var resArray = [];

        in_allSites.map((siteItem) => {

            if (siteItem.siteStudies.length == 0) {
                let newRec = {

                    siteAndStudyId: siteItem.siteId + 'no_studies',

                    siteId: siteItem.siteId,
                    siteName: siteItem.siteName,
                    siteOwnerId: siteItem.siteOwnerId,

                    siteStudyId: 'no_studies',
                    siteStudyShortName: '*** ' + LocStr('No studies assigned to this site yet', language) + ' ***',

                    siteSubjects: [],

                    lastUpdate: siteItem.lastUpdate,
                    createdAt: siteItem.createdAt,
                }

                resArray.push(newRec)

            } else {

                siteItem.siteStudies.map((studyItem) => {

                    var arrSubjects = []
                    siteItem.siteSubjects.map((subjectItem) => {

                        const newSubject = {
                            userId: validateStr(subjectItem._id),
                            userFullname: validateStr(subjectItem.firstName + ' ' + subjectItem.lastName),
                            userFirstname: validateStr(subjectItem.firstName),
                            userLastname: validateStr(subjectItem.lastName),
                            userEmail: validateStr(subjectItem.email),
                            userPhone: validateStr(subjectItem.phone),
                            userRoles: validateArray(subjectItem.role),
                            userAnalgesics: validateJSONArray(subjectItem.analgesic),
                            userStatus: validateStr(subjectItem.status),
                            lastUpdate: subjectItem.updatedAt,
                            createdAt: subjectItem.createdAt,
                        }

                        arrSubjects.push(newSubject)
                    })


                    let newRec = {

                        siteAndStudyId: siteItem.siteId + studyItem._id,

                        siteId: siteItem.siteId,
                        siteName: siteItem.siteName,
                        siteOwnerId: siteItem.siteOwnerId,

                        siteStudyId: studyItem._id,
                        siteStudyShortName: studyItem.briefTitle,

                        siteSubjects: arrSubjects,

                        lastUpdate: siteItem.lastUpdate,
                        createdAt: siteItem.createdAt,
                    }

                    resArray.push(newRec)
                })
            }
        })

        return resArray;
    }

    //--------------------------------------------------------------------------------------------------------

    const renderSiteAndStudy = ({ item, index, separators }) => {
        return (
            <View style={{ width: tableWidth - 2, height: 34, marginTop: 0 }} key={item.key} >
                <MySiteAndStudyLine style={{ width: tableWidth - 2, height: 42, marginTop: 0 }}
                    width={tableWidth - 2}
                    actionsColumnWidth={30}
                    rowIndex={index}
                    canEdit={true}
                    theSiteAndStudy={item}
                    onActionPressed={(action, rowIndex) => { onSiteAndStudyActionPressed(item) }}
                    language={language}
                />
            </View>
        );
    }

    //========================================================================================================

    function filterByRole(theRole) {
        let res = state.allUsers.filter((item) => {
            return (item.userRoles.includes(theRole));
        });
        return res
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const validateSitesArray = (src) => {
        if ((src == null) || (src == undefined)) return [];

        var newArray = [];
        src.map((item) => {
            const newItem = {
                siteId: validateStr(item._id),
                siteName: validateStr(item.siteName),
                siteOwnerId: validateStr(item.siteOwner),
                siteSubjects: validateArray(item.subjects),
                lastUpdate: item.updatedAt,
                createdAt: item.createdAt,
            }
            newArray.push(newItem)
        })

        return newArray;
    }

    //-------------------------------------------------------------------------------

    const serverLogout = async () => {
        try {
            setAnimating(true);

            logoutUserAtHealables(token,
                (result) => {
                    setAnimating(false);

                    calledFirstGetAllUsersQuery = false;

                    navigation.pop();
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    /*
    const serverLogout = async () => {
        try {
            setAnimating(true);
  
            logoutUser(serverMode, token, 
                (result) => {
                    setAnimating(false);
                    
                    navigation.pop();
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    }
    */

    //######################################################################

    const serverGetAllUsers = async (filterText = '') => {
        try {

            setAnimating(true);

            getAllUsersAtHealables(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var gotUsers = [];
                    var gotSubjects = [];
                    result.map((item, index) => {
                        // extract 'details' field:

                        const newItem = {
                            userId: validateStr(item._id),
                            userFullname: validateStr(item.firstName + ' ' + item.lastName),
                            userFirstname: validateStr(item.firstName),
                            userLastname: validateStr(item.lastName),
                            userEmail: validateStr(item.email),
                            userPhone: validateStr(item.phone),
                            userRoles: validateArray(item.role),
                            userAnalgesics: validateJSONArray(item.analgesic),
                            userStatus: validateStr(item.status),
                            lastUpdate: item.updatedAt,
                            createdAt: item.createdAt,
                        }

                        if (newItem.userRoles.includes('subject')) {
                            gotSubjects.push(newItem);
                        } else {
                            if ((filterText.length === 0) ||
                                (newItem.userFirstname.toLowerCase().includes(filterText)) ||
                                (newItem.userLastname.toLowerCase().includes(filterText))) {
                                gotUsers.push(newItem);
                            }
                        }
                    });

                    updateStateWithUsers(gotUsers, gotSubjects);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //######################################################################

    const serverUpdateUser = async (userId, newUser) => {
        try {

            setAnimating(true);

            updateUserAtHealables(token, userId, newUser,
                (result) => {
                    setAnimating(false);
                    updateStateUserAfterUpdate(userId, newUser);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverDeleteUser = async (userId) => {
        try {

            setAnimating(true);

            deleteUserAtHealables(token, userId,
                (result) => {
                    updateStateUserAfterDelete(userId);
                    setAnimating(false);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverCreateUser = async (userInfo) => {
        try {

            setAnimating(true);

            createUserAtHealables(token, userInfo,
                (result) => {
                    updateStateUserAfterCreate(userInfo, result);

                    serverInviteUser(result, userInfo)
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverInviteUser = async (userId, newUser) => {
        try {

            setAnimating(true);

            inviteUserAtHealables(token, userId,
                (result) => {
                    updateStateUserAfterInvite(userId, newUser);
                    setAnimating(false);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################
    //######################################################################

    const serverGetAllStudies = async (filterText = '') => {
        try {

            setAnimating(true);

            getAllStudiesAtHealables(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var gotStudies = [];
                    result.map((item, index) => {
                        // extract 'details' field:

                        const newItem = {
                            studyId: validateStr(item._id),
                            studyShortName: validateStr(item.briefTitle),
                            studyFullName: validateStr(item.officialTitle),
                            studyUniqueProtocolId: validateStr(item.uniqueProtocolId),
                            studySites: validateSitesArray(item.sites),
                            studyMetadata: validateMetadata(item.metadata),
                            lastUpdate: item.updatedAt,
                            createdAt: item.createdAt,
                        }

                        if ((filterText.length === 0) ||
                            (newItem.studyShortName.toLowerCase().includes(filterText)) ||
                            (newItem.studyFullName.toLowerCase().includes(filterText))) {
                            gotStudies.push(newItem);
                        }
                    });

                    updateStateWithStudies(gotStudies);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //######################################################################

    const serverCreateStudy = async (studyInfo, studyFullItem) => {
        try {

            setAnimating(true);

            createStudyAtHealables(token, studyInfo,
                (result) => {
                    setAnimating(false);
                    updateStateStudyAfterCreate(studyFullItem, result);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverUpdateStudy = async (studyId, newStudy, studyFullItem) => {
        try {

            setAnimating(true);

            updateStudyAtHealables(token, studyId, newStudy,
                (result) => {
                    setAnimating(false);
                    updateStateStudyAfterUpdate(studyId, studyFullItem);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverDeleteStudy = async (studyId) => {
        try {

            setAnimating(true);

            deleteStudyAtHealables(token, studyId,
                (result) => {
                    setAnimating(false);
                    updateStateStudyAfterDelete(studyId);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################
    //######################################################################

    const serverGetMySites = async (filterText = '') => {
        try {
            setAnimating(true);

            getMySitesAtHealables(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var gotSites = [];
                    result.map((item, index) => {
                        // extract 'details' field:

                        const newItem = {
                            siteId: validateStr(item._id),
                            siteName: validateStr(item.siteName),
                            siteOwnerId: validateStr(item.siteOwner),
                            siteSubjects: validateArray(item.subjects),
                            siteStudies: validateArray(item.studies),
                            lastUpdate: item.updatedAt,
                            createdAt: item.createdAt,
                        }

                        if ((!loggedInUserIsSiteAdmin) ||
                            (loggedInUserId == newItem.siteOwnerId)) {
                            if ((filterText.length == 0) ||
                                (newItem.siteName.toLowerCase().includes(filterText))) {
                                gotSites.push(newItem);
                            }
                        }
                    });

                    updateStateWithSites(gotSites);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //######################################################################

    const serverGetAllSites = async (filterText = '') => {
        try {
            setAnimating(true);

            getAllSitesAtHealables(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var gotSites = [];
                    result.map((item, index) => {
                        // extract 'details' field:

                        const newItem = {
                            siteId: validateStr(item._id),
                            siteName: validateStr(item.siteName),
                            siteOwnerId: validateStr(item.siteOwner._id),
                            siteSubjects: validateArray(item.subjects),
                            lastUpdate: item.updatedAt,
                            createdAt: item.createdAt,
                        }

                        if ((!loggedInUserIsSiteAdmin) ||
                            (loggedInUserId == newItem.siteOwnerId)) {
                            if ((filterText.length == 0) ||
                                (newItem.siteName.toLowerCase().includes(filterText))) {
                                gotSites.push(newItem);
                            }
                        }
                    });

                    updateStateWithSites(gotSites);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //######################################################################

    const serverCreateSite = async (siteInfo) => {
        try {

            setAnimating(true);

            createSiteAtHealables(token, siteInfo,
                (result) => {
                    setAnimating(false);
                    updateStateSiteAfterCreate(siteInfo, result);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverUpdateSite = async (siteId, newSite) => {
        try {

            setAnimating(true);

            updateSiteAtHealables(token, siteId, newSite,
                (result) => {
                    setAnimating(false);
                    updateStateSiteAfterUpdate(siteId, newSite);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    const serverDeleteSite = async (siteId) => {
        try {

            setAnimating(true);

            deleteSiteAtHealables(token, siteId,
                (result) => {
                    setAnimating(false);
                    updateStateSiteAfterDelete(siteId);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################
    //######################################################################

    const serverGetAllProtocols = async (filterText = '') => {
        try {

            setAnimating(true);

            getAllProtocolsHeaders(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var headers = [];
                    result.map((item, index) => {
                        // extract 'details' field:
                        let newItem = {
                            protocolId: validateStr(item._id),
                            protocolName: validateStr(item.protocolName),
                            protocolNum: validateStr(item.protocolNum),
                            authorName: validateStr(item.authorName),
                            authorId: validateStr(item.authorId),
                            field_tags: validateArray(item.field_tags),
                            field_protected: validateBool(item.field_protected),
                            lastUpdate: validateStr(item.lastUpdate),
                            CreatedAt: validateStr(item.CreatedAt),
                            currentEditor: item.currentEditor,
                        };
                        if ((filterText.length === 0) ||
                            (newItem.protocolName.toLowerCase().includes(filterText)) ||
                            (newItem.protocolNum.toLowerCase().includes(filterText)) ||
                            (newItem.authorName.toLowerCase().includes(filterText)) ||
                            (foundInTags(newItem.field_tags, filterText))) {
                            headers.push(newItem);
                        }
                    });

                    updateStateWithProtocols(headers);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //######################################################################
    //######################################################################

    // ============= render ====================

    if (screenWidth < 900) {
        return (
            <View style={styles.container}>
                <View style={{ height: screenHeight, width: screenWidth, justifyContent: 'center' }} >
                    <Text style={styles.loadingTitle} >{LocStr('Sorry - window size too small to display app...\n\nAre you trying to use the app on a phone/tablet?\n\nTry rotating to landscape position.', language)}</Text>
                </View>
            </View>
        );
    }

    if (!calledFirstGetAllUsersQuery) {
        calledFirstGetAllUsersQuery = true;
        reloadAllArraysFromServer();
    }

    if (!fontsLoaded) {
        return (
            <AppLoading
                startAsync={_loadAssetsAsync()}
                onFinish={() => { setFontsLoaded(true); }}
                onError={console.warn}
            />
        );
    }

    if (state.currentTab.length == 0) {
        setState({ ...state, currentTab: getDefaultTab(loggedInUserRoles) })
    }

    // mytodo: set according to role
    let canAdd = true;

    return (
        <View style={styles.container}>

            <View style={styles.bodyStyle} >

                {/*---------------------------------------------------   T a b l e s   -------------------------------------------------*/}

                <View style={{ width: screenWidth, height: screenHeight - 60 - 40, marginTop: 40 + 60, flexDirection: 'row' }} >

                    {/*---------------   T a b l e   n a m e  &  s h o w   s c r o l l b a r s :   ----------------*/}
                    <View style={{ marginLeft: leftTablesMargin, marginTop: 16, width: tableWidth, height: 30, justifyContent: 'center', position: 'absolute' }} >

                        <Text style={[styles.lblTitle, { marginLeft: 0, marginTop: 0, color: '#6a707e' }]} >{state.currentTab}</Text>
                        <Text style={[styles.lblSubTitle, { marginLeft: tableWidth - 200 - 34 - 7, width: 200, marginTop: 0, position: 'absolute', color: '#6a707e', textAlign: 'right' }]} >{LocStr('Show scrollbars', language)}</Text>
                        <View style={{ marginLeft: tableWidth - 34, width: 34, height: 17, marginTop: 0, position: 'absolute', backgroundColor: '#ffffff' }} >
                            <MySwitch style={{ width: 34, height: 17, marginTop: 0, position: 'absolute' }}
                                marginTop={0}
                                value={showScrollbars}
                                onValueChanged={(value) => { onShowScrollbarsValueChanged(value) }} />
                        </View>
                    </View>

                    {/*---------------   S e a r c h   &   A d d   i t e m   b u t t o n :   ----------------*/}

                    <View style={{ marginLeft: leftTablesMargin, marginTop: 16 + 30 + 10, width: tableWidth, height: 30, justifyContent: 'center', position: 'absolute' }} >

                        {loggedInUserIsSiteAdmin ? null :
                            <TouchableOpacity style={{ marginLeft: tableWidth - 30, marginTop: 0, width: 30, height: 30, position: 'absolute' }}
                                onPress={() => { onAddItemPressed(); }}
                                disabled={!canAdd}
                            >
                                <Feather name="plus-circle" style={{ fontSize: 30, position: 'absolute', color: canAdd ? '#6a707e' : '#d9d9d9' }} />
                            </TouchableOpacity>}

                        <TouchableOpacity style={{ marginLeft: tableWidth - (loggedInUserIsSiteAdmin ? 30 : 70), marginTop: 0, width: 30, height: 30, position: 'absolute' }}
                            onPress={() => { onRefreshPressed(); }} >
                            <Feather name="refresh-ccw" style={{ fontSize: 30, position: 'absolute', color: '#6a707e' }} />
                        </TouchableOpacity>

                        <View style={{ width: 500, height: 27, marginLeft: 0, marginTop: 2, flexDirection: 'row' }} >
                            <View style={{
                                width: 500, height: 25, borderRadius: 5, backgroundColor: '#fff',
                                shadowColor: '#000',
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.22,
                                shadowRadius: 12,
                            }} >
                                <TextInput style={{
                                    marginLeft: 0, paddingLeft: 5, borderRadius: 5, height: 25, backgroundColor: '#fff',
                                    fontFamily: "Rubik-Medium", fontSize: 12, color: '#5f5f5f',
                                    borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                                }}
                                    value={searchText.text} multiline={false} selectTextOnFocus={true} maxLength={30}
                                    placeholder={LocStr('Search', language)} placeholderTextColor='#a9a9a9'
                                    onChangeText={(newText) => {

                                        // clear triggerL
                                        if (searchText.typingTimeout) {
                                            clearTimeout(searchText.typingTimeout);
                                        }

                                        if (newText.length <= 30) {
                                            setSearchText({
                                                text: newText.toLowerCase(),
                                                typing: false,
                                                typingTimeout: setTimeout(function () {
                                                    handleSearch(newText);
                                                }, 1500)
                                            });
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.nativeEvent.key == "Enter") {
                                            if (searchText.typingTimeout) {
                                                clearTimeout(searchText.typingTimeout);
                                            }
                                            handleSearch(searchText.text);
                                        }
                                    }}
                                //ref={(input) => { textInput4 = input; }}
                                />

                                <TouchableOpacity style={{ marginLeft: 500 - 25, marginTop: 0, width: 25, height: 25, position: 'absolute' }}
                                    onPress={() => {
                                        if (searchText.typingTimeout) {
                                            clearTimeout(searchText.typingTimeout);
                                        }
                                        setSearchText({ ...searchText, text: '' });
                                        handleSearch('');
                                    }}
                                    disabled={(searchText.text.length === 0)}
                                >
                                    <Image fadeDuration={0}
                                        style={{ width: 19, height: 19, marginLeft: 3, marginTop: 4, position: 'absolute' }}
                                        source={(searchText.text.length > 0) ? img_cancel : img_search} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>

                    {/*---------------   L i s t s         ----------------*/}

                    {(state.currentTab == 'Users') ?
                        <View style={{
                            marginLeft: leftTablesMargin,
                            marginTop: 16 + 30 + 10 + 30 + 16,
                            width: tableWidth,
                            height: screenHeight - 60 - 40 - (16 + 30 + 10 + 30 + 16) - 30,
                            position: 'absolute',
                            shadowColor: '#000',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.22,
                            shadowRadius: 12,
                        }} >

                            <MyUsersHeader style={{ width: tableWidth - 2, height: 32 }}
                                width={tableWidth - 2}
                                actionsColumnWidth={90}
                                sortByName={state.sortUsersBy === 'Name'}
                                arrTitles={['Name', 'Email', 'Phone', 'Role', 'Status']}
                                onHeaderPressed={(headerName) => {
                                    updateStateUserWithSort(headerName)
                                }}
                                language={language}
                            />

                            <FlatList style={{ width: tableWidth - 2, height: screenHeight - 60 - 40 - 20 - 22 - 20 - 17 - 20 - 30 }}
                                data={state.allUsers}
                                renderItem={renderUser}
                                keyExtractor={(item, index) => { return item.userId; }}
                                showsVerticalScrollIndicator={showScrollbars}
                            />

                        </View> : null}

                    {/*---------------------------*/}

                    {(state.currentTab == 'Studies') ?
                        <View style={{
                            marginLeft: leftTablesMargin,
                            marginTop: 16 + 30 + 10 + 30 + 16,
                            width: tableWidth,
                            height: screenHeight - 60 - 40 - (16 + 30 + 10 + 30 + 16) - 30,
                            position: 'absolute',
                            shadowColor: '#000',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.22,
                            shadowRadius: 12,
                        }} >

                            <MyStudiesHeader style={{ width: tableWidth - 2, height: 32 }}
                                width={tableWidth - 2}
                                actionsColumnWidth={60}
                                sortByName={state.sortUsersBy == 'Name'}
                                arrTitles={['Title', 'Protocol', 'Sites', 'Configuration']}
                                onHeaderPressed={(headerName) => {
                                }}
                                language={language}
                            />

                            <FlatList style={{ width: tableWidth - 2, height: screenHeight - 60 - 40 - 20 - 22 - 20 - 17 - 20 - 30 }}
                                data={state.allStudies}
                                renderItem={renderStudy}
                                keyExtractor={(item, index) => { return item.studyId; }}
                                showsVerticalScrollIndicator={showScrollbars}
                            />

                        </View> : null}

                    {/*---------------------------*/}

                    {(state.currentTab == 'Sites') ?
                        <View style={{
                            marginLeft: leftTablesMargin,
                            marginTop: 16 + 30 + 10 + 30 + 16,
                            width: tableWidth,
                            height: screenHeight - 60 - 40 - (16 + 30 + 10 + 30 + 16) - 30,
                            position: 'absolute',
                            shadowColor: '#000',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.22,
                            shadowRadius: 12,
                        }} >

                            {loggedInUserIsSiteAdmin ?
                                <MySiteAndStudyHeader style={{ width: tableWidth - 2, height: 32 }}
                                    width={tableWidth - 2}
                                    actionsColumnWidth={30}
                                    sortByName={true}
                                    arrTitles={['Site name', 'Study', 'Subjects']}
                                    onHeaderPressed={(headerName) => {
                                    }}
                                    language={language}
                                /> :
                                <MySitesHeader style={{ width: tableWidth - 2, height: 32 }}
                                    width={tableWidth - 2}
                                    actionsColumnWidth={30/*60*/}
                                    sortByName={true}
                                    arrTitles={['Site name', 'Manager', 'Phone', 'Email', 'Studies']}
                                    onHeaderPressed={(headerName) => {
                                    }}
                                    language={language}
                                />}

                            {loggedInUserIsSiteAdmin ?
                                <FlatList style={{ width: tableWidth - 2, height: screenHeight - 60 - 40 - 20 - 22 - 20 - 17 - 20 - 30 }}
                                    data={populateStudiesAndSubjectsForSites(loggedInUserId)}
                                    renderItem={renderSiteAndStudy}
                                    keyExtractor={(item, index) => { return item.siteAndStudyId; }}
                                    showsVerticalScrollIndicator={showScrollbars}
                                /> :
                                <FlatList style={{ width: tableWidth - 2, height: screenHeight - 60 - 40 - 20 - 22 - 20 - 17 - 20 - 30 }}
                                    data={countStudiesInEachSite(state.allSites, state.allStudies)}
                                    renderItem={renderSite}
                                    keyExtractor={(item, index) => { return item.siteId; }}
                                    showsVerticalScrollIndicator={showScrollbars}
                                />}

                        </View> : null}

                </View>

            </View>

            {/* ================================   T o p   b a r   ================================ */}

            <View style={{
                height: 60,
                width: screenWidth,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.12,
                shadowRadius: 12,
                justifyContent: 'center',
                //alignItems: 'center',
                backgroundColor: '#fff',
                position: 'absolute',
            }}
            >
                <Text style={styles.topBarTitle} >Healables Protocols {process.env.ENV} - Version {require('../../package.json').version}</Text>
                <View style={styles.topBarImageAndVer} >
                    <Image fadeDuration={0} style={styles.topBarImage} source={require('../../assets/images/healables.png')} />
                    <Text style={styles.topBarVersion} >{expo.version}</Text>
                </View>

                {animating ?
                    <ActivityIndicator
                        animating={animating}
                        color='#001a2f'
                        size='large'
                        style={styles.activityIndicator}
                    /> :
                    <TouchableOpacity style={{ width: 200, height: 20, marginLeft: screenWidth - 20 - 200, position: 'absolute' }} activeOpacity={1} onPress={() => { onLogoutPressed() }} >
                        <Text style={{ width: 176, height: 20, marginTop: 2, textAlign: 'right', fontFamily: "Rubik-Regular", fontSize: 12, color: "#7f7f7f" }} >{loggedInUserFullname}</Text>
                        <Feather name="log-out" style={{ width: 20, height: 20, marginLeft: 180, position: 'absolute', fontSize: 18, color: '#3f3f3f' }} />
                    </TouchableOpacity>
                }

            </View>

            {/* ================================   M e n u   b a r   ================================ */}

            <MyTopMenu style={{ width: screenWidth, height: 40, marginTop: 60 }}
                width={screenWidth}
                selectedTab={state.currentTab}
                arrTabs={setAvailableTabs(loggedInUserRoles)}
                onTabSelected={(selectedTab) => { onMenuPressed(selectedTab) }}
                language={language}
            />

            {/* ================================   P o p u p s   ================================ */}

            {editUserState.isVisible ?
                <MyEditUserPopup
                    parentWidth={screenWidth}
                    onValueChanged={(userIndex, userItem, isNew) => { onUserChanged(userIndex, userItem, isNew) }}
                    onCanceled={() => onUserEditCanceled()}
                    userIndex={editUserState.userIndex}
                    userItem={editUserState.userItem}
                    isNew={editUserState.isNew}
                    token={token}
                    language={language}
                /> : null
            }

            {editStudyState.isVisible ?
                <MyEditStudyPopup
                    parentWidth={screenWidth}
                    onValueChanged={(studyIndex, studyItem, isNew) => { onStudyChanged(studyIndex, studyItem, isNew) }}
                    onCanceled={() => onStudyEditCanceled()}
                    studyIndex={editStudyState.studyIndex}
                    studyItem={editStudyState.studyItem}
                    isNew={editStudyState.isNew}
                    token={token}

                    arrUsers={state.allUsers}
                    onUserAdded={(newUser) => {
                        var newArray = [...state.allUsers]
                        newArray.push(newUser)
                        setState({ ...state, allUsers: newArray })
                    }}

                    arrSites={state.allSites}
                    onSiteAdded={(newSite) => {
                        var newArray = [...state.allSites]
                        newArray.push(newSite)
                        setState({ ...state, allSites: newArray })
                    }}

                    arrStudies={state.allStudies}

                    arrProtocols={state.allProtocols}
                    language={language}
                /> : null
            }

            {editSiteState.isVisible ?
                <MyEditSitePopup
                    parentWidth={screenWidth}
                    onValueChanged={(siteIndex, siteItem, isNew) => { onSiteChanged(siteIndex, siteItem, isNew) }}
                    onCanceled={() => onSiteEditCanceled()}
                    siteIndex={editSiteState.siteIndex}
                    siteItem={editSiteState.siteItem}
                    isNew={editSiteState.isNew}
                    arrUsers={filterByRole('siteAdmin')}
                    onUserAdded={(newUser) => {
                        var newArray = [...state.allUsers]
                        newArray.push(newUser)
                        setState({ ...state, allUsers: newArray })
                    }}
                    token={token}
                    language={language}
                /> : null
            }

            {editSiteAndStudyState.isVisible ?
                <MyEditSiteAndStudyPopup
                    popupWidth={tableWidth}
                    popupHeight={screenHeight - 60 - 40 - (16 + 30 + 10 + 30)}
                    popupX={leftTablesMargin}
                    popupY={60 + 40 + 16 + 30 + 10}

                    siteAndStudy={editSiteAndStudyState.siteAndStudyItem}
                    onSiteSubjectsChanged={(updatedSubjects, forSiteAndStudy) => {
                        onSiteAndStudyChanged(updatedSubjects, forSiteAndStudy)
                    }}
                    onCancelPressed={() => onSiteAndStudyEditCanceled()}
                    onRefreshPressed={() => onRefreshPressed()}
                    token={token}
                    language={language}
                /> : null
            }

            {messagePopupState.isMessageVisible ?
                <MyMessagePopup
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({ isMessageVisible: false, title: '' })}
                    okTitle={LocStr('Ok', language)}
                    message={messagePopupState.title}
                    language={language}
                /> : null
            }

            {questionPopupState.isQuestionVisible ?
                <MyAskQuestionPopup
                    parentWidth={screenWidth}
                    onYesPressed={(questionId, itemId) => { onQuestionAnswerWasYes(questionId, itemId) }}
                    onNoPressed={(questionId, itemId) => { onQuestionAnswerWasNo(questionId, itemId) }}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.itemId}
                    yesTitle={LocStr('Yes', language)}
                    noTitle={LocStr('No', language)}
                    question={questionPopupState.title}
                    language={language}
                /> : null
            }

        </View>
    );
};

// ============= styles ====================

AdminScreen.navigationOptions = {
    headerShown: false
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#f1f5f9',
        // prevent user-select on texts
        userSelect: 'none',
    },

    loadingTitle: {
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        //alignSelf: 'center',
        //backgroundColor: '#f00',
    },

    topBarTitle: {
        height: 25,
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        backgroundColor: '#fff',
    },
    topBarImageAndVer: {
        marginLeft: 47,
        marginTop: 0,
        width: 113 + 30,
        height: 40,
        position: 'absolute',
    },
    topBarImage: {
        marginLeft: 0,
        marginTop: 0,
        width: 113,
        height: 40,
        resizeMode: 'contain',
        position: 'absolute',
    },
    topBarVersion: {
        width: 30,
        marginLeft: 113 + 3,
        marginTop: 40 - 12,
        height: 11,
        textAlign: 'left',
        position: 'absolute',
        fontFamily: "Quicksand-Medium",
        fontSize: 10,
        color: "#001a2f",
        backgroundColor: '#fff',
    },

    bodyStyle: {
        flex: 1,
        flexDirection: 'column',
        //alignItems:'center',
    },
    lblTitle: {
        fontFamily: "Rubik-Medium",
        fontSize: 22,
        color: "#fff",
        //position: 'absolute',
    },
    btnAddUser: {
        height: 36,
        width: 200,
        marginLeft: 200,
        marginTop: 20,
        borderRadius: 7,
        justifyContent: 'center',
        backgroundColor: '#008cff'
    },
    activityIndicator: {
        position: 'absolute',
        alignSelf: 'flex-end',
        marginRight: 15,
        height: 30,
        marginTop: 0,
    },
    lblEditOrder: {
        width: 120,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#008cff",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblSaveOrder: {
        width: 120,
        fontFamily: "Rubik-Medium",
        fontSize: 14,
        color: "#008cff",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblSubTitle: {
        width: 120,
        fontFamily: "Rubik-Regular",
        fontSize: 12,
        color: "#d9d9d9",
        //position: 'absolute',
    },

});
