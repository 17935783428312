import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, TextInput } from 'react-native';
import { LocStr } from '../utils/Utils';

//=========================================================================

const MyEditText = ( { parentWidth=560, text, placeHolder, onOkPressed, onCancelPressed, okTitle, cancelTitle, message, language } ) => {

    const [state, setState] = useState(text);

    var screenHeight = Dimensions.get('window').height;

    const onEnterPressed = () => {
        onOkPressed(state);
    }

    return (
        <View style={{  width: parentWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
            >
            <View style={{ width:400,height:200, alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                }}>
                <View style={{ width:398,height:198, position:'absolute',flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblTitle16,{marginTop:30,marginLeft:30,marginRight:30}]}>{message}</Text>

                    <View style={{width:398-2-40,height:32,marginLeft:20,marginTop:80,borderRadius:4,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                        <TextInput 
                            style={{width:398-2-40-2,height:30,marginLeft:1,marginTop:1,backgroundColor:'#fff',color: '#001a2f',
                                    borderRadius:3,borderBottomWidth:0,borderTopWidth:0,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                                placeholder={placeHolder}
                                                placeholderTextColor='#a0aec0'
                                                value={state}
                                                multiline={false}
                                                selectTextOnFocus={true}
                                                maxLength={100}  //setting limit of input
                                                onBlur={() => onEnterPressed() }
                                                onChangeText={(newText)=>{
                                                    setState(newText)
                                                }}
                                                onKeyPress={(e)=>{
                                                    if(e.nativeEvent.key == "Enter"){
                                                        onEnterPressed();
                                                    }
                                                }}
                                                />
                    </View>

                    <View style={{marginLeft:90,marginTop:140,width:220,height:36,position:'absolute',flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCancelPressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#fff',borderRadius:6}}>
                                <Text style={[styles.lblTitle16,{marginTop:7,width:96}]}>{cancelTitle}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{width:100,marginLeft:20,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onOkPressed(state)}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#fff',borderRadius:6}}>
                                <Text style={[styles.lblTitle16,{marginTop:7,width:96}]}>{okTitle}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                </View> 
            </View> 
        </View>
    );
};

const styles = StyleSheet.create({
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
});

export default MyEditText;