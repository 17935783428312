import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

//=========================================================================

const MyRadioButton = ( { onPressed, title, value } ) => {
    return (
        <TouchableOpacity style={{height:20,flexDirection:'row',alignItems:'center',justifyContent:'center'}} onPress={() => {onPressed()}} >
            {value?
                <View style={{marginLeft:0,width:20,height:20,borderRadius:10,backgroundColor:'#008cff'}} >
                    <View style={{marginLeft:6,marginTop:6,width:8,height:8,position:'absolute',borderRadius:4,backgroundColor:'#fff'}} />
                </View>:
                <View style={{marginLeft:0,width:20,height:20,borderRadius:10,backgroundColor:'#e2e8f0'}} >
                    <View style={{marginLeft:2,marginTop:2,width:16,height:16,position:'absolute',borderRadius:8,backgroundColor:'#fff'}} />
                </View>
            }
            <Text style={{marginLeft:8,marginTop:3,fontFamily:'Rubik-Regular',fontSize:14,color:'#001a2f'}} >{title}</Text>
        </TouchableOpacity>
    );
};

export default MyRadioButton;