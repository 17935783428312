import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

//=========================================================================

const MyUsersHeader = ( { width, actionsColumnWidth=90, arrTitles, sortByName=true, onHeaderPressed, language } ) => {

    const renderHeader = () => {

        let renderList = arrTitles.map((item, index) => {

            var showIcon = false
            var showGrey = false
            if (item==='Name')
            {
                showIcon=true
                showGrey=!sortByName
            }
            if (item==='Role')
            {
                showIcon=true
                showGrey=sortByName
            }

            if (item==='Status')
            {
                return(
                    <View style={{width:60,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={item} >
                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            textAlign:'center',
                            fontFamily: "Rubik-Medium",
                            fontSize: 14,
                            color: "#001a2f",
                            }} >{item}
                        </Text>
                    </View>
                );
            } else {
                return(
                    <TouchableOpacity style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={item} disabled={!showIcon} onPress={() => {onHeaderPressed(item);}} >
                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            textAlign:'center',
                            fontFamily: "Rubik-Medium",
                            fontSize: 14,
                            color: "#001a2f",
                            }} >{item}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
                    </TouchableOpacity>
                );
            }
        });

        return renderList;
    }

    //-------------------------------------------------------------------------------

    return (
        <View style={{width:width,height:30,backgroundColor:'#fff',flexDirection:'row',borderRadius:5,
        }}>

            {
                renderHeader()
            }

            <View style={{width:actionsColumnWidth,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={'actions'} />

        </View>
    );
};

export default MyUsersHeader;