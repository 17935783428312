import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = process.env.ENV === 'prod' ? {
    apiKey: "AIzaSyAf2E-T88efKd4rXmtkwSfpduehFowCkJo",
    authDomain: "healables-platform-prod.firebaseapp.com",
    projectId: "healables-platform-prod",
    storageBucket: "healables-platform-prod.appspot.com",
    messagingSenderId: "742550612997",
    appId: "1:742550612997:web:d3c4fc5068cbd36eb8406c",
    measurementId: "G-P74EREB180"
} : {
    apiKey: "AIzaSyCN_lVTkfNq4OV5bnfRFmMT5Wy1LFkwtws",
    authDomain: "healables-platform-dev.firebaseapp.com",
    projectId: "healables-platform-dev",
    storageBucket: "healables-platform-dev.appspot.com",
    messagingSenderId: "803448429007",
    appId: "1:803448429007:web:2e859c152dc940bdd61aba"

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


export const signIn = async (email, pass) => {
    const user = await signInWithEmailAndPassword(auth, email, pass);
    const result = (await user.user.getIdTokenResult());
    for (const role of ['superadmin', 'protocols-admin']) {
        if (result.claims.roles.includes(role)) {
            return user.user;
        }
    }

    await logout();
    throw new Error('Unauthorized');
}


export const logout = async () => {
    console.log(signOut);
    await auth.signOut(app);
}