import React, { useState } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import MyRadioButton from '../components/MyRadioButton';
import MyMessagePopup from '../components/MyMessagePopup';
import { LocStr } from '../utils/Utils';
import { numToStrWaveform, numToStrPolarity, validateFreq, validateCurrent, validateDuration, strToArrayWithValidation } from '../utils/Utils';

//=========================================================================

const MyEditTreatmentPopup = ({ parentWidth, onValueChanged, onCanceled, treatmentIndex, treatmentItem, isNew, language }) => {

    var screenWidth = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    var textInput4;
    var textInput5;
    var textInput6;

    const [state, setState] = useState(strToArrayWithValidation(treatmentItem.treatment));

    const [messagePopupState, setMessagePopupState] = useState({ isMessageVisible: false, title: '' });

    //========================================================================================================

    const onUpdatePressed = () => {

        if (numToStrWaveform(state[0], '') === '') {
            const txt = LocStr('Please select a waveform', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        if ((validateFreq(state[1], '', '') === '') || (validateFreq(state[2], '', '') === '')) {
            const txt = LocStr('Please enter valid frequecies (0.1-999999)', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        if (validateCurrent(state[3], '', '') === '') {
            const txt = LocStr('Please enter a valid current (10-600)', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        if (validateDuration(state[4], '', '') === '') {
            const txt = LocStr('Please enter a valid duration (1-60)', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }
        if (numToStrPolarity(state[5], '') === '') {
            const txt = LocStr('Please select polarity', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }

        onValueChanged(treatmentIndex, { treatment: state, key: treatmentItem.key }, isNew);
    }

    //========================================================================================================

    return (
        <TouchableOpacity style={{
            width: screenWidth,
            height: screenHeight - 60 - 73,
            justifyContent: 'center',
            position: 'absolute'
        }}
            activeOpacity={1}
            onPress={() => {
                onCanceled();
            }}
        >
            <TouchableOpacity style={{
                width: 500,/*height:200,*/ alignSelf: 'center', backgroundColor: '#d9d9d9', borderRadius: 8,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.22,
                shadowRadius: 12,
            }}
                activeOpacity={1}
                onPress={() => {
                }}
            >
                <View style={{ /*width:398,height:198, height:198,position:'absolute'*/flexDirection: 'column', margin: 1, backgroundColor: '#fff', borderRadius: 6 }}>

                    <Text style={styles.lblDialogTitle} >{LocStr(isNew ? 'Add Step' : 'Edit Step', language)}</Text>

                    {/* -----   Waveform   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 30, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Waveform:', language)}</Text>

                        <View style={{ width: 330, height: 20, marginLeft: 10, flexDirection: 'row', alignItems: 'center' }} >
                            <View style={{ height: 20, marginLeft: 0 }} >
                                <MyRadioButton style={{ height: 20 }}
                                    title={LocStr('Gentle', language)}
                                    value={numToStrWaveform(state[0], '') === 'Gentle'}
                                    onPressed={(value) => { setState(['0', state[1], state[2], state[3], state[4], state[5]]) }} />
                            </View>

                            <View style={{ height: 20, marginLeft: 20 }} >
                                <MyRadioButton style={{ height: 20 }}
                                    title={LocStr('Mild', language)}
                                    value={numToStrWaveform(state[0], '') === 'Mild'}
                                    onPressed={(value) => { setState(['2', state[1], state[2], state[3], state[4], state[5]]) }} />
                            </View>

                            <View style={{ height: 20, marginLeft: 20 }} >
                                <MyRadioButton style={{ height: 20 }}
                                    title={LocStr('Sharp', language)}
                                    value={numToStrWaveform(state[0], '') === 'Sharp'}
                                    onPressed={(value) => { setState(['3', state[1], state[2], state[3], state[4], state[5]]) }} />
                            </View>
                        </View>

                    </View>

                    <View style={{ alignSelf: 'center', width: 420, marginTop: 12, height: 1, backgroundColor: '#e2e8f0' }} />

                    {/* -----   Freq1   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 12, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Freq1:', language)}</Text>

                        <View style={{ width: 230, height: 27, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9', flexDirection: 'row' }} >

                            <TextInput style={{
                                margin: 1, flex: 1, borderRadius: 2, backgroundColor: '#fff',
                                borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                            }}
                                value={state[1]} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText) => {
                                    if ((!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/)))
                                    //if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/))
                                    {
                                        if (validateFreq(newText)) {
                                            setState([state[0], newText, state[2], state[3], state[4], state[5]]);
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == "Enter") {
                                        textInput4.focus();
                                    }
                                }}
                            />

                        </View>

                        <Text style={styles.lblSubTitle} >{LocStr('(0.1-999999 Hz)', language)}</Text>

                    </View>

                    <View style={{ alignSelf: 'center', width: 420, marginTop: 12, height: 1, backgroundColor: '#e2e8f0' }} />

                    {/* -----   Freq2   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 12, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Freq2:', language)}</Text>

                        <View style={{ width: 230, height: 27, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9' }} >

                            <TextInput style={{
                                margin: 1, borderRadius: 2, backgroundColor: '#fff',
                                borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                            }}
                                value={state[2]} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText) => {
                                    if ((!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/)))
                                    //if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/))
                                    {
                                        if (validateFreq(newText)) {
                                            setState([state[0], state[1], newText, state[3], state[4], state[5]]);
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == "Enter") {
                                        textInput5.focus();
                                    }
                                }}
                                ref={(input) => { textInput4 = input; }}
                            />

                        </View>

                        <Text style={styles.lblSubTitle} >{LocStr('(0.1-999999 Hz)', language)}</Text>

                    </View>

                    <View style={{ alignSelf: 'center', width: 420, marginTop: 12, height: 1, backgroundColor: '#e2e8f0' }} />

                    {/* -----   Current   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 12, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Current:', language)}</Text>

                        <View style={{ width: 230, height: 27, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9' }} >

                            <TextInput style={{
                                margin: 1, borderRadius: 2, backgroundColor: '#fff',
                                borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                            }}
                                value={state[3]} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText) => {
                                    if ((!newText || newText.match(/^\d{1,}?$/)) && (newText.length <= 5))
                                    //if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/))
                                    {
                                        setState([state[0], state[1], state[2], newText, state[4], state[5]]);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == "Enter") {
                                        textInput6.focus();
                                    }
                                }}
                                ref={(input) => { textInput5 = input; }}
                            />

                        </View>

                        <Text style={styles.lblSubTitle} >{LocStr('(0, 10-600 μA)', language)}</Text>

                    </View>

                    <View style={{ alignSelf: 'center', width: 420, marginTop: 12, height: 1, backgroundColor: '#e2e8f0' }} />

                    {/* -----   Duration   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 12, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Duration:', language)}</Text>

                        <View style={{ width: 230, height: 27, marginLeft: 10, borderRadius: 3, backgroundColor: '#a9a9a9' }} >

                            <TextInput style={{
                                margin: 1, borderRadius: 2, backgroundColor: '#fff',
                                borderBottomWidth: 4, borderTopWidth: 4, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                            }}
                                value={state[4]} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText) => {
                                    //if ((!newText || newText.match(/^\d{1,}?.{0,1}\d{1,}?$/)) && (newText.length<=5))
                                    if (!newText || newText.match(/^\d{1,}(\.\d{0,2})?$/)) {
                                        setState([state[0], state[1], state[2], state[3], newText, state[5]]);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == "Enter") {
                                    }
                                }}
                                ref={(input) => { textInput6 = input; }}
                            />

                        </View>

                        <Text style={styles.lblSubTitle} >{LocStr('(1-60 Min)', language)}</Text>

                    </View>

                    <View style={{ alignSelf: 'center', width: 420, marginTop: 12, height: 1, backgroundColor: '#e2e8f0' }} />

                    {/* -----   Polarity   ----- */}

                    <View style={{ alignSelf: 'center', marginTop: 8, flexDirection: 'row' }} >

                        <Text style={styles.lblTitle} >{LocStr('Polarity:', language)}</Text>

                        <View style={{ width: 330, height: 20, marginLeft: 10, flexDirection: 'row', alignItems: 'center' }} >
                            <View style={{ height: 20, marginLeft: 0 }} >
                                <MyRadioButton style={{ height: 20 }}
                                    title={LocStr('Positive', language)}
                                    value={numToStrPolarity(state[5], '') === 'Positive'}
                                    onPressed={(value) => { setState([state[0], state[1], state[2], state[3], state[4], '0']) }} />
                            </View>

                            <View style={{ height: 20, marginLeft: 20 }} >
                                <MyRadioButton style={{ height: 20 }}
                                    title={LocStr('Alternating', language)}
                                    value={numToStrPolarity(state[5], '') === 'Alternating'}
                                    onPressed={(value) => { setState([state[0], state[1], state[2], state[3], state[4], '1']) }} />
                            </View>
                        </View>

                    </View>

                    {/* -----   buttons   ----- */}


                    <View style={{ alignSelf: 'center', marginTop: 40, height: 36, flexDirection: 'row' }} >

                        <TouchableOpacity style={{ width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onCanceled() }}
                        >
                            <View style={{ width: 96, height: 32, position: 'absolute', margin: 2, backgroundColor: '#008cff', borderRadius: 6 }}>
                                <Text style={[styles.lblButton, { marginTop: 7 }]}>{LocStr('Cancel', language)}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ marginLeft: (420 - 100 - 100), width: 100, height: 36, backgroundColor: '#008cff', borderRadius: 8 }}
                            onPress={() => { onUpdatePressed() }}
                        >
                            <View style={{ width: 96, height: 32, position: 'absolute', margin: 2, backgroundColor: '#008cff', borderRadius: 6 }}>
                                <Text style={[styles.lblButton, { marginTop: 7 }]}>{LocStr('Update', language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>

                    <View style={{ width: 1, height: 20 }} />

                </View>
            </TouchableOpacity>

            {messagePopupState.isMessageVisible ?
                <MyMessagePopup
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({ isMessageVisible: false, title: '' })}
                    okTitle={LocStr('Ok', language)}
                    message={messagePopupState.title}
                    language={language}
                /> : null
            }
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width: 80,
        marginTop: 5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width: 100,
        marginTop: 5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width: 500,
        marginTop: 20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditTreatmentPopup;