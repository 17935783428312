import React, { useContext, useState, useEffect, useRef } from 'react';
import { View, ScrollView, StyleSheet, Dimensions, Image, Text, TextInput, ActivityIndicator, TouchableOpacity, FlatList } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyMessagePopup from '../components/MyMessagePopup';
import MyEditProtocolPopup from '../components/MyEditProtocolPopup';
import MyEditTreatmentPopup from '../components/MyEditTreatmentPopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import MyTableHeader from '../components/MyTableHeader';
import MyTableLine from '../components/MyTableLine';
import MyHeaderLine from '../components/MyHeaderLine';
import MySwitch from '../components/MySwitch';
import MyEditText from '../components/MyEditText';
import MyTopMenu from '../components/MyTopMenu';
import { Feather, Fontisto } from '@expo/vector-icons';
import { LocStr, strToArray, getTimeNow, foundInTags } from '../utils/Utils'
import { Context as healablesServerContext } from '../context/healablesServerContext';
import { Context as flaskDataServerContext } from '../context/flaskDataServerContext';
//import { DraggableGrid } from 'react-native-draggable-grid';
import DraggableGrid from '../3rdparty/index';
import { numToStrWaveform, numToStrPolarity, validateFreq, validateCurrent, validateDuration, setAvailableTabs, validateBool, validateArray, validateStr } from '../utils/Utils';
import { expo } from '../../app.json'

import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';

//=========================================================================

const img_search = require('../../assets/images/icon_search.png');
const img_cancel = require('../../assets/images/icon_close_x.png');

const prefKeyShowScrollbars = 'prefKeyShowScrollbars';

//=========================================================================

function cacheImages(images) {
    return images.map(image => {
        if (typeof image === 'string') {
            return Image.prefetch(image);
        } else {
            return Asset.fromModule(image).downloadAsync();
        }
    });
}

function cacheFonts(fonts) {
    return fonts.map(font => Font.loadAsync(font));
}

//----------------------------------------------------------------------

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

let currentlySelectedProtocol = -1;
let calledFirstGetAllQuery = false;
let nowEditingProtocolId = ''

export default function ProtocolsScreen({ navigation }) {

    // ============= Incoming params ====================

    let token = navigation.state.params.token;

    let loggedInUserId = navigation.state.params.loggedInUserId;
    var loggedInUserFullname = navigation.state.params.loggedInUserFullname;
    var loggedInUserFirstname = navigation.state.params.loggedInUserFirstname;
    var loggedInUserLastname = navigation.state.params.loggedInUserLastname;
    let loggedInUserEmail = navigation.state.params.loggedInUserEmail;
    let loggedInUserRoles = navigation.state.params.loggedInUserRoles;

    let language = navigation.state.params.language;

    let serverMode = navigation.state.params.serverMode;

    // ============= variables ====================

    const [state, setState] = useState(
        {
            protocolHeaders: [
            ],
            treatmentData: [
            ],
            oldTreatmentData: [
            ],
        });

    const [selectedProtocol, setSelectedProtocol] = useState(-1);

    const [animating, setAnimating] = useState(false);

    const [showScrollbars, setShowScrollbars] = useState(false);

    const [searchText, setSearchText] = useState({
        text: '',
        typing: false,
        typingTimeout: 0,
    });

    const [messagePopupState, setMessagePopupState] = useState({ isMessageVisible: false, title: '' });

    const [getTextPopupState, setGetTextPopupState] = useState({ isGetTextVisible: false, title: '', userName: loggedInUserFullname });

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false,
        questionId: '',
        userId: '',
        title: '',
    });

    const [editTreatmentState, setEditTreatmentState] = useState({
        isVisible: false,
        isNew: true,
        treatmentIndex: -1,
        treatmentItem: {},
    });

    const [editProtocolState, setEditProtocolState] = useState({
        isVisible: false,
        isNew: true,
        isCopy: false,
        protocolIndex: -1,
        protocolItem: {},
    });

    //=====================================================================

    const { logoutUserAtHealables,
        getAllProtocolsHeaders,
        getProtocolData,
        createProtocol,
        deleteProtocol,
        updateProtocol,
    } = useContext(healablesServerContext);

    const { logoutUser,
    } = useContext(flaskDataServerContext);

    //######################################################################

    const [fontsLoaded, setFontsLoaded] = useState(false);

    const _loadAssetsAsync = async () => {
        const imageAssets = cacheImages([]);

        const fontAssets = cacheFonts([
            Feather.font,
            Fontisto.font,
            { 'Rubik-Regular': require('../../assets/fonts/Rubik-Regular.ttf') },
            { 'Rubik-Medium': require('../../assets/fonts/Rubik-Medium.ttf') },
            { 'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.ttf') },
        ]);

        await Promise.all([...imageAssets, ...fontAssets]);
    }

    //######################################################################

    const [dimensions, setDimensions] = useState({ window, screen });

    var screenWidth = Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;
    var leftPanelWidth = 500;
    var rightPanelWidth = screenWidth - leftPanelWidth;
    var tableWidth = rightPanelWidth - 40;

    useEffect(() => {
        Dimensions.addEventListener("change", onChange);

        // read settings:
        readSettings();

        return () => {
            Dimensions.removeEventListener("change", onChange);

            if (nowEditingProtocolId.length > 0) {
                serverStopEditing(nowEditingProtocolId);
            }
        };
    }, []);

    const onChange = ({ window, screen }) => {
        setDimensions({ window, screen });
    };

    //----------------------------------------------------------------------

    const readSettings = async () => {
        try {
            let item = await AsyncStorage.getItem(prefKeyShowScrollbars);
            setShowScrollbars(((item !== null) && (item === 'true')));
        } catch (err) {
        }
    }

    //----------------------------------------------------------------------

    const onTreatmentChanged = (treatmentIndex, treatmentItem, isNew) => {
        setEditTreatmentState({
            isVisible: false,
            isNew: true,
            treatmentIndex: -1,
            treatmentItem: {},
        });

        let newArr = state.treatmentData.map((item, index) => {
            if ((!isNew) && (index === treatmentIndex)) {
                return { treatment: treatmentItem.treatment.join(), key: treatmentItem.key };
            } else {
                return item;
            }
        });
        if (isNew) {
            newArr.push({ treatment: treatmentItem.treatment.join(), key: treatmentItem.key });
        }

        let newProtocol = prepareTreatmentsForServerUpdate(newArr);
        serverUpdateProtocol(newProtocol.protocolId, newProtocol, false);   // treament changed
    }

    const onTreatmentEditCanceled = () => {
        setEditTreatmentState({
            isVisible: false,
            isNew: true,
            treatmentIndex: -1,
            treatmentItem: {},
        });
        serverStopEditing();
    }

    //----------------------------------------------------------------------

    const onProtocolChanged = (protocolIndex, protocolItem, inSteps, isNew) => {
        setEditProtocolState({
            isVisible: false,
            isNew: true,
            isCopy: false,
            protocolIndex: -1,
            protocolItem: {},
        });

        if (isNew) {
            if (protocolIndex !== -1) {
                serverGetTreatmentsForDuplicate(state.protocolHeaders[protocolIndex].protocolId, protocolItem, inSteps);
                return;
            }
            // NEW protocol - use CSV?
            serverCreateProtocol(protocolItem, inSteps);
        } else {
            // update record on server
            serverUpdateProtocol(protocolItem.protocolId, protocolItem, true);  // protocol changed
        }
    }

    const onProtocolEditCanceled = () => {
        setEditProtocolState({
            isVisible: false,
            isNew: true,
            isCopy: false,
            protocolIndex: -1,
            protocolItem: {},
        });
    }

    //---------------------------------------------------------------------

    const onMenuPressed = (optionName) => {

        if (optionName == 'Protocols') {
            return;
        }

        currentlySelectedProtocol = -1;
        calledFirstGetAllQuery = false;
        nowEditingProtocolId = ''

        navigation.state.params.onSelectTab(optionName)
        navigation.pop();
    }

    //---------------------------------------------------------------------

    const onLogoutPressed = () => {
        const txt = LocStr('Do you want to logout?', language);
        setQuestionPopupState({ isQuestionVisible: true, questionId: 'logout', title: txt });
    }

    //---------------------------------------------------------------------

    const onProtocolHeaderPressed = (rowIndex) => {
        if (rowIndex !== selectedProtocol) {
            if (state.oldTreatmentData.length > 0) {
                onCancelOrderPressed();
            }

            setSelectedProtocol(rowIndex);
        }
    }

    //---------------------------------------------------------------------

    const onQuestionAnswerWasNo = (questionId, userId) => {

        setQuestionPopupState({
            isQuestionVisible: false,
            questionId: '',
            userId: '',
            title: ''
        });
    }

    const onQuestionAnswerWasYes = (questionId, userId) => {

        setQuestionPopupState({
            isQuestionVisible: false,
            questionId: '',
            userId: '',
            title: ''
        });

        switch (questionId) {
            case 'logout':
                serverLogout();
                break;

            case 'delete_protocol':
                serverDeleteProtocol(userId)
                break;

            case 'delete_treatment':
                deleteTreatment(userId);
                break;
        }
    }

    //---------------------------------------------------------------------

    const deleteTreatment = (userId) => {
        let newArr = state.treatmentData.filter((item, index) => {
            return (index !== userId);
        });
        let newProtocol = prepareTreatmentsForServerUpdate(newArr);
        serverUpdateProtocol(newProtocol.protocolId, newProtocol, false);   // treament deleted
    }

    const onAddTreatmentPressed = () => {

        if (!validateUserName()) { return }

        if (!checkIfEditingAllowed(true, 'add'))   // Create treatment
        {
            return;
        }
        serverStartEditing();   // Add treatment pressed

        setEditTreatmentState({
            isVisible: true,
            isNew: true,
            treatmentIndex: -1,
            treatmentItem: { treatment: '3,,,10,,0', key: `idx_${state.treatmentData.length}` },
        });
    }

    const onTreatmentActionPressed = (action, rowIndex) => {

        var txt;

        if (!checkIfEditingAllowed(true, action, rowIndex))   // edit/duplicate/delete treatment
        {
            return;
        }

        if (!validateUserName()) { return }

        switch (action) {
            case 'edit':
                serverStartEditing();   // Edit treatment pressed
                setEditTreatmentState({
                    isVisible: true,
                    isNew: false,
                    treatmentIndex: rowIndex,
                    treatmentItem: state.treatmentData[rowIndex],
                });
                break;

            case 'duplicate':
                serverStartEditing();   // Duplicate treatment pressed
                setEditTreatmentState({
                    isVisible: true,
                    isNew: true,
                    treatmentIndex: -1,
                    treatmentItem: { treatment: state.treatmentData[rowIndex].treatment, key: `idx_${state.treatmentData.length}` },
                });
                break;

            case 'delete':
                txt = LocStr('Are you sure you want to delete this treatment?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'delete_treatment', userId: rowIndex, title: txt });
                break;
        }
    }

    //---------------------------------------------------------------------

    const onRefreshPressed = () => {
        currentlySelectedProtocol = -1;
        setState({ protocolHeaders: [], treatmentData: [], oldTreatmentData: [] });
        setSelectedProtocol(-1);
        serverGetAllProtocols('', searchText.text);  // refresh
    }

    //---------------------------------------------------------------------

    const validateUserName = () => {
        /*
        if (getTextPopupState.userName=='Offline User')
        {
            let txt = LocStr('Please enter your name',language);
            setGetTextPopupState({...getTextPopupState,isGetTextVisible:true,title:txt});
            return false;
        }
        */
        return true;
    }

    //---------------------------------------------------------------------

    const onAddProtocolPressed = () => {
        serverFindVacantProtocolNum('addProtocol');
    }

    const reallyStartAddProtocol = (vacantProtocolNum) => {

        if (!validateUserName()) { return }

        let now = getTimeNow();
        setEditProtocolState({
            isVisible: true,
            isNew: true,
            isCopy: false,
            protocolIndex: -1,
            protocolItem: {
                protocolId: '',
                protocolName: '',
                protocolNum: vacantProtocolNum,
                authorName: getTextPopupState.userName,
                authorId: loggedInUserId,
                field_tags: [],
                field_protected: false,
                lastUpdate: now,
                CreatedAt: now,
                currentEditor: { authorId: loggedInUserId, authorName: getTextPopupState.userName },
            },
        });
    }

    const reallyStartDuplicteProtocol = (vacantProtocolNum, rowIndex) => {

        if (!validateUserName()) { return }

        let theProtocol = state.protocolHeaders[rowIndex];
        let now = getTimeNow();

        setEditProtocolState({
            isVisible: true,
            isNew: true,
            isCopy: true,
            protocolIndex: rowIndex,
            protocolItem: {
                protocolId: '',
                protocolName: calcCopyProtocolName(theProtocol.protocolName),
                protocolNum: vacantProtocolNum,
                authorName: theProtocol.authorName,
                authorId: theProtocol.authorId,
                field_tags: theProtocol.field_tags,
                field_protected: theProtocol.field_protected,
                lastUpdate: now,
                CreatedAt: now,
                currentEditor: { authorId: loggedInUserId, authorName: getTextPopupState.userName },
            },
        });
    }

    const onProtocolActionPressed = (action, rowIndex) => {

        let theProtocol = state.protocolHeaders[rowIndex];
        let now = getTimeNow();
        var txt;

        if (!validateUserName()) { return }

        switch (action) {
            case 'edit':
                setEditProtocolState({
                    isVisible: true,
                    isNew: false,
                    isCopy: false,
                    protocolIndex: rowIndex,
                    protocolItem: {
                        protocolId: theProtocol.protocolId,
                        protocolName: theProtocol.protocolName,
                        protocolNum: theProtocol.protocolNum,
                        authorName: theProtocol.authorName,
                        authorId: theProtocol.authorId,
                        field_tags: theProtocol.field_tags,
                        field_protected: theProtocol.field_protected,
                        lastUpdate: now,
                        CreatedAt: theProtocol.CreatedAt,
                        currentEditor: theProtocol.currentEditor,
                    },
                });
                break;

            case 'duplicate':
                serverFindVacantProtocolNum('duplicate', rowIndex);
                break;

            case 'delete':
                if (theProtocol.authorName.startsWith('*#*')) {
                    updateStateAfterDelete(theProtocol.protocolId);
                    return;
                }

                txt = LocStr('Are you sure you want to delete this protocol?', language);
                setQuestionPopupState({ isQuestionVisible: true, questionId: 'delete_protocol', userId: theProtocol.protocolId, title: txt });
                break;

            case 'download':
                serverGetTreatmentsForExport(theProtocol.protocolId, theProtocol);
                break;
        }
    }

    //-------------------------------------------------------------------------------

    const calcCopyProtocolName = (srcName) => {

        let cmpWith = srcName;
        let idx1 = srcName.indexOf(' (copy ');
        if (idx1 > 0) {
            cmpWith = srcName.substring(0, idx1);
        }

        let arrSimilar = [];
        state.protocolHeaders.map((item) => {
            if (item.protocolName.startsWith(cmpWith)) {
                let idx2 = item.protocolName.indexOf(' (copy ');
                if (idx2 !== -1) {
                    let strNum = item.protocolName.slice(idx2 + 7);
                    arrSimilar.push(strNum.slice(0, strNum.length - 1));
                }
            }
        });

        if (arrSimilar.length == 0) return cmpWith + ' (copy 1)';

        for (let i = 1; i < 999; i++) {
            let test = '' + i;
            if (!arrSimilar.includes(test)) {
                return cmpWith + ' (copy ' + test + ')';
            }
        }

        return cmpWith + ' (copy ' + count + ')';
    }

    //-------------------------------------------------------------------------------

    const validateProtocolNumInternally = (num) => {
        if (num.length == 0) return true;
        if (num === '0') return false;
        let idx = state.protocolHeaders.findIndex((item) => {
            return (item.protocolNum === num);
        })
        return (idx === -1);
    }

    const findVacantProtocolNumInternally = () => {

        for (let res = 10000; res < 99999; res++) {
            let cmpWith = '' + res;
            let idx = state.protocolHeaders.findIndex((item) => {
                return (item.protocolNum === cmpWith);
            })
            if (idx === -1) return cmpWith;
        }
        return '';
    }

    //-------------------------------------------------------------------------------

    const prepareTreatmentsForServerUpdate = (newTreatments) => {

        let treatArray = newTreatments.map((item) => {
            return item.treatment;
        });
        let res = {
            ...state.protocolHeaders[selectedProtocol],
            treatmentData: treatArray,
            editor: { authorId: loggedInUserId, authorName: getTextPopupState.userName }
        }
        return res;
    }

    //-------------------------------------------------------------------------------

    const compareTreatmentArrays = (arr1, arr2) => {

        if ((arr1 == null) || (arr1 == undefined) || (arr2 == null) || (arr2 == undefined)) return false;

        let len1 = arr1.length;
        let len2 = arr2.length;
        if (len1 != len2) return false;

        for (let i = 0; i < len1; i++) {
            if (arr1[i].treatment.localeCompare(arr2[i].treatment) != 0) return false;
        }
        return true;
    }

    //-------------------------------------------------------------------------------

    const checkIfEditingAllowed = (showPopup, calledFor, rowIndex = 0) => {

        if ((selectedProtocol !== -1) &&
            (state.protocolHeaders !== null) &&
            (state.protocolHeaders !== undefined) &&
            (state.protocolHeaders.length > selectedProtocol)) {
            let theProtocol = state.protocolHeaders[selectedProtocol];
            if ((theProtocol.currentEditor === null) ||
                (theProtocol.currentEditor === undefined) ||
                (theProtocol.currentEditor.authorId === null) ||
                (theProtocol.currentEditor.authorId === undefined)) {
                return true;
            } else {
                if (validateStr(theProtocol.currentEditor.authorId).localeCompare(loggedInUserId) === 0) {
                    return true;
                } else {
                    if (showPopup) {
                        if (validateUpdateIsOld(theProtocol.lastUpdate)) {
                            serverStopEditing(theProtocol.protocolId,
                                theProtocol.currentEditor.authorId,
                                theProtocol.currentEditor.authorName,
                                calledFor,
                                rowIndex)
                        } else {
                            let txt = LocStr(`Sorry - this protocol is now edited by\n${theProtocol.currentEditor.authorName},\n\nplease try again later`, language);
                            setMessagePopupState({ isMessageVisible: true, title: txt });
                        }
                    }
                    return false;
                }
            }
        }
        return false;
    }

    //-------------------------------------------------------------------------------

    const onEditOrderPressed = () => {
        if (state.oldTreatmentData.length > 0) {
            // Saving new protocol order:
            if (compareTreatmentArrays(state.treatmentData, state.oldTreatmentData)) {
                setState({ ...state, oldTreatmentData: [] });
                serverStopEditing();
                return;
            }
            let newProtocol = prepareTreatmentsForServerUpdate(state.treatmentData);
            serverUpdateProtocol(newProtocol.protocolId, newProtocol, false);   // treatment order changed
        } else {
            if (!checkIfEditingAllowed(true, 'order'))   // Edit order of treatments
            {
                return;
            }

            if (!validateUserName()) { return }

            // Starting "edit order" mode:
            let oldArray = state.treatmentData;
            setState({ ...state, oldTreatmentData: oldArray });
            serverStartEditing();   // Start edit order pressed
        }
    }

    const onCancelOrderPressed = () => {
        let oldArray = state.oldTreatmentData;
        setState({ ...state, treatmentData: oldArray, oldTreatmentData: [] });
        serverStopEditing();
    }

    const exitOrderEditingMode = () => {
        let oldArray = state.oldTreatmentData;
        setState({ ...state, treatmentData: oldArray, oldTreatmentData: [] });
    }

    //---------------------------------------------------------------------

    const onShowScrollbarsValueChanged = (value) => {
        setShowScrollbars(value)
        AsyncStorage.setItem(prefKeyShowScrollbars, value ? 'true' : 'false');
    }

    //---------------------------------------------------------------------

    const handleSearch = (newText) => {
        currentlySelectedProtocol = -1;
        setSelectedProtocol(-1);
        serverGetAllProtocols('', newText.toLowerCase()); // Search
    }

    //---------------------------------------------------------------------

    const validateUpdateIsOld = (lastUpdate) => {
        let lastDate = Date.parse(lastUpdate);
        let nowDate = new Date();
        let delta = Math.round((nowDate - lastDate) / 1000);
        return (delta > 600);     // 10 minutes
    }

    //-------------------------------------------------------------------------------

    const renderTreatmentItem = (item, order) => {

        return (
            <View style={{ width: tableWidth - 2, height: 42, marginTop: 0 }} key={item.key} >
                <MyTableLine style={{ width: tableWidth - 2, height: 42, marginTop: 0 }}
                    width={tableWidth - 2}
                    arrValues={item.treatment}
                    rowIndex={order}
                    nowDragging={inDragMode}
                    onActionPressed={(action, rowIndex) => { onTreatmentActionPressed(action, rowIndex) }}
                    language={language}
                />
            </View>
        );
    };

    //-------------------------------------------------------------------------------

    const renderProtocolHeader = ({ item, index, separators }) => {

        let width = 460;
        let height = 80;

        return (
            <MyHeaderLine style={{ width: width, height: height }}
                width={width} height={height}
                rowIndex={index}
                isSelected={index === selectedProtocol}
                item={item}
                onLinePressed={(rowIndex) => { onProtocolHeaderPressed(rowIndex) }}
                onActionPressed={(action, rowIndex) => { onProtocolActionPressed(action, rowIndex) }}
                language={language}
            />
        );
    }

    //######################################################################
    //###   D o w n l o a d   J S O N   a n d   I m a g e s
    //######################################################################

    const exportProtocol = (theProtocol) => {

        if ((theProtocol.treatmentData === null) ||
            (theProtocol.treatmentData === undefined) ||
            (theProtocol.treatmentData.length == 0)) {
            txt = LocStr('No treatments to export', language);
            setMessagePopupState({ isMessageVisible: true, title: txt });
            return;
        }

        // Now start downloading:
        var fileDownload = require('js-file-download');

        let txt = `WAVESHAPE,FREQ1,FREQ2,CURRENT,DURATION,POLARITY\n`;
        theProtocol.treatmentData.map((item) => {

            let arrFields = strToArray(item);

            txt = txt + numToStrWaveform(arrFields[0]) + ',';
            txt = txt + validateFreq(arrFields[1], '', '') + ',';
            txt = txt + validateFreq(arrFields[2], '', '') + ',';
            txt = txt + validateCurrent(arrFields[3], '', '') + ',';
            txt = txt + validateDuration(arrFields[4], '', '') + ',';
            txt = txt + numToStrPolarity(arrFields[5]) + '\n';
        })

        var fileName;
        if ((theProtocol.protocolNum !== null) && (theProtocol.protocolNum.length > 0)) {
            fileName = `${theProtocol.protocolNum}-${theProtocol.protocolName}-${theProtocol.authorName}.csv`
        } else {
            fileName = `0-${theProtocol.protocolName}-${theProtocol.authorName}.csv`
        }
        fileName = fileName.replace(/ /g, "_")

        fileDownload(txt, fileName);
    }

    //######################################################################

    const checkIfProtocolWasDeleted = (errMsg, calledFrom) => {

        if ((errMsg.startsWith('protocol not found')) && (errMsg.length > 18)) {
            let protocolId = errMsg.substring(18);
            let res = false;

            switch (calledFrom) {
                case 'delete protocol':
                    updateStateAfterDelete(protocolId);
                    return true;

                case 'start editing':
                case 'stop editing':
                case 'update protocol':
                case 'get treatments':
                    res = true;
                    break;

                default:
                    break;
            }

            if (res) {
                let idx = state.protocolHeaders.findIndex((item) => {
                    return (item.protocolId === protocolId);
                });
                if (idx !== -1) {
                    let name = state.protocolHeaders[idx].authorName;
                    state.protocolHeaders[idx].authorName = '*#*' + name;
                    if (idx === selectedProtocol) {
                        setState({ ...state, protocolHeaders: state.protocolHeaders, treatmentData: [], oldTreatmentData: [] });
                    } else {
                        setState({ ...state, protocolHeaders: state.protocolHeaders, oldTreatmentData: [] });
                    }
                }
            }
            return res;
        }

        return false;
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const serverLogout = async () => {
        try {
            setAnimating(true);

            logoutUserAtHealables(token,
                (result) => {
                    setAnimating(false);

                    currentlySelectedProtocol = -1;
                    calledFirstGetAllQuery = false;

                    navigation.pop();
                    navigation.pop();
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    /*
    const serverLogout = async () => {
        try {
            setAnimating(true);
  
            logoutUser(serverMode, token, 
                (result) => {
                    setAnimating(false);
                    
                    currentlySelectedProtocol = -1;
                    calledFirstGetAllQuery = false;

                    navigation.pop();
                },
                (errMsg) => {
                    setAnimating(false);
                
                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({isMessageVisible:true,title:txt});
                    return;
                }
            );
        } catch (err) {
        }
    }
    */

    //======================================================================

    const updateStateAfterCreate = (newProtocol, createdProtocolId) => {

        let now = getTimeNow();
        newProtocol.lastUpdate = now;
        newProtocol.CreatedAt = now;
        newProtocol.protocolId = createdProtocolId;

        var treatments = [];
        if ((newProtocol.treatmentData !== null) && (newProtocol.treatmentData !== undefined)) {
            newProtocol.treatmentData.map((item, index) => {
                treatments.push({ treatment: item, key: `idx_${index + 1}` });
            });
        }
        newProtocol.treatmentData = treatments;

        let newArray = state.protocolHeaders.filter((item) => {
            return true;
        });
        newArray.push(newProtocol);

        updateStateWithProtocols(newArray, createdProtocolId);
    }

    const updateStateAfterDelete = (deletedProtocolId) => {

        let newArray = state.protocolHeaders.filter((item) => {
            return (item.protocolId !== deletedProtocolId);
        });

        if (selectedProtocol != -1) {
            currentlySelectedProtocol = -1;
            setSelectedProtocol(-1);
        }

        updateStateWithProtocols(newArray, '');
    }

    const updateStateAfterUpdate = (updatedProtocolId, newProtocol) => {

        let now = getTimeNow();
        newProtocol.lastUpdate = now;

        let newArray = [];
        state.protocolHeaders.map((item) => {
            if (item.protocolId === updatedProtocolId) {
                newArray.push(newProtocol);
            } else {
                newArray.push(item);
            }
        });

        updateStateWithProtocols(newArray, updatedProtocolId);
    }

    //-----------------------------------------------------

    const updateStateWithProtocols = (headers, selectProtocolWithId = '') => {

        headers.sort((a, b) => {
            var res = a.protocolName.localeCompare(b.protocolName);
            if (res === 0) {
                res = b.lastUpdate.localeCompare(a.lastUpdate);
            }
            return res;
        });

        setState({ ...state, protocolHeaders: headers, oldTreatmentData: [] });

        if (selectProtocolWithId.length > 0) {
            let index = headers.findIndex((item) => {
                return (selectProtocolWithId === item.protocolId);
            })
            if (index !== -1) {
                currentlySelectedProtocol = -1;
                setSelectedProtocol(index);
            }
        }
    }

    //------------------------------------------------------

    const serverGetAllProtocols = async (selectProtocolWithId = '', filterText = '') => {
        try {

            setAnimating(true);

            getAllProtocolsHeaders(token,
                (result) => {
                    setAnimating(false);

                    // translate from response format to our format:
                    var headers = [];
                    result.map((item, index) => {
                        // extract 'details' field:
                        let newItem = {
                            protocolId: validateStr(item._id),
                            protocolName: validateStr(item.protocolName),
                            protocolNum: validateStr(item.protocolNum),
                            authorName: validateStr(item.authorName),
                            authorId: validateStr(item.authorId),
                            field_tags: validateArray(item.field_tags),
                            field_protected: validateBool(item.field_protected),
                            lastUpdate: validateStr(item.lastUpdate),
                            CreatedAt: validateStr(item.CreatedAt),
                            currentEditor: item.currentEditor,
                        };
                        if ((filterText.length === 0) ||
                            (newItem.protocolName.toLowerCase().includes(filterText)) ||
                            (newItem.protocolNum.toLowerCase().includes(filterText)) ||
                            (newItem.authorName.toLowerCase().includes(filterText)) ||
                            (foundInTags(newItem.field_tags, filterText))) {
                            headers.push(newItem);
                        }
                    });

                    updateStateWithProtocols(headers, selectProtocolWithId);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const handleResultOfVacantNum = (vacantNum, calledFor, rowIndex) => {

        let res = vacantNum;
        if (vacantNum.length == 0) {
            res = findVacantProtocolNumInternally();
            for (let res = 10000; res < 99999; res++) {
                let cmpWith = '' + res;
                let idx = state.protocolHeaders.findIndex((item) => {
                    return (item.protocolNum === cmpWith);
                })
                if (idx === -1) {
                    res = cmpWith;
                }
            }
        }
        switch (calledFor) {
            case 'addProtocol':
                reallyStartAddProtocol(res);
                break;
            case 'duplicate':
                reallyStartDuplicteProtocol(res, rowIndex)
                break;
        }
    }

    const serverFindVacantProtocolNum = async (calledFor, rowIndex = 0) => {
        try {
            getAllProtocolsHeaders(token,
                (result) => {

                    for (let res = 10000; res < 99999; res++) {
                        let cmpWith = '' + res;
                        let idx = result.findIndex((item) => {
                            return (item.protocolNum === cmpWith);
                        })
                        if (idx == -1) {
                            handleResultOfVacantNum(cmpWith, calledFor, rowIndex);
                            return;
                        }
                    }
                    handleResultOfVacantNum('', calledFor, rowIndex);
                },
                (errMsg) => {
                    handleResultOfVacantNum('', calledFor, rowIndex);
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const serverGetTreatments = async (protocolId) => {
        try {

            setAnimating(true);

            getProtocolData(token, protocolId,
                (result) => {
                    setAnimating(false);

                    var treatments = [];
                    result.treatmentData.map((item, index) => {
                        treatments.push({ treatment: item, key: `idx_${index + 1}` });
                    });

                    setState({ ...state, treatmentData: treatments });
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    if (checkIfProtocolWasDeleted(errMsg, 'get treatments')) {
                        return;
                    }

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const serverGetTreatmentsForDuplicate = async (protocolId, protocolItem, inSteps) => {
        try {

            setAnimating(true);

            getProtocolData(token, protocolId,
                (result) => {

                    let newArrSteps = result.treatmentData;
                    if ((inSteps !== null) && (inSteps.length > 0)) {
                        newArrSteps.push(inSteps);
                    }

                    serverCreateProtocol(protocolItem, newArrSteps);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    if (checkIfProtocolWasDeleted(errMsg, 'get treatments')) {
                        return;
                    }

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const serverGetTreatmentsForExport = async (protocolId, protocolItem) => {
        try {

            setAnimating(true);

            getProtocolData(token, protocolId,
                (result) => {
                    setAnimating(false);

                    let theProtocol = {
                        ...protocolItem,
                        treatmentData: result.treatmentData,
                    }
                    exportProtocol(theProtocol);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);

                    if (checkIfProtocolWasDeleted(errMsg, 'get treatments')) {
                        return;
                    }

                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const serverStartEditing = async (protocolId = state.protocolHeaders[selectedProtocol].protocolId) => {
        try {
            let newProtocol = {
                startEdit: true,
                editor: {
                    authorId: validateStr(loggedInUserId),
                    authorName: validateStr(getTextPopupState.userName)
                },
            };

            updateProtocol(token, protocolId, newProtocol,
                (result) => {
                    nowEditingProtocolId = protocolId;
                },
                (errMsg) => {
                    console.log(errMsg);
                    if (checkIfProtocolWasDeleted(errMsg, 'start editing')) {
                        return;
                    }
                    return;
                }
            );
        } catch (err) {
        }
    };

    //======================================================================

    const serverStopEditing = async (protocolId = state.protocolHeaders[selectedProtocol].protocolId,
        authorId = loggedInUserId,
        authorName = getTextPopupState.userName,
        calledFor = null,
        rowIndex = 0) => {
        try {
            let newProtocol = {
                stopEdit: true,
                editor: {
                    authorId: validateStr(authorId),
                    authorName: validateStr(authorName)
                },
            };

            updateProtocol(token, protocolId, newProtocol,
                (result) => {
                    nowEditingProtocolId = '';

                    if (calledFor !== null) {
                        let tmpProtocol = state.protocolHeaders[selectedProtocol];
                        tmpProtocol.currentEditor = null;
                        updateStateAfterUpdate(protocolId, tmpProtocol);

                        switch (calledFor) {
                            case 'add':
                                onAddTreatmentPressed();
                                break;
                            case 'edit':
                            case 'duplicate':
                            case 'delete':
                                onTreatmentActionPressed(calledFor, rowIndex);
                                break;
                            case 'order':
                                onEditOrderPressed();
                                break;
                            default:
                                break;
                        }
                    }
                },
                (errMsg) => {
                    console.log(errMsg);
                    if (checkIfProtocolWasDeleted(errMsg, 'stop editing')) {
                        return;
                    }
                    return;
                }
            );
        } catch (err) {
        }
    };

    //======================================================================

    const serverUpdateProtocol = async (protocolId, newProtocol, needToReadAllProtocols) => {
        try {

            setAnimating(true);

            updateProtocol(token, protocolId, newProtocol,
                (result) => {
                    if (!needToReadAllProtocols) {
                        setAnimating(false);
                        var treatments = [];
                        newProtocol.treatmentData.map((item, index) => {
                            treatments.push({ treatment: item, key: `idx_${index + 1}` });
                        });
                        setState({ ...state, treatmentData: treatments, oldTreatmentData: [] });
                    }
                    updateStateAfterUpdate(protocolId, newProtocol);

                    if (selectedProtocol !== -1) {
                        serverStopEditing();
                    }
                },
                (errMsg) => {
                    setAnimating(false);
                    if (selectedProtocol !== -1) {
                        serverStopEditing();
                    }

                    console.log(errMsg);
                    if (checkIfProtocolWasDeleted(errMsg, 'update protocol')) {
                        return;
                    }
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //======================================================================

    const serverCreateProtocol = async (protocolInfo, arrSteps) => {
        try {

            setAnimating(true);

            let theProtocol = protocolInfo;

            if ((arrSteps !== null) && (arrSteps.length > 0)) {
                theProtocol = {
                    ...protocolInfo,
                    treatmentData: arrSteps,
                }
            }

            createProtocol(token, theProtocol,
                (result) => {
                    updateStateAfterCreate(theProtocol, result);
                    setAnimating(false);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //======================================================================

    const serverDeleteProtocol = async (protocolId) => {
        try {

            setAnimating(true);

            deleteProtocol(token, protocolId,
                (result) => {
                    updateStateAfterDelete(protocolId);
                    setAnimating(false);
                },
                (errMsg) => {
                    setAnimating(false);

                    console.log(errMsg);
                    if (checkIfProtocolWasDeleted(errMsg, 'delete protocol')) {
                        return;
                    }
                    const txt = errMsg;
                    setMessagePopupState({ isMessageVisible: true, title: txt });
                    return;
                }
            );
        } catch (err) {
        }
    };

    //######################################################################

    if (currentlySelectedProtocol !== selectedProtocol) {
        currentlySelectedProtocol = selectedProtocol;
        serverGetTreatments(state.protocolHeaders[currentlySelectedProtocol].protocolId);
    }

    let inDragMode = (state.oldTreatmentData.length > 0);

    // ============= render ====================

    if (screenWidth < 900) {
        return (
            <View style={styles.container}>
                <View style={{ height: screenHeight, width: screenWidth, justifyContent: 'center' }} >
                    <Text style={styles.loadingTitle} >{LocStr('Sorry - window size too small to display app...\n\nAre you trying to use the app on a phone/tablet?\n\nTry rotating to landscape position.', language)}</Text>
                </View>
            </View>
        );
    }

    if (!calledFirstGetAllQuery) {
        calledFirstGetAllQuery = true;
        serverGetAllProtocols('');      // first call ever
    }

    if (!fontsLoaded) {
        return (
            <AppLoading
                startAsync={_loadAssetsAsync()}
                onFinish={() => { setFontsLoaded(true); }}
                onError={console.warn}
            />
        );
    }

    return (
        <View style={styles.container}>

            <View style={styles.bodyStyle} >

                {/*---------------   P r o t o c o l   H e a d e r s   T a b l e   ----------------*/}

                <View style={{ width: 1, height: 1, marginTop: 59 + 40 }} />

                <View style={{
                    width: leftPanelWidth,
                    height: screenHeight - 60 - 40,
                    marginTop: 60 + 40,
                    position: 'absolute',
                    flexDirection: 'column',
                    backgroundColor: '#2d323e',
                }}
                //vertical={true} 
                //pagingEnabled={false} 
                //showsVerticalScrollIndicator={true/*IsSafari()*/}
                //scrollEnabled={true}
                >

                    <Text style={[styles.lblTitle, { width: 190, height: 22, marginLeft: 20, marginTop: 20 }]} >{LocStr('Protocols:', language)}</Text>

                    <TouchableOpacity style={{ marginLeft: 460 - 10, marginTop: 16, width: 30, height: 30, position: 'absolute' }}
                        onPress={() => { onAddProtocolPressed(); }} >
                        <Feather name="plus-circle" style={{ fontSize: 30, position: 'absolute', color: '#d9d9d9' }} />
                    </TouchableOpacity>

                    <TouchableOpacity style={{ marginLeft: 460 - 50, marginTop: 16, width: 30, height: 30, position: 'absolute' }}
                        onPress={() => { onRefreshPressed(); }} >
                        <Feather name="refresh-ccw" style={{ fontSize: 30, position: 'absolute', color: '#d9d9d9' }} />
                    </TouchableOpacity>

                    <View style={{ width: 460, height: 27, marginLeft: 20, marginTop: 20, flexDirection: 'row' }} >
                        <View style={{ width: 268, height: 25, borderRadius: 5, backgroundColor: '#fff' }} >
                            <TextInput style={{
                                marginLeft: 0, paddingLeft: 5, borderRadius: 5, height: 25, backgroundColor: '#fff',
                                fontFamily: "Rubik-Medium", fontSize: 12, color: '#5f5f5f',
                                borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 4, borderLeftWidth: 4, borderColor: '#fff'
                            }}
                                value={searchText.text} multiline={false} selectTextOnFocus={true} maxLength={30}
                                placeholder={LocStr('Search', language)} placeholderTextColor='#a9a9a9'
                                onChangeText={(newText) => {

                                    // clear triggerL
                                    if (searchText.typingTimeout) {
                                        clearTimeout(searchText.typingTimeout);
                                    }

                                    if (newText.length <= 30) {
                                        setSearchText({
                                            text: newText.toLowerCase(),
                                            typing: false,
                                            typingTimeout: setTimeout(function () {
                                                handleSearch(newText);
                                            }, 1500)
                                        });
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == "Enter") {
                                        if (searchText.typingTimeout) {
                                            clearTimeout(searchText.typingTimeout);
                                        }
                                        handleSearch(searchText.text);
                                    }
                                }}
                            //ref={(input) => { textInput4 = input; }}
                            />

                            <TouchableOpacity style={{ marginLeft: 268 - 25, marginTop: 0, width: 25, height: 25, position: 'absolute' }}
                                onPress={() => {
                                    if (searchText.typingTimeout) {
                                        clearTimeout(searchText.typingTimeout);
                                    }
                                    setSearchText({ ...searchText, text: '' });
                                    handleSearch('');
                                }}
                                disabled={(searchText.text.length === 0)}
                            >
                                <Image fadeDuration={0}
                                    style={{ width: 19, height: 19, marginLeft: 3, marginTop: 4, position: 'absolute' }}
                                    source={(searchText.text.length > 0) ? img_cancel : img_search} />
                            </TouchableOpacity>
                        </View>

                        <Text style={[styles.lblSubTitle, { width: 460 - 34, height: 15, marginTop: 7, paddingRight: 7, textAlign: 'right' }]} >{LocStr('Show scrollbars', language)}</Text>

                        <MySwitch style={{ width: 34, height: 17, marginTop: 5, position: 'absolute' }}
                            marginTop={5}
                            value={showScrollbars}
                            onValueChanged={(value) => { onShowScrollbarsValueChanged(value) }} />

                    </View>

                    {(state.protocolHeaders.length > 0) ?
                        <View style={{ width: 460 + 15, marginLeft: 20, marginTop: 20 }} >

                            <FlatList style={{ width: 460 + 15, height: screenHeight - 60 - 40 - 20 - 22 - 20 - 17 - 20 - 30 }}
                                data={state.protocolHeaders}
                                renderItem={renderProtocolHeader}
                                keyExtractor={(item) => { return item.protocolId; }}
                                showsVerticalScrollIndicator={showScrollbars}
                            />

                            <View style={{ width: 460, height: 1, marginLeft: 0, marginTop: 0, position: 'absolute', backgroundColor: '#6a707e' }} />

                        </View> :
                        <Text style={[styles.lblTitle, { textAlign: 'center', color: "#d9d9d9", width: 460, height: 22, marginLeft: 20, marginTop: 40 }]} >{LocStr((searchText.text.length > 0) ? 'No results' : '', language)}</Text>
                    }

                </View>

                {/*---------------   T r e a t m e n t s   T a b l e   ----------------*/}

                <ScrollView style={{
                    width: rightPanelWidth,
                    height: screenHeight - 60 - 40,
                    marginLeft: leftPanelWidth,
                    marginTop: 60 + 40,
                    position: 'absolute',
                    flexDirection: 'column',
                    //backgroundColor:'#001a2f',
                }}
                    vertical={true}
                    pagingEnabled={false}
                    showsVerticalScrollIndicator={showScrollbars}
                    scrollEnabled={true}
                >

                    {(selectedProtocol == -1) ? null :
                        <Text style={[styles.lblTitle, { marginLeft: 20, marginTop: 20, position: 'absolute', color: '#6a707e' }]} >{state.protocolHeaders[selectedProtocol].protocolName}</Text>}

                    {(selectedProtocol == -1) ? null :
                        <TouchableOpacity style={{ marginLeft: rightPanelWidth - 50, marginTop: 16, width: 30, height: 30, position: 'absolute' }}
                            onPress={() => { onAddTreatmentPressed(); }}
                            disabled={inDragMode}
                        >
                            <Feather name="plus-circle" style={{ fontSize: 30, position: 'absolute', color: inDragMode ? '#d9d9d9' : '#6a707e' }} />
                        </TouchableOpacity>}

                    {((selectedProtocol == -1) || (state.treatmentData.length == 0)) ?

                        <View style={{ width: 200, height: 240, marginLeft: (rightPanelWidth - 200) / 2, marginTop: (screenHeight - 60 - 240) / 2, alignItems: 'center' }} >
                            {fontsLoaded ? <Fontisto name="electronjs" style={{ fontSize: 200, color: '#d9d9d9', marginLeft: 2, marginTop: 1 }} /> : null}
                            {fontsLoaded ? <Text style={[styles.lblTitle, { marginTop: 10, color: '#d9d9d9' }]}
                            >{LocStr((selectedProtocol === -1) ? 'No protocol selected' : 'No treatments', language)}</Text> : null}
                        </View> :

                        <View style={{ width: tableWidth, marginTop: 30 }} >

                            <View style={{ width: tableWidth, marginLeft: 20, marginTop: 20, flexDirection: 'column'/*,borderColor:'#6a707e',borderWidth:1*/ }} >

                                <View style={{ marginLeft: 0, marginTop: 15, width: tableWidth }} >
                                    <Text style={[styles.lblTitle, { fontSize: 16, color: '#6a707e' }]} >{LocStr('Protocol Steps:', language)}</Text>

                                    {(state.treatmentData.length < 2) ? null :
                                        <TouchableOpacity style={{ width: 120, marginLeft: tableWidth - 120, marginTop: 2, position: 'absolute' }} onPress={() => { onEditOrderPressed(); }} >
                                            <Text style={[inDragMode ? styles.lblSaveOrder : styles.lblEditOrder, { marginTop: 0, marginLeft: 0 }]} >{LocStr(inDragMode ? 'Update order' : 'Change order', language)}</Text>
                                        </TouchableOpacity>}

                                    {(inDragMode && (state.treatmentData.length >= 2)) ?
                                        <TouchableOpacity style={{ width: 100, marginLeft: tableWidth - 240, marginTop: 2, position: 'absolute' }} onPress={() => { onCancelOrderPressed(); }} >
                                            <Text style={[styles.lblEditOrder, { marginTop: 0, marginLeft: 0 }]} >{LocStr('Cancel', language)}</Text>
                                        </TouchableOpacity> : null}
                                </View>

                                <View style={{ width: tableWidth - 2, marginTop: 15 }} >

                                    <MyTableHeader style={{ width: tableWidth - 2, height: 32 }}
                                        width={tableWidth - 2}
                                        arrTitles={['Waveform', 'Freq1', 'Freq2', 'Current', 'Duration', 'Polarity']}
                                        language={language}
                                    />

                                    <View style={{ width: tableWidth - 2, marginTop: 0, height: (state.treatmentData.length * 42) }}>
                                        <DraggableGrid style={{ width: tableWidth - 2 }}
                                            numColumns={1}
                                            renderItem={renderTreatmentItem}
                                            itemHeight={42}
                                            data={state.treatmentData}
                                            onDragRelease={(data) => {
                                                setState({ ...state, treatmentData: data });
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    }

                </ScrollView>

                {editTreatmentState.isVisible ?
                    <MyEditTreatmentPopup
                        parentWidth={screenWidth}
                        onValueChanged={(treatmentIndex, treatmentItem, isNew) => { onTreatmentChanged(treatmentIndex, treatmentItem, isNew) }}
                        onCanceled={() => onTreatmentEditCanceled()}
                        treatmentIndex={editTreatmentState.treatmentIndex}
                        treatmentItem={editTreatmentState.treatmentItem}
                        isNew={editTreatmentState.isNew}
                        language={language}
                    /> : null
                }

                {editProtocolState.isVisible ?
                    <MyEditProtocolPopup
                        parentWidth={screenWidth}
                        validateProtocolNumInternally={(protNum) => { return validateProtocolNumInternally(protNum); }}
                        findVacantProtocolNumInternally={() => { return findVacantProtocolNumInternally(); }}
                        onValueChanged={(protocolIndex, protocolItem, inSteps, isNew) => { onProtocolChanged(protocolIndex, protocolItem, inSteps, isNew) }}
                        onCanceled={() => onProtocolEditCanceled()}
                        protocolIndex={editProtocolState.protocolIndex}
                        protocolItem={editProtocolState.protocolItem}
                        isNew={editProtocolState.isNew}
                        isCopy={editProtocolState.isCopy}
                        token={token}
                        language={language}
                    /> : null
                }

            </View>

            {/* ================================   T o p   b a r   ================================ */}

            <View style={{
                height: 60,
                width: screenWidth,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.12,
                shadowRadius: 12,
                justifyContent: 'center',
                //alignItems: 'center',
                backgroundColor: '#fff',
                position: 'absolute',
            }}
            >
                <Text style={styles.topBarTitle} >Healables Protocols Manager {process.env.ENV} - Version {require('../../package.json').version}</Text>
                <View style={styles.topBarImageAndVer} >
                    <Image fadeDuration={0} style={styles.topBarImage} source={require('../../assets/images/healables.png')} />
                    <Text style={styles.topBarVersion} >{expo.version}</Text>
                </View>

                {animating ?
                    <ActivityIndicator
                        animating={animating}
                        color='#001a2f'
                        size='large'
                        style={styles.activityIndicator}
                    /> :
                    <TouchableOpacity style={{ width: 200, height: 20, marginLeft: screenWidth - 20 - 200, position: 'absolute' }} activeOpacity={1} onPress={() => { onLogoutPressed() }} >
                        <Text style={{ width: 176, height: 20, marginTop: 2, textAlign: 'right', fontFamily: "Rubik-Regular", fontSize: 12, color: "#7f7f7f" }} >{getTextPopupState.userName}</Text>
                        <Feather name="log-out" style={{ width: 20, height: 20, marginLeft: 180, position: 'absolute', fontSize: 18, color: '#3f3f3f' }} />
                    </TouchableOpacity>
                }

            </View>

            {/* ================================   M e n u   b a r   ================================ */}

            <MyTopMenu style={{ width: screenWidth, height: 40, marginTop: 60 }}
                width={screenWidth}
                selectedTab={'Protocols'}
                arrTabs={setAvailableTabs(loggedInUserRoles)}
                onTabSelected={(selectedTab) => { onMenuPressed(selectedTab) }}
                language={language}
            />

            {/* ================================   P o p u p s   ================================ */}

            {messagePopupState.isMessageVisible ?
                <MyMessagePopup
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({ isMessageVisible: false, title: '' })}
                    okTitle={LocStr('Ok', language)}
                    message={messagePopupState.title}
                    language={language}
                /> : null
            }

            {getTextPopupState.isGetTextVisible ?
                <MyEditText
                    parentWidth={screenWidth}
                    text={getTextPopupState.userName}
                    placeHolder={LocStr('Enter your name', language)}
                    onOkPressed={(newtext) => setGetTextPopupState({ isGetTextVisible: false, title: '', userName: newtext })}
                    onCancelPressed={() => setGetTextPopupState({ ...getTextPopupState, isGetTextVisible: false, title: '' })}
                    okTitle={LocStr('Ok', language)}
                    cancelTitle={LocStr('Cancel', language)}
                    message={getTextPopupState.title}
                    language={language}
                /> : null
            }


            {questionPopupState.isQuestionVisible ?
                <MyAskQuestionPopup
                    parentWidth={screenWidth}
                    onYesPressed={(questionId, userId) => { onQuestionAnswerWasYes(questionId, userId) }}
                    onNoPressed={(questionId, userId) => { onQuestionAnswerWasNo(questionId, userId) }}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.userId}
                    yesTitle={LocStr('Yes', language)}
                    noTitle={LocStr('No', language)}
                    question={questionPopupState.title}
                    language={language}
                /> : null
            }

        </View>
    );
};

// ============= styles ====================

ProtocolsScreen.navigationOptions = {
    headerShown: false
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#f1f5f9',
        // prevent user-select on texts
        userSelect: 'none',
    },

    loadingTitle: {
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        //alignSelf: 'center',
        //backgroundColor: '#f00',
    },

    topBarTitle: {
        height: 25,
        textAlign: 'center',
        fontFamily: "Quicksand-Medium",
        fontSize: 20,
        color: "#001a2f",
        backgroundColor: '#fff',
    },
    topBarImageAndVer: {
        marginLeft: 47,
        marginTop: 0,
        width: 113 + 30,
        height: 40,
        position: 'absolute',
    },
    topBarImage: {
        marginLeft: 0,
        marginTop: 0,
        width: 113,
        height: 40,
        resizeMode: 'contain',
        position: 'absolute',
    },
    topBarVersion: {
        width: 30,
        marginLeft: 113 + 3,
        marginTop: 40 - 12,
        height: 11,
        textAlign: 'left',
        position: 'absolute',
        fontFamily: "Quicksand-Medium",
        fontSize: 10,
        color: "#001a2f",
        backgroundColor: '#fff',
    },

    bodyStyle: {
        flex: 1,
        flexDirection: 'column',
        //alignItems:'center',
    },
    lblTitle: {
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#fff",
        //position: 'absolute',
    },
    btnAddUser: {
        height: 36,
        width: 200,
        marginLeft: 200,
        marginTop: 20,
        borderRadius: 7,
        justifyContent: 'center',
        backgroundColor: '#008cff'
    },
    activityIndicator: {
        position: 'absolute',
        alignSelf: 'flex-end',
        marginRight: 15,
        height: 30,
        marginTop: 0,
    },
    lblEditOrder: {
        width: 120,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#008cff",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblSaveOrder: {
        width: 120,
        fontFamily: "Rubik-Medium",
        fontSize: 14,
        color: "#008cff",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblSubTitle: {
        width: 120,
        fontFamily: "Rubik-Regular",
        fontSize: 12,
        color: "#d9d9d9",
        //position: 'absolute',
    },

});
