import React, { useState } from 'react';
import { View, TouchableOpacity, Text, TextInput } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, isRoleChecked } from '../utils/Utils';

//=========================================================================

const MyMetadataLine = ({
    width,
    rowIndex,
    metadata,
    onSubmit,
    canDelete,
    handleDelete,
    language
}) => {

    //-----------------------------------------------------
    
    const [state, setState] = useState(metadata)

    let textInput1;
    let textInput2;
    let textInput3;

    //-----------------------------------------------------
    
    return (
        <View style={{
            width: width,
            height: 34,
            marginTop: 0,
            marginLeft: 0,
            backgroundColor:'#fff'
        }} >

            <View style={{
                width: width,
                height: 32,
                marginTop: 0,
                position: 'absolute',
                flexDirection: 'row'
            }}>

                <MyTextInput
                    field={'key'}
                    state={state.key}
                    valChanged={(newVal) => {setState( { ...state, key: newVal}) }}
                    gotRef={(input) => {textInput1 = input;}}
                    enterPressed={() => { textInput2.focus() }}
                    focusChanged={(nowEditing) => { if (!nowEditing) { onSubmit(state,rowIndex) }}}
                    />

                <MyTextInput
                    field={'value'}
                    state={state.value}
                    valChanged={(newVal) => {setState( { ...state, value: newVal}) }}
                    gotRef={(input) => {textInput2 = input;}}
                    enterPressed={() => { textInput3.focus() }}
                    focusChanged={(nowEditing) => { if (!nowEditing) { onSubmit(state,rowIndex) }}}
                />

                <MyTextInput
                    field={'description'}
                    state={state.description}
                    valChanged={(newVal) => {setState( { ...state, description: newVal}) }}
                    gotRef={(input) => {textInput3 = input;}}
                    enterPressed={() => { /*NOP*/ }}
                    focusChanged={(nowEditing) => { if (!nowEditing) { onSubmit(state,rowIndex) }}}
                />

                <View
                    style={{
                        marginLeft: 4,
                        height: 32,
                        flexDirection: 'row',
                        backgroundColor: '#fff'
                    }}
                    key={'actions'}
                >
                    {/* -----   actions icons   ----- */}

                    {canDelete?
                    <TouchableOpacity
                        style={{ width: 30, height: 30 }}
                        onPress={() => { handleDelete(rowIndex); }}
                        >
                        <Feather
                            name="trash-2"
                            style={{
                                fontSize: 16,
                                position: 'absolute',
                                color: '#6a707e',
                                marginLeft: 8,
                                marginTop: 8,
                                marginRight: 5
                            }} />
                    </TouchableOpacity>:<View style={{ width: 30, height: 30 }} />}

                </View>
            </View>

        </View>
    );
};

const MyTextInput = ({ field, state, valChanged, gotRef, enterPressed, focusChanged }) => {

    let textInput;

    return (
        <View style={{
            flex: 1,
            height: 32,
            marginLeft: 4,
            marginRight: 4,
            justifyContent: 'center',
            backgroundColor: '#fff',
            borderRadius: 2,
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderColor: '#a9a9a9'
        }} >
            <TextInput
                style={{
                    margin: 1,
                    flex: 1,
                    borderRadius: 2,
                    backgroundColor: '#fff',
                    borderBottomWidth: 4,
                    borderTopWidth: 4,
                    borderRightWidth: 4,
                    borderLeftWidth: 4,
                    borderColor: '#fff'
                }}
                value={state || ''}
                multiline={false}
                selectTextOnFocus={true}
                maxLength={100}

                onBlur= {() => { focusChanged(false) }}
                //onFocus={() => { focusChanged(true) }}

                onChangeText={(newText) => {
                    if (newText.length <= 100) {
                        valChanged(newText)
                    }
                }}
                onKeyPress={(e) => {
                   if (e.nativeEvent.key == "Enter") {
                        enterPressed()
                   }
                }}
                ref={(input) => { gotRef(input); }}
            />
        </View>
    )
}



export default MyMetadataLine;