import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import { LocStr } from '../utils/Utils';

//=========================================================================

const MyAskQuestionPopup = ( { parentWidth=560, isYesNo=true, onYesPressed, onNoPressed, questionId='', auxString='', yesTitle, noTitle, question, language } ) => {

    var screenHeight = Dimensions.get('window').height;

    let noBtnWidth    = isYesNo?184:126;
    let yesBtnWidth   = isYesNo?184:242;
    let conterOffsetY = isYesNo?0:54;

    return (
        <View style={{  width: parentWidth,
                        height: screenHeight-60-73-conterOffsetY,
                        justifyContent:'center',
                        position:'absolute'}} 
            >
            <View style={{ width:464,height:242, alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                }}>
                <View style={{ width:464-2,height:242-2, position:'absolute',flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblTitle16,{marginTop:32,marginLeft:31,width:400,height:105,marginRight:31,textAlign:'center'}]}>{question}</Text>

                    <View style={{marginLeft:31,marginTop:32+105+32,width:400,height:50,position:'absolute',flexDirection:'row'}} >

                        <TouchableOpacity style={{width:noBtnWidth,height:50,backgroundColor:'#cbd5e0',borderRadius:5}} 
                            onPress={() => {onNoPressed(questionId,auxString)}}
                            >
                            <View style={{ width:noBtnWidth-2,height:48, position:'absolute',margin:1,backgroundColor: '#fff',borderRadius:4}}>
                                <Text style={[styles.lblButton,{marginTop:14,width:noBtnWidth-4}]}>{noTitle}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{marginLeft:32,width:yesBtnWidth,height:50,backgroundColor:'#008cff',borderRadius:5}} 
                            onPress={() => {onYesPressed(questionId,auxString)}}
                            >
                            <View style={{ width:yesBtnWidth-2,height:48, position:'absolute',margin:1,backgroundColor: '#008cff',borderRadius:4}}>
                                <Text style={[styles.lblButton,{marginTop:14,width:yesBtnWidth-4,color:'#fff'}]}>{yesTitle}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                </View> 
            </View> 
        </View>
    );
};

const styles = StyleSheet.create({
    lblTitle16: {
        //width: 398,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
    lblButton: {
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        textAlign: 'center',
        color: "#008cff",
        position: 'absolute',
    },
});

export default MyAskQuestionPopup;