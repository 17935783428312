import React, { useContext, useState, useEffect, useRef } from 'react';
import { View, ScrollView, StyleSheet, Dimensions, Image, Text, TextInput, ActivityIndicator, TouchableOpacity, FlatList } from 'react-native';
import { LocStr, strToArray, getTimeNow } from '../utils/Utils'

const img_search = require('../../assets/images/icon_search.png');
const img_cancel = require('../../assets/images/icon_close_x.png');


const MyListSearch = ({width=498, offY=80, handleSearch, language}) => {
    const [searchText, setSearchText] = useState({
        text: '',
        typing: false,
        typingTimeout: 0,
    });  

    return (
        <View style={{ 
            width: width - 2 - 40, 
            height: 32, 
            marginLeft: 20, 
            marginTop: offY, 
            borderRadius: 4, 
            borderColor: '#a9a9a9',
            borderWidth: 1,
            flexDirection: 'row' 
        }}>
            <TextInput style={{
                flex: 1, 
                marginTop: 1,
                marginLeft: 1, 
                marginRight: 1,
                paddingLeft: 5, 
                borderRadius: 3, 
                height: 28, 
                backgroundColor: '#fff',
                fontFamily: "Rubik-Medium", 
                fontSize: 12, 
                color: '#5f5f5f'
                
            }}
                value={searchText.text} multiline={false} selectTextOnFocus={true} maxLength={30}
                placeholder={LocStr('Search', language)} placeholderTextColor='#a9a9a9'
                onChangeText={(newText) => {

                    // clear triggerL
                    if (searchText.typingTimeout) {
                        clearTimeout(searchText.typingTimeout);
                    }

                    if (newText.length <= 30) {
                        setSearchText({
                            text: newText.toLowerCase(),
                            typing: false,
                            typingTimeout: setTimeout(function () {
                                handleSearch(newText);
                            }, 1500)
                        });
                    }
                }}
                onKeyPress={(e) => {
                    if (e.nativeEvent.key == "Enter") {
                        if (searchText.typingTimeout) {
                            clearTimeout(searchText.typingTimeout);
                        }
                        handleSearch(searchText.text);
                    }
                }}
            />

            <TouchableOpacity style={{ 
                borderRadius: 3,
                marginRight: 1,
                marginTop: 1, 
                width: 25, 
                height: 25, 
                backgroundColor: '#fff' }}
                onPress={() => {
                    if (searchText.typingTimeout) {
                        clearTimeout(searchText.typingTimeout);
                    }
                    setSearchText({ ...searchText, text: '' });
                    handleSearch('');
                }}
                disabled={(searchText.text.length === 0)}
            >
                <Image fadeDuration={0}
                    style={{ width: 19, height: 19, marginLeft: 3, marginTop: 4, position: 'absolute' }}
                    source={(searchText.text.length > 0) ? img_cancel : img_search} />
            </TouchableOpacity>
        </View>
    )

}

export { MyListSearch as default }