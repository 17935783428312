import React, { useContext, useState } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import MyMessagePopup from '../components/MyMessagePopup';
import MyRadioButton from '../components/MyRadioButton';
import { LocStr } from '../utils/Utils';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MyEditUserPopup = ( { parentWidth, 
                            onValueChanged, 
                            onCanceled, 
                            userIndex, 
                            userItem, 
                            isNew, 
                            forceRole='',
                            token,
                            language } ) => {

    var screenWidth  = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    var textInput1;
    var textInput2;
    var textInput3;
    var textInput4;

    const [state, setState] = useState(userItem);

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

//========================================================================================================

    const validateEmail = (val) => {
        //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return (pattern.test(val))
    }

    //========================================================================================================

    const validateRequiredFields = (calledFor) => {

        if (state.userFirstname.trim().length==0)
        {
            const txt = LocStr('Please enter first name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.userLastname.trim().length==0)
        {
            const txt = LocStr('Please enter last name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (!validateEmail(state.userEmail.trim()))
        {
            const txt = LocStr('Please enter a valid email address',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.userPhone.trim().length==0)
        {
            const txt = LocStr('Please enter a valid phone number',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.userRoles.length==0)
        {
            const txt = LocStr('Please select at least one role',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        return true;
    }

    //========================================================================================================

    // 'superAdmin', 'usersAdmin', 'siteAdmin', 'studyAdmin', 'subject'

    const isRoleChecked = (roleName) => {
        let filteredData = state.userRoles.filter(x => String(x).includes(roleName));
        return (filteredData.length>0)
    }

    const updateRoleState = (roleName) => {
        setState({...state,userRoles:[roleName]})
    }

    //========================================================================================================

    const onUpdatePressed = () => {

        if (!validateRequiredFields('update')) return;

        let newUser = { 
            ...state,
            userFirstname: state.userFirstname.trim(),
            userLastname:  state.userLastname.trim(),
            userEmail:     state.userEmail.trim(),
            userPhone:     state.userPhone.trim(),
            userFullname:  state.userFirstname.trim() + ' ' + state.userLastname.trim(),
        }

        onValueChanged(userIndex,newUser,isNew);
    }

    //######################################################################

    return (
        <TouchableOpacity style={{  width: screenWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
                        activeOpacity={1}
                        onPress={() => {
                            onCanceled();
                        }}
            >
            <TouchableOpacity style={{ width:500,/*height:200,*/ alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                                    }}
                                    activeOpacity={1}
                                    onPress={() => {
                                    }}
                >
                <View style={{ /*width:398,height:198, height:198,position:'absolute'*/flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={styles.lblDialogTitle} >{LocStr(isNew?((forceRole.length>0)?'Add site admin':'Add user'):'Edit user',language)}</Text>

                    {/* -----   first name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('First name:',language)}</Text>
    
                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userFirstname} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=50)
                                    {
                                        setState({...state, userFirstname:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput2.focus();
                                    }
                                }}
                                ref={(input) => { textInput1 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   last name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Last name:',language)}</Text>
    
                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userLastname} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=50)
                                    {
                                        setState({...state, userLastname:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput3.focus();
                                    }
                                }}
                                ref={(input) => { textInput2 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Email   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Email:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userEmail} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=30)
                                    {
                                        setState({...state, userEmail:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput4.focus();
                                    }
                                }}
                                ref={(input) => { textInput3 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Phone   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row',backgroundColor:'#ffffff'}} >

                        <Text style={styles.lblTitle} >{LocStr('Phone:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.userPhone} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=30)
                                    {
                                        setState({...state, userPhone:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                    }
                                }}
                                ref={(input) => { textInput4 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Roles   -----  'superAdmin', 'usersAdmin', 'siteAdmin', 'studyAdmin', 'subject' */}

                    {(isRoleChecked('superAdmin') || (forceRole.length>0))?null:
                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        <Text style={[styles.lblTitle,{backgroundColor:'#ffffff'}]} >{LocStr('Role:',language)}</Text>

                        <View style={{width:300,height:20,marginLeft:10,marginTop:3,flexDirection:'row',alignItems:'center'}} >
                            <View style={{height:20,marginLeft:0}} >
                                <MyRadioButton style={{height:20}} 
                                    title={LocStr('Users',language)} 
                                    value={isRoleChecked('usersAdmin')} 
                                    onPressed={(value) => {updateRoleState('usersAdmin')}} />
                            </View>

                            <View style={{height:20,marginLeft:20}} >
                                <MyRadioButton style={{height:20}} 
                                    title={LocStr('Study',language)} 
                                    value={isRoleChecked('studyAdmin')} 
                                    onPressed={(value) => {updateRoleState('studyAdmin')}} />
                            </View>

                            <View style={{height:20,marginLeft:20}} >
                                <MyRadioButton style={{height:20}} 
                                    title={LocStr('Site',language)} 
                                    value={isRoleChecked('siteAdmin')} 
                                    onPressed={(value) => {updateRoleState('siteAdmin')}} />
                            </View>
                        </View>

                    </View>}

                    {(isRoleChecked('superAdmin') || (forceRole.length>0))?null:
                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />}

                    {/* -----   buttons   ----- */}

                    <View style={{alignSelf:'center',marginTop:40,height:36,flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCanceled()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Cancel',language)}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{marginLeft:40,width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onUpdatePressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr(isNew?'Save':'Update',language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                    <View style={{width:1,height:20}} />

                </View> 
            </TouchableOpacity> 

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width:100,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width:500,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditUserPopup;