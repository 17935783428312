import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import { LocStr } from '../utils/Utils';

//=========================================================================

const MyMessagePopup = ( { parentWidth=560, onOkPressed, okTitle, message, language } ) => {

    var screenHeight = Dimensions.get('window').height;

    return (
        <View style={{  width: parentWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
            >
            <View style={{ width:400,height:200, alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                }}>
                <View style={{ width:398,height:198, position:'absolute',flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblTitle16,{marginTop:30,marginLeft:30,marginRight:30}]}>{message}</Text>

                    <View style={{marginLeft:149,marginTop:140,width:100,height:36,position:'absolute',flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onOkPressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#fff',borderRadius:6}}>
                                <Text style={[styles.lblTitle16,{marginTop:7,width:96}]}>{okTitle}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                </View> 
            </View> 
        </View>
    );
};

const styles = StyleSheet.create({
    lblTitle16: {
        width: 338,
        fontFamily: "Rubik-Regular",
        fontSize: 16,
        textAlign: 'center',
        color: "#001a2f",
        position: 'absolute',
        //backgroundColor: '#0f0',
    },
});

export default MyMessagePopup;