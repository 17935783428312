import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

//=========================================================================

const MySitesHeader = ( { width, actionsColumnWidth=90, arrTitles, onHeaderPressed, language } ) => {

    var showIcon = false
    var showGrey = false

    return (
        <View style={{width:width,height:30,backgroundColor:'#fff',flexDirection:'row',borderRadius:5,
        }}>

            <TouchableOpacity style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={arrTitles[0]} disabled={!showIcon} onPress={() => {onHeaderPressed(arrTitles[0]);}} >
                <Text numberOfLines={1} ellipsizeMode='tail' style={{
                    textAlign:'center',
                    fontFamily: "Rubik-Medium",
                    fontSize: 14,
                    color: "#001a2f",
                    }} >{arrTitles[0]}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={arrTitles[1]} disabled={!showIcon} onPress={() => {onHeaderPressed(arrTitles[1]);}} >
                <Text numberOfLines={1} ellipsizeMode='tail' style={{
                    textAlign:'center',
                    fontFamily: "Rubik-Medium",
                    fontSize: 14,
                    color: "#001a2f",
                    }} >{arrTitles[1]}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={arrTitles[2]} disabled={!showIcon} onPress={() => {onHeaderPressed(arrTitles[2]);}} >
                <Text numberOfLines={1} ellipsizeMode='tail' style={{
                    textAlign:'center',
                    fontFamily: "Rubik-Medium",
                    fontSize: 14,
                    color: "#001a2f",
                    }} >{arrTitles[2]}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
            </TouchableOpacity>

            {(arrTitles.length>=4)?
            <TouchableOpacity style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={arrTitles[3]} disabled={!showIcon} onPress={() => {onHeaderPressed(arrTitles[3]);}} >
                <Text numberOfLines={1} ellipsizeMode='tail' style={{
                    textAlign:'center',
                    fontFamily: "Rubik-Medium",
                    fontSize: 14,
                    color: "#001a2f",
                    }} >{arrTitles[3]}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
            </TouchableOpacity>:null}

            {(arrTitles.length>=5)?
            <TouchableOpacity style={{width:90,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={arrTitles[4]} disabled={!showIcon} onPress={() => {onHeaderPressed(arrTitles[4]);}} >
                <Text numberOfLines={1} ellipsizeMode='tail' style={{
                    textAlign:'center',
                    fontFamily: "Rubik-Medium",
                    fontSize: 14,
                    color: "#001a2f",
                    }} >{arrTitles[4]}{showIcon?<FontAwesome name="sort" style={{fontSize:16,color:showGrey?'#d9d9d9':'#797979',marginLeft:6,marginTop:5}} />:null}</Text>
            </TouchableOpacity>:null}

            <View style={{width:actionsColumnWidth,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={'actions'} />

        </View>
    );
};

export default MySitesHeader;