import React from 'react';
import { View, Text } from 'react-native';
import { Feather } from '@expo/vector-icons';

//=========================================================================

const MyTableHeader = ( { width, arrTitles, language } ) => {

    const renderHeader = () => {

        let renderList = arrTitles.map((item, index) => {

            return(
                <View style={{flex:1,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={item} >
                    <Text numberOfLines={1} ellipsizeMode='tail' style={{
                        textAlign:'center',
                        fontFamily: "Rubik-Medium",
                        fontSize: 14,
                        color: "#001a2f",
                        }} >{item}
                    </Text>
                </View>
            );
        });

        return renderList;
    }

    //-------------------------------------------------------------------------------

    return (
        <View style={{width:width,height:30,backgroundColor:'#fff',flexDirection:'row',borderRadius:5,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.22,
            shadowRadius: 12,
        }}>

            <View style={{width:30,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={'index'} />

            {
                renderHeader()
            }

            <View style={{width:90,height:30,justifyContent:'center',backgroundColor:'#fff'}} key={'actions'} />

        </View>
    );
};

export default MyTableHeader;