import React from 'react';
import { Image, TouchableOpacity } from 'react-native';

//=========================================================================

const img_on  = require('../../assets/images/switch_on.png');
const img_off = require('../../assets/images/switch_off.png');
  
//=========================================================================

const MySwitch = ( { marginTop, onValueChanged, value } ) => {
    return (
        <TouchableOpacity style={{width:34,height:17,marginTop:marginTop}} onPress={() => {onValueChanged(!value)}} >
            <Image fadeDuration={0} style={{width:34,height:17}} source={value?img_on:img_off} />
        </TouchableOpacity>
    );
};

export default MySwitch;