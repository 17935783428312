import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, metadataDescription } from '../utils/Utils';

//=========================================================================

const MyStudyLine = ( { width, rowIndex, canEdit, canDelete, theStudy, protocolName, onActionPressed, language } ) => {

    const sitesDescription = (item) => {
        let tot = item.length
        if (tot==0)
        {
            return ''
        } else {
            if (tot==1)
            {
                return '1 ' + LocStr('site',language)
            } else {
                return '' + tot + ' ' + LocStr('sites',language)
            }
        }
    }

    //-----------------------------------------------------

    let sitesText = sitesDescription(theStudy.studySites)
    let metaText  = metadataDescription(theStudy.studyMetadata,language)
    
    return (
        <View style={{width:width,height:34,marginTop:0,marginLeft:0}} >

            <View style={{width:width,height:32,marginTop:2,marginLeft:0,borderRadius:5,
                }} >
                
                <TouchableOpacity style={{width:width,height:32,marginTop:0,position:'absolute',flexDirection:'row'}}
                     onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit}
                    >

                    <View style={{flex:0.6,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'left',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theStudy.studyShortName}</Text>

                    </View>

                    <View style={{flex:0.4,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'left',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: (protocolName=='')?'#f00':'#3f3f3f',
                            //backgroundColor:'#0f0',
                            }} >{(protocolName=='')?LocStr('Not defined',language):protocolName}</Text>

                    </View>

                    <View style={{width:100,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: (sitesText==='')?'#f00':'#3f3f3f',
                            //backgroundColor:'#0f0',
                            }} >{(sitesText=='')?LocStr('No sites',language):sitesText}</Text>

                    </View>

                    <View style={{width:110,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: (metaText==='')?'#f00':'#3f3f3f',
                            //backgroundColor:'#0f0',
                            }} >{(metaText==='')?LocStr('Not defined',language):metaText}</Text>

                    </View>

                    <View style={{width:60,height:32,flexDirection:'row',backgroundColor:'#fff'}} key={'actions'} >
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit} >
                            <Feather name="edit-2" style={{fontSize: 16,position: 'absolute',color: canEdit?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('delete',rowIndex);}} disabled={!canDelete} >
                            <Feather name="trash-2" style={{fontSize: 16,position: 'absolute',color: canDelete?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>

                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default MyStudyLine;