import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { StyleSheet } from 'react-native';
import LoginScreen from './src/screens/LoginScreen';
import AdminScreen from './src/screens/AdminScreen';
import ProtocolsScreen from './src/screens/ProtocolsScreen';
import { Provider as HealablesProvider } from './src/context/healablesServerContext';
import { Provider as FlaskDataProvider } from './src/context/flaskDataServerContext';
import 'dotenv/config';

const navigator = createStackNavigator({
  LoginScreen: LoginScreen,
  AdminScreen: AdminScreen,
  ProtocolsScreen: ProtocolsScreen,
},
  {
    initialRouteName: 'LoginScreen',
    defaultNavigationOptions: {
      animationEnabled: false,
      title: 'Healables Protocols - Version ' + require('./package.json').version,
    }
  });

// wrapping navigator inside data provider:
const App = createAppContainer(navigator);
export default () => {
  return (
    <HealablesProvider>
      <FlaskDataProvider>
        <App />
      </FlaskDataProvider>
    </HealablesProvider>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
