import React, { useContext, useState, useEffect } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet, FlatList } from 'react-native';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import MyMessagePopup from './MyMessagePopup';
import { LocStr, defaultMetadataArray, getOperationMode, isDefaultMetadataKey } from '../utils/Utils';
import { Feather } from '@expo/vector-icons';
import MyMetadataLine from './MyMetadataLine'
import MyUsersHeader from '../components/MyUsersHeader';
import MyRadioButton from '../components/MyRadioButton';

//=========================================================================

const MyMetadataPopup = ({ parentWidth,
    arrConfiguration,
    onValueChanged,
    onCanceled,
    isNew,
    language }) => {

    //=================================================================================

    var screenWidth = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    const popupW = 800;
    
    const [metadataState, setMetadataState] = useState((arrConfiguration.length>0)?arrConfiguration:defaultMetadataArray());

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false, 
        questionId: '', 
        itemId: '',
        title: '',
    });

    //========================================================================================================

    const onQuestionAnswerWasNo = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});
    }

    const onQuestionAnswerWasYes = (questionId,itemId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                itemId: '',
                title: ''});

        switch (questionId)
        {
            case 'delete':
                let rowIndex = itemId
                setMetadataState(() => {
                    let filtered = [...metadataState]
                    filtered.splice(rowIndex, 1)
        
                    return filtered
                })
                break;
        }
    }

    //=================================================================================

    const handleDelete = (rowIndex) => {
        const txt = LocStr('Are you sure you want to delete?',language);
        setQuestionPopupState({isQuestionVisible:true,questionId:'delete',itemId:''+rowIndex,title:txt});
    }

    //=================================================================================

    const handleSubmit = (metadataRow, rowIndex) => {

        //console.log('@@@@MyMetadataPopup metadataRow, rowIndex:', metadataRow, rowIndex);
        let metadataArr = [...metadataState]
        //console.log('@@@@MyMetadataPopup metadataArr:', metadataArr);
        metadataArr[rowIndex] = metadataRow
        setMetadataState(metadataArr)

    }

    //=================================================================================

    const handleOKPressed = () => {

        if (noOperationModeChecked())
        {
            let txt = LocStr('Please select operation mode',language)
            setMessagePopupState({ isMessageVisible: true, title: txt })
            return
        }
        onValueChanged(metadataState)
    }

    //=================================================================================


    const handleAddItem = () => {
        
        setMetadataState(() => {
            let metadataArr
            let newMetadata = {
                key: '',
                value: '',
                description: ''
            }
            if (metadataState.length > 0) {
                metadataArr = [newMetadata, ...metadataState]
            } else {
                metadataArr = [newMetadata]
            }
            
            return metadataArr
        })
    }

    const _renderItem = ({ item, index }) => {

        if (item.key=='OperationMode')
        {
            return null
        }
        if (operationMode!='trial')
        {
            if ((item.key=='ProtocolStartDate') ||
                (item.key=='ProtocolWeekly') ||
                (item.key=='StudyID') ||
                (item.key=='ProtocolID') ||
                (item.key=='_sep_trial_'))
            {
                return null
            }
        }

        if (item.key.startsWith('_sep_'))
        {
            return (
                <View style={{width:popupW-40, height:34}} >
                    <Text style={{marginTop:9,marginLeft:9,fontFamily: "Rubik-Medium",fontSize:14,color:'#001a2f',backgroundColor:'#fff'}} >
                        {LocStr(item.value,language)}</Text>
                </View>
            )
        }

        return (
            <View>
                <MyMetadataLine
                    width={popupW - 40}
                    rowIndex={index}
                    metadata={item}
                    onSubmit={handleSubmit}
                    canDelete={!isDefaultMetadataKey(item.key)}
                    handleDelete={handleDelete}
                    language={language}
                />
            </View>
        )
    }

    //######################################################################

    function noOperationModeChecked()
    {
        if ((metadataState==null) || (metadataState==undefined))
        {
            return true
        }

        let opMode = metadataState.filter((item)=> {
            return (item.key=='OperationMode')
        })
        if (opMode.length==0)
        {
            return true
        }
        return (opMode[0].value.length==0)
    }

    function isOperationModeChecked(cmpWith)
    {
        if ((metadataState==null) || (metadataState==undefined))
        {
            return false
        }

        let opMode = metadataState.filter((item)=> {
            return (item.key=='OperationMode')
        })
        if (opMode.length>0)
        {
            return (opMode[0].value==cmpWith.toLowerCase())
        } else {
            return false
        }
    }

    function updateOperationModeState(newVal)
    {
        var newState = []
        var foundOne = false
        metadataState.map((item)=>{
            if (item.key=='OperationMode')
            {
                foundOne = true
                newState.push({ ...item, value: newVal.toLowerCase() })
            } else {
                newState.push(item)
            }
        })

        if (!foundOne)
        {
            setMetadataState([ { key: 'OperationMode', value: newVal.toLowerCase(), description: 'care / trial / home / pro' },
                                ...newState ])
            return
            //newState = [  ]
            //newState.push({ key: 'OperationMode', value: newVal.toLowerCase(), description: 'care / trial / home / pro' })
        }

        setMetadataState(newState)
    }

    //######################################################################

    let operationMode = getOperationMode(metadataState)

    return (
        <View style={{ width: screenWidth, height: screenHeight-60-73, justifyContent: 'center', position: 'absolute', marginTop: 60+73 }} >
            
            <TouchableOpacity style={{width: popupW, height: screenHeight-60-73, alignSelf: 'center', 
                backgroundColor: '#fff', borderRadius: 8, 
                shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.22, shadowRadius: 12, }}
                activeOpacity={1}
                onPress={() => {}} >

                <Text  style={[styles.lblDialogTitle, { width: popupW }]} >
                    {LocStr(isNew ? 'Add configuration' : 'Edit configuration', language)}
                </Text>

                {/* -----   add line   ----- */}
                <TouchableOpacity style={{ marginLeft: popupW - 50, marginTop: 15, marginBottom: 10, width: 30, height: 30, position: 'absolute' }}
                    onPress={handleAddItem}
                    >
                    <Feather name="plus-circle" style={{ fontSize: 30, position: 'absolute', color: '#6a707e' }} />
                </TouchableOpacity>

                {/* -----   operation mode line   ----- */}

                <View style={{alignSelf:'center',width:popupW-40,marginTop:20,flexDirection:'row'}} >

                    <Text style={[styles.lblTitle,{fontFamily: "Rubik-Medium",color:noOperationModeChecked()?'#f00':'#001a2f',backgroundColor:'#fff'}]} >
                        {LocStr('* Operation Mode:',language)}</Text>

                    <View style={{width:300,height:20,marginLeft:10,marginTop:3,flexDirection:'row',alignItems:'center'}} >
                        <View style={{height:20,marginLeft:0}} >
                            <MyRadioButton style={{height:20}} 
                                title={LocStr('Care',language)} 
                                value={isOperationModeChecked('Care')} 
                                onPressed={(value) => {updateOperationModeState('Care')}} />
                        </View>

                        <View style={{height:20,marginLeft:20}} >
                            <MyRadioButton style={{height:20}} 
                                title={LocStr('Trial',language)} 
                                value={isOperationModeChecked('Trial')} 
                                onPressed={(value) => {updateOperationModeState('Trial')}} />
                        </View>

                        <View style={{height:20,marginLeft:20}} >
                            <MyRadioButton style={{height:20}} 
                                title={LocStr('Home',language)} 
                                value={isOperationModeChecked('Home')} 
                                onPressed={(value) => {updateOperationModeState('Home')}} />
                        </View>

                        <View style={{height:20,marginLeft:20}} >
                            <MyRadioButton style={{height:20}} 
                                title={LocStr('Pro',language)} 
                                value={isOperationModeChecked('Pro')} 
                                onPressed={(value) => {updateOperationModeState('Pro')}} />
                        </View>
                    </View>

                </View>

                {/* -----   metadata line   ----- */}

                <View style={{ width: popupW-40, marginTop: 20 }} >

                    <MyUsersHeader style={{width:popupW-40,height:32,marginTop:0,marginLeft: 20}} 
                                        width={popupW-40} 
                                        actionsColumnWidth={0}
                                        sortByName={true}
                                        arrTitles={['Key','Value','Description']}
                                        onHeaderPressed={(headerName)=>{
                                        }}
                                        language={language}
                                        />

                    <FlatList 
                        style={{ 
                            width: popupW - 40,
                            height: screenHeight - 60 - 73 - 100-50 - 38, 
                            marginLeft: 20,
                            marginTop: 0, 
                        }}
                        data={metadataState}
                        renderItem={_renderItem}
                        showsVerticalScrollIndicator={false}
                    />

                </View>

                {/* -----   buttons   ----- */}

                <View 
                style={{ 
                    alignSelf: 'center', 
                    marginTop: 8, 
                    height: 36, 
                    flexDirection: 'row' 
                }} >

                    <TouchableOpacity 
                    style={{ 
                        width: 100, 
                        height: 36, 
                        backgroundColor: '#008cff', 
                        borderRadius: 8 
                    }}
                        onPress={() => { onCanceled() }}
                    >
                        <View 
                        style={{ 
                            width: 96, 
                            height: 32, 
                            position: 'absolute', 
                            margin: 2, 
                            backgroundColor: '#008cff', 
                            borderRadius: 6 }}>
                            <Text style={[styles.lblButton, { marginTop: 7 }]}>{LocStr('Cancel', language)}</Text>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity 
                    style={{ 
                        marginLeft: 40, 
                        width: 100, 
                        height: 36, 
                        backgroundColor: '#008cff', 
                        borderRadius: 8 
                    }}
                        onPress={() =>  handleOKPressed() }
                    >
                        <View 
                        style={{ 
                            width: 96, 
                            height: 32, 
                            position: 'absolute', 
                            margin: 2, 
                            backgroundColor: '#008cff', 
                            borderRadius: 6
                        }}>
                            <Text 
                            style={[styles.lblButton, { marginTop: 7 }]}
                            >
                            {LocStr(isNew ? 'Save' : 'Update', language)}
                            </Text>
                        </View>
                    </TouchableOpacity>

                </View>

                <View style={{ width: 1, height: 20 }} />


            </TouchableOpacity>

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

            {questionPopupState.isQuestionVisible?
                <MyAskQuestionPopup 
                    parentWidth={screenWidth}
                    onYesPressed={(questionId,itemId) => {onQuestionAnswerWasYes(questionId,itemId)}}
                    onNoPressed={(questionId,itemId) => {onQuestionAnswerWasNo(questionId,itemId)}}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.itemId}
                    yesTitle={LocStr('Yes',language)}
                    noTitle={LocStr('No',language)}
                    question={questionPopupState.title}
                    language={language}
                    />:null
                }


        </View>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width: 120,
        marginTop: 5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width: 100,
        marginTop: 5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width: 800,
        marginTop: 20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyMetadataPopup;