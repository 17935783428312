import React, { useContext, useState, useEffect } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import MyMessagePopup from '../components/MyMessagePopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import MySelectProtocolPopup from '../components/MySelectProtocolPopup';
import MyEditStudySitesPopup from '../components/MyEditStudySitesPopup';
import MyMetadataPopup from '../components/MyMetadataPopup'
import { FontAwesome } from '@expo/vector-icons';
import { LocStr, validateJSONArray, metadataDescription, getOperationMode } from '../utils/Utils';

//=========================================================================

const MyEditStudyPopup = ( { parentWidth, 
                            onValueChanged, 
                            onCanceled, 
                            studyIndex, 
                            studyItem, 
                            isNew, 

                            arrUsers,
                            onUserAdded,
                            
                            arrSites,
                            onSiteAdded,
                            
                            arrStudies,

                            arrProtocols,
                            token,
                            language } ) => {

    var screenWidth  = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    const popupW      = 600;
    const popupInnerW = popupW-200;

    var textInput2;

    const [auxArrays, setAuxArrays] = useState({
        allUsers: arrUsers,
        allSites: arrSites,
    });

    const [state, setState] = useState(studyItem);

    const [protocolPopupState, setProtocolPopupState] = useState({isProtocolPopupVisible: false, title: ''});

    const [studySitesPopupState, setStudySitesPopupState] = useState({isStudySitesPopupVisible: false, title: ''});

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

    const [metadataPopupState, setMetadataPopupState] = useState({isMetadataPopupVisible: false, title: ''});

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false, 
        questionId: '', 
        studyId: '',
        title: '',
    });

    //========================================================================================================

    const validateRequiredFields = (calledFor) => {

        if (state.studyShortName.length==0)
        {
            const txt = LocStr('Please enter brief title',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.studyFullName.length==0)
        {
            const txt = LocStr('Please enter official title',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        return true;
    }

    //========================================================================================================

    const onQuestionAnswerWasNo = (questionId,studyId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                studyId: '',
                title: ''});
    }

    const onQuestionAnswerWasYes = (questionId,studyId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                studyId: '',
                title: ''});

        switch (questionId)
        {
        }
    }

    //========================================================================================================

    const handleSelectProtocol = () => {
        setProtocolPopupState({isProtocolPopupVisible:true,title:''});
    }

    const handleSelectedProtocol = (newVal) => {
        setProtocolPopupState({isProtocolPopupVisible:false, title:''})
        setState({...state, studyUniqueProtocolId:newVal.protocolNum});
    }

    const handleMetadataChanged = (newVal) => {
        setMetadataPopupState({isMetadataPopupVisible:false, title:''})
        const newValJson = JSON.stringify(newVal) 
        setState({...state, studyMetadata: newValJson})   
    }

    function getProtocolNameFromUniqueId(theId) {
        if (theId==='') return '';

        let idx = arrProtocols.findIndex((item)=> {
            return (item.protocolNum===theId);
        })
        if (idx === -1)
        {
            return '';
        } else {
            return arrProtocols[idx].protocolName;
        }
    }

    function sitesDescription(item) {
        let tot = item.length
        if (tot==0)
        {
            return ''
        } else {
            if (tot==1)
            {
                return '1 ' + LocStr('site',language)
            } else {
                return '' + tot + ' ' + LocStr('site/s',language)
            }
        }
    }

    //========================================================================================================

    const handleSelectSites = () => {
        setStudySitesPopupState({isStudySitesPopupVisible:true,title:''});
    }

    const handleSitesChanged = (newVal) => {
        setStudySitesPopupState({isStudySitesPopupVisible:false, title:''})

        var newSites = [];
        newVal.map((item) => {
            newSites.push(item)
        })
        
        setState({ ...state, studySites: newSites});
    }

    //========================================================================================================

    const handleEditMetadata = () => {
        setMetadataPopupState({isMetadataPopupVisible:true,title:''});
    }

    //========================================================================================================

    const onUpdatePressed = () => {

        if (!validateRequiredFields('update')) return;

        let newStudy = state

        onValueChanged(studyIndex,newStudy,isNew);
    }

    //######################################################################

    let protText  = getProtocolNameFromUniqueId(state.studyUniqueProtocolId)
    let sitesText = sitesDescription(state.studySites)
    let metaText  = metadataDescription(state.studyMetadata,language)

    return (
        <TouchableOpacity style={{  width: screenWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
                        activeOpacity={1}
                        onPress={() => {
                            //onCanceled();
                        }}
            >
            <TouchableOpacity style={{ width:popupW,/*height:200,*/ alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                                    }}
                                    activeOpacity={1}
                                    onPress={() => {
                                    }}
                >
                <View style={{ /*width:398,height:198, height:198,position:'absolute'*/flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={[styles.lblDialogTitle,{width:popupW}]} >{LocStr(isNew?'Add study':'Edit study',language)}</Text>

                    {/* -----   short name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Brief title:',language)}</Text>
    
                        <View style={{width:popupInnerW,height:90,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.studyShortName} multiline={false} selectTextOnFocus={true} maxLength={200} multiline={true}
                                onChangeText={(newText)=>{
                                    if (newText.length<=200)
                                    {
                                        setState({...state, studyShortName:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput2.focus();
                                    }
                                }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   last name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Official title:',language)}</Text>
    
                        <View style={{width:popupInnerW,height:90,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.studyFullName} multiline={false} selectTextOnFocus={true} maxLength={200} multiline={true}
                                onChangeText={(newText)=>{
                                    if (newText.length<=200)
                                    {
                                        setState({...state, studyFullName:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        //textInput3.focus();
                                    }
                                }}
                                ref={(input) => { textInput2 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   protocol   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Protocol:',language)}</Text>
    
                        <TouchableOpacity style={{width:popupInnerW,height:27,marginLeft:10,borderRadius:3,borderWidth:1,borderColor:'#a9a9a9',backgroundColor:'#ffffff',flexDirection:'row'}} 
                            onPress={() => {handleSelectProtocol()}}
                            >

                            <Text style={{ margin:4,width:popupInnerW-2,color:(protText=='')?'#f00':'#3f3f3f',}} >{(protText=='')?LocStr('Not defined',language):protText}</Text>

                            <View style={{width:1,height:25,marginLeft:popupInnerW-29,position:'absolute',backgroundColor:'#a9a9a9'}} />

                            <View style={{width:26,height:25,marginLeft:popupInnerW-28,position:'absolute',borderTopRightRadius:3,backgroundColor:'#f1f5f9'}} >
                                <FontAwesome name="chevron-right" style={{fontSize: 14, position: 'absolute',color:'#6a707e',marginLeft:9,marginTop:6,}} />
                            </View>

                        </TouchableOpacity>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   site/s   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Site/s:',language)}</Text>
    
                        <TouchableOpacity style={{width:popupInnerW,height:27,marginLeft:10,borderRadius:3,borderWidth:1,borderColor:'#a9a9a9',backgroundColor:'#ffffff',flexDirection:'row'}} 
                            onPress={() => {handleSelectSites()}}
                            >

                            <Text style={{ margin:4,width:popupInnerW-2,color:(sitesText=='')?'#f00':'#3f3f3f',}} >{(sitesText=='')?LocStr('No sites',language):sitesText}</Text>

                            <View style={{width:1,height:25,marginLeft:popupInnerW-29,position:'absolute',backgroundColor:'#a9a9a9'}} />

                            <View style={{width:26,height:25,marginLeft:popupInnerW-28,position:'absolute',borderTopRightRadius:3,backgroundColor:'#f1f5f9'}} >
                                <FontAwesome name="chevron-right" style={{fontSize: 14, position: 'absolute',color:'#6a707e',marginLeft:9,marginTop:6,}} />
                            </View>

                        </TouchableOpacity>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   metadata   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Configuration:',language)}</Text>
    
                        <TouchableOpacity style={{width:popupInnerW,height:27,marginLeft:10,borderRadius:3,borderWidth:1,borderColor:'#a9a9a9',backgroundColor:'#ffffff',flexDirection:'row'}} 
                            onPress={() => {handleEditMetadata()}}
                            >

                            <Text style={{ margin:4,width:popupInnerW-2,color:(metaText=='')?'#f00':'#3f3f3f',}} >{(metaText.length==0)?LocStr('Not defined',language):metaText}</Text>

                            <View style={{width:1,height:25,marginLeft:popupInnerW-29,position:'absolute',backgroundColor:'#a9a9a9'}} />

                            <View style={{width:26,height:25,marginLeft:popupInnerW-28,position:'absolute',borderTopRightRadius:3,backgroundColor:'#f1f5f9'}} >
                                <FontAwesome name="chevron-right" style={{fontSize: 14, position: 'absolute',color:'#6a707e',marginLeft:9,marginTop:6,}} />
                            </View>

                        </TouchableOpacity>

                    </View>

                    <View style={{alignSelf:'center',width:popupW-80,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   buttons   ----- */}

                    <View style={{alignSelf:'center',marginTop:40,height:36,flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCanceled()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Cancel',language)}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity style={{marginLeft:40,width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onUpdatePressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr(isNew?'Save':'Update',language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                    <View style={{width:1,height:20}} />

                </View> 
            </TouchableOpacity> 

            {protocolPopupState.isProtocolPopupVisible?
                <MySelectProtocolPopup 
                    parentWidth={screenWidth}
                    arrProtocols={arrProtocols}
                    onProtocolSelected={(newVal) => {handleSelectedProtocol(newVal)}}
                    onCancelPressed={() => setProtocolPopupState({isProtocolPopupVisible:false, title:''})}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

            {studySitesPopupState.isStudySitesPopupVisible?
                <MyEditStudySitesPopup 
                    parentWidth={screenWidth}

                    currStudyId={studyItem.studyId}
                    
                    arrUsers={auxArrays.allUsers}
                    onUserAdded={(newUser) => { 
                        var newArray = [ ...auxArrays.allUsers ]
                        newArray.push(newUser)

                        setAuxArrays({ ...auxArrays, allUsers: newArray }) 
                        onUserAdded(newUser)
                    }}
                    
                    arrSites={auxArrays.allSites}
                    onSiteAdded={(newSite) => { 
                        var newArray = [ ...auxArrays.allSites ]
                        newArray.push(newSite)

                        setAuxArrays({ ...auxArrays, allSites: newArray }) 
                        onSiteAdded(newSite)
                    }}

                    arrStudies={arrStudies}
                    
                    studySites={state.studySites}
                    onSitesChanged={(newVal) => {handleSitesChanged(newVal)}}
                    
                    onCancelPressed={() => setStudySitesPopupState({studySitesPopupState:false, title:''})}
                    message={messagePopupState.title}
                    language={language}
                    token={token}
                    />:null
                }

            {metadataPopupState.isMetadataPopupVisible?
                <MyMetadataPopup 
                    parentWidth={screenWidth}
                    arrConfiguration={JSON.parse(validateJSONArray(state.studyMetadata))}
                    onValueChanged={(newVal) => {handleMetadataChanged(newVal)}}
                    onCanceled={() => setMetadataPopupState({metadataPopupState:false, title:''})}
                    isNew={isNew}
                    language={language}
                    />:null
                } 

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }

            {questionPopupState.isQuestionVisible?
                <MyAskQuestionPopup 
                    parentWidth={screenWidth}
                    onYesPressed={(questionId,studyId) => {onQuestionAnswerWasYes(questionId,studyId)}}
                    onNoPressed={(questionId,studyId) => {onQuestionAnswerWasNo(questionId,studyId)}}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.studyId}
                    yesTitle={LocStr('Yes',language)}
                    noTitle={LocStr('No',language)}
                    question={questionPopupState.title}
                    language={language}
                    />:null
                }

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width:100,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width:800,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditStudyPopup;