import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, formatDate } from '../utils/Utils'

//=========================================================================

const MyTopMenu = ( { width, selectedTab, arrTabs, onTabSelected, language } ) => {

    const renderMenu = () => {

        let renderList = arrTabs.map((item, index) => {

            return(
                <TouchableOpacity style={{flexDirection: 'row',height:40,marginLeft:(index==0)?20:0}} key={item} activeOpacity={1} onPress={() => {onTabSelected(item)}} >
                    <View style={{height:40,marginLeft:20 }} >
                        <Text style={{height:20,marginTop:10,textAlign: 'center',fontFamily: "Quicksand-Medium",fontSize:16,color:"#ffffff"}} >{LocStr(item,language)}</Text>
                        <View style={{height:2, backgroundColor:(selectedTab==item)?'#ffffff':'#464a54'}} />
                    </View>
                    <View style={{width:1, height:40,marginLeft:20, backgroundColor:'#7f7f7f'}} />
                </TouchableOpacity>
                );
        });

        return renderList;
    }

    //-------------------------------------------------------------------------------

    return (
        <View style={{
            height: 40,
            marginTop: 60,
            width: width,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.12,
            shadowRadius: 12,
            flexDirection: 'row',
            backgroundColor: '#464a54',
            position: 'absolute',
            }} 
        >

        <View style={{width:10, height:40, backgroundColor:'#464a54'}} />

        {
            renderMenu()
        }
        
        </View>
    );    
};

export default MyTopMenu;

