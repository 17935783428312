import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { LocStr, calcFreqDesc } from '../utils/Utils';

//=========================================================================

const MyAnalgesicsLine = ( { width, offX, actionsColumnWidth=90, rowIndex, canDecrement, canIncrement, theItem, onActionPressed, language } ) => {

    //-----------------------------------------------------

    var freqColor = '#3f3f3f'
    if (theItem.freq==0)
    {
        freqColor = '#ff0000'
    }
    if (theItem.freq==99)
    {
        freqColor = '#008cff'
    }
    
    return (
        <View style={{width:width,height:34,marginTop:0,marginLeft:offX}} >

            <View style={{width:width,height:32,marginTop:2,marginLeft:0,borderRadius:5,
                }} >
                
                <View style={{width:width,height:32,marginTop:0,position:'absolute',flexDirection:'row'}} >

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theItem.name}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:8, marginRight:8,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theItem.dose}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            marginLeft:4, marginRight:4,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: freqColor,
                            //backgroundColor:'#0f0',
                            }} >{LocStr(calcFreqDesc(theItem.freq),language)}</Text>

                    </View>

                    <View style={{width:actionsColumnWidth,height:32,flexDirection:'row',backgroundColor:'#fff'}} key={'actions'} >
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('minus',rowIndex);}} disabled={!canDecrement} >
                            <Feather name="minus" style={{fontSize: 16,position: 'absolute',color: canDecrement?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('reset',rowIndex);}} disabled={false} >
                            <MaterialIcons name="cancel" style={{fontSize: 16,position: 'absolute',color: '#6a707e',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('plus',rowIndex);}} disabled={!canIncrement} >
                            <Feather name="plus" style={{fontSize: 16,position: 'absolute',color: canIncrement?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </View>
    );
};

export default MyAnalgesicsLine;