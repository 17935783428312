import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { LocStr, isRoleChecked } from '../utils/Utils';

//=========================================================================

const MyUserLine = ( { width, rowIndex, canEdit, canDelete, canInvite, theUser, onActionPressed, language } ) => {

    // 'superAdmin', 'usersAdmin', 'siteAdmin', 'studyAdmin', 'subject'
    const roleToDisplayable = (roleName) => {
        switch (roleName)
        {
            case 'superAdmin': return 'Super admin'
            case 'usersAdmin': return 'Users admin'
            case 'siteAdmin':  return 'Site admin'
            case 'studyAdmin': return 'Study admin'
            case 'subject':    return 'Subject'
        }
    }

    const rolesToString = (roles) => {
        let res = ''
        roles.map((item,index) => {
            if (index > 0)
            {
                res = res + ', '
            }
            res = res + roleToDisplayable(item)
        })
        return res
    }

    let isSuperAdmin = isRoleChecked('superAdmin',theUser.userRoles)

    return (
        <View style={{width:width,height:34,marginTop:0,marginLeft:0}} >

            <View style={{width:width,height:32,marginTop:2,marginLeft:0,borderRadius:5,
                }} >
                
                <TouchableOpacity style={{width:width,height:32,marginTop:0,position:'absolute',flexDirection:'row'}} 
                    onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit} 
                    >

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theUser.userFullname}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theUser.userEmail}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: "#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{theUser.userPhone}</Text>

                    </View>

                    <View style={{flex:1,height:32,justifyContent:'center',backgroundColor:'#fff'}} >

                        <Text numberOfLines={1} ellipsizeMode='tail' style={{
                            //flex:1,
                            textAlign:'center',
                            //alignSelf:'center',
                            fontFamily: "Rubik-Regular",
                            fontSize: 14,
                            color: isSuperAdmin?"#0000cf":"#3f3f3f",
                            //backgroundColor:'#0f0',
                            }} >{rolesToString(theUser.userRoles)}</Text>

                    </View>

                    <View style={{width:60,height:32,justifyContent:'center',alignItems:'center',backgroundColor:'#fff'}} >

                        {(theUser.userStatus=='approved')?
                            <Feather name="check" style={{fontSize: 16,position: 'absolute',color:'#00aa00',marginLeft:2,marginTop:1}} />:null}
                        {(theUser.userStatus=='pending')?
                            <Text numberOfLines={1} ellipsizeMode='tail' style={{textAlign:'center',fontFamily: "Rubik-Regular",fontSize: 14,color: "#aa0000",}} >{LocStr('Pending',language)}</Text>:null}
                        {(theUser.userStatus=='new')?
                            <Text numberOfLines={1} ellipsizeMode='tail' style={{textAlign:'center',fontFamily: "Rubik-Regular",fontSize: 14,color: "#3f3f3f",}} >{LocStr('Invite',language)}</Text>:null}

                    </View>

                    <View style={{width:90,height:32,flexDirection:'row',backgroundColor:'#fff'}} key={'actions'} >
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('invite',rowIndex);}} disabled={!canInvite} >
                            <Feather name="send" style={{fontSize: 16,position: 'absolute',color: canInvite?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('edit',rowIndex);}} disabled={!canEdit} >
                            <Feather name="edit-2" style={{fontSize: 16,position: 'absolute',color: canEdit?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>
                    
                        <TouchableOpacity style={{width:30,height:30}} onPress={() => {onActionPressed('delete',rowIndex);}} disabled={!canDelete} >
                            <Feather name="trash-2" style={{fontSize: 16,position: 'absolute',color: canDelete?'#6a707e':'#cfcfcf',marginLeft:8,marginTop:8}} />
                        </TouchableOpacity>

                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default MyUserLine;