import React, { useContext, useState } from 'react';
import { View, TextInput, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import MyMessagePopup from '../components/MyMessagePopup';
import MyAskQuestionPopup from '../components/MyAskQuestionPopup';
import MyCheckbox from '../components/MyCheckbox';
import { LocStr, validateFreq, validateCurrent, validateDuration, strToNumWaveform, strToNumPolarity, strToArray } from '../utils/Utils';
import * as DocumentPicker from 'expo-document-picker';
import { Context as healablesServerContext } from '../context/healablesServerContext';

//=========================================================================

const MyEditProtocolPopup = ( { parentWidth, 
                                validateProtocolNumInternally,
                                findVacantProtocolNumInternally,
                                onValueChanged, 
                                onCanceled, 
                                protocolIndex, 
                                protocolItem, 
                                isNew, 
                                isCopy, 
                                token,
                                language } ) => {

    var screenWidth  = parentWidth;//Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;

    var textInput4;
    var textInput5;
    var textInput6;

    const [state, setState] = useState(protocolItem);

    const [messagePopupState, setMessagePopupState] = useState({isMessageVisible: false, title: ''});

    const [questionPopupState, setQuestionPopupState] = useState({
        isQuestionVisible: false, 
        questionId: '', 
        userId: '',
        title: '',
    });

    const { getAllProtocolsHeaders, 
        } = useContext(healablesServerContext);

//========================================================================================================

    const _pickDocument = async () => {
        try {
            let fileType = 'text/csv';

            let result = await DocumentPicker.getDocumentAsync({
                type: fileType,
            });

            if (result.cancelled)
            {
            } else {
                if (result.type==='success')
                {
                    // check file type selected:
                    if (!result.name.endsWith('.csv'))
                    {
                        const txt = LocStr('Please select a .csv file',language);
                        setMessagePopupState({isMessageVisible:true,title:txt});
                        return;
                    }

                    let index = result.uri.indexOf(',');
                    if (index!==-1)
                    {
                        const str = result.uri.substring(index+1);
                        const byteString = atob(str).replaceAll('\r','\n');

                        let arrLines = byteString.split('\n');
                        let arrSteps = [];
                        arrLines.map((item)=>{
                            if (item.indexOf('FREQ1')===-1)
                            {
                                if (item.length>0)
                                {
                                    let arrFields = strToArray(item);
                                    let txt = '';
                                    txt = txt + strToNumWaveform(arrFields[0])+',';
                                    txt = txt + validateFreq    (arrFields[1],'','')+',';
                                    txt = txt + validateFreq    (arrFields[2],'','')+',';
                                    txt = txt + validateCurrent (arrFields[3],'','')+',';
                                    txt = txt + validateDuration(arrFields[4],'','')+',';
                                    txt = txt + strToNumPolarity(arrFields[5]);

                                    console.log(txt);

                                    arrSteps.push(txt);
                                }
                            }
                        })
    
                        onValueChanged(protocolIndex,state,arrSteps,isNew);
                    }                
                }
            }
        } catch (errMsg) {
          console.log(errMsg);
        }
    }      

    //========================================================================================================

    const validateRequiredFields = (calledFor) => {

        if (state.protocolName.length==0)
        {
            const txt = LocStr('Please enter protocol name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.authorName.length==0)
        {
            const txt = LocStr('Please enter author name',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        if (state.protocolNum==='0')
        {
            const txt = LocStr('Please enter a valid\nprotocol number (1-99999)',language);
            setMessagePopupState({isMessageVisible:true,title:txt});
            return false;
        }
        /*
        if ((isNew) && (!validateProtocolNumInternally(state.protocolNum)))
        {
            const txt = LocStr('Protocol number already used.\n\nCan we offer you a vacant one?',language);
            setQuestionPopupState({isQuestionVisible:true,questionId:'protocol_num',title:txt});
            return false;
        }
        return true;
        */
        serverValidateProtocolNum(state.protocolNum,calledFor);
        return false;
    }

    //========================================================================================================

    const arr2str = (srcArr) => {
        if ((srcArr == null) || (srcArr == undefined) || (srcArr.length == 0))
        {
            return ""
        }
        return `${srcArr}`
    }

    const str2arr = (srcStr) => {
        if ((srcStr == null) || (srcStr == undefined) || (srcStr.length == 0))
        {
            return []
        }
        let newArr = srcStr.split(',').map(item => item.trim());;
       return newArr
    }

    //========================================================================================================

    const onQuestionAnswerWasNo = (questionId,userId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                userId: '',
                title: ''});
    }

    const onQuestionAnswerWasYes = (questionId,userId) => {

        setQuestionPopupState({
                isQuestionVisible: false, 
                questionId: '', 
                userId: '',
                title: ''});

        switch (questionId)
        {
            case 'protocol_num':
                serverFindVacantProtocolNum('protocol_num');                
                break;
        }
    }

    //========================================================================================================

    const onUpdatePressed = () => {

        if (!validateRequiredFields('update')) return;

        onValueChanged(protocolIndex,state,null,isNew);
    }

    //========================================================================================================

    const onImportPressed = () => {

        if (!validateRequiredFields('import')) return;

        _pickDocument();
    }

    //######################################################################
    //###   S e r v e r   s t u f f
    //######################################################################

    const serverValidateProtocolNum = async (numToCheck,calledFor) => {
        try {
            getAllProtocolsHeaders(global.token, 
                (result) => {
                    let idx = result.findIndex((item)=> {
                        return ((item.protocolNum===numToCheck) && (item._id!==protocolItem.protocolId))
                    })
                    if (idx!==-1)
                    {
                        const txt = LocStr('Protocol number already used.\n\nCan we offer you a vacant one?',language);
                        setQuestionPopupState({isQuestionVisible:true,questionId:'protocol_num',title:txt});
                    } else {
                        switch (calledFor)
                        {
                            case 'update':
                                onValueChanged(protocolIndex,state,null,isNew);
                                break;
                            case 'import':
                                _pickDocument();
                                break;
                        }
                    }
                  },
                (errMsg) => {
                    if ((isNew) && (!validateProtocolNumInternally(state.protocolNum)))
                    {
                        const txt = LocStr('Protocol number already used.\n\nCan we offer you a vacant one?',language);
                        setQuestionPopupState({isQuestionVisible:true,questionId:'protocol_num',title:txt});
                    }
                    return;
                }
            );
        } catch (err) {
        }
    }

    //======================================================================

    const handleResultOfVacantNum = (vacantNum, calledFor) => {

        let res = vacantNum;
        if (vacantNum.length==0)
        {
            res = findVacantProtocolNumInternally();
        }
        switch (calledFor)
        {
            case 'protocol_num':
                setState({...state, protocolNum:res});
                break;
        }
    }

    const serverFindVacantProtocolNum = async (calledFor) => {
        try {
            getAllProtocolsHeaders(token, 
                (result) => {

                    for (let res=10000;res<99999;res++)
                    {
                        let cmpWith=''+res;
                        let idx = result.findIndex((item)=> {
                            return (item.protocolNum===cmpWith);
                        })
                        if (idx==-1)
                        {
                            handleResultOfVacantNum(cmpWith,calledFor);
                            return;
                        } 
                    }
                    handleResultOfVacantNum('',calledFor);
                },
                (errMsg) => {
                    handleResultOfVacantNum('',calledFor);
                }
            );
        } catch (err) {
        }
    }

    //######################################################################

    return (
        <TouchableOpacity style={{  width: screenWidth,
                        height: screenHeight-60-73,
                        justifyContent:'center',
                        position:'absolute'}} 
                        activeOpacity={1}
                        onPress={() => {
                            onCanceled();
                        }}
            >
            <TouchableOpacity style={{ width:500,/*height:200,*/ alignSelf: 'center', backgroundColor: '#d9d9d9',borderRadius:8,
                                    shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 12,
                                    }}
                                    activeOpacity={1}
                                    onPress={() => {
                                    }}
                >
                <View style={{ /*width:398,height:198, height:198,position:'absolute'*/flexDirection:'column',margin:1,backgroundColor: '#fff',borderRadius:6}}>

                    <Text style={styles.lblDialogTitle} >{LocStr(isNew?'Add protocol':'Edit protocol',language)}</Text>

                    {/* -----   Protocol name   ----- */}

                    <View style={{alignSelf:'center',marginTop:16,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Protocol name:',language)}</Text>
    
                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9',flexDirection:'row'}} >

                            <TextInput style={{ margin:1,flex:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.protocolName} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=50)
                                    {
                                        setState({...state, protocolName:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput4.focus();
                                    }
                                }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Author name   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Author name:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.authorName} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if (newText.length<=30)
                                    {
                                        setState({...state, authorName:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput5.focus();
                                    }
                                }}
                                ref={(input) => { textInput4 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Protocol number   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Protocol number:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={state.protocolNum} multiline={false} selectTextOnFocus={true} maxLength={100}
                                onChangeText={(newText)=>{
                                    if ((!newText || newText.match(/^\d{1,}?$/)) && (newText.length<=5))
                                    {
                                        setState({...state, protocolNum:newText});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                        textInput6.focus();
                                    }
                                }}
                                ref={(input) => { textInput5 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Tags   ----- */}

                    <View style={{alignSelf:'center',marginTop:12,flexDirection:'row'}} >

                        <Text style={styles.lblTitle} >{LocStr('Tags:',language)}</Text>

                        <View style={{width:300,height:27,marginLeft:10,borderRadius:3,backgroundColor:'#a9a9a9'}} >

                            <TextInput style={{ margin:1,borderRadius:2,backgroundColor:'#fff',
                                borderBottomWidth:4,borderTopWidth:4,borderRightWidth:4,borderLeftWidth:4,borderColor:'#fff'}} 
                                value={arr2str(state.field_tags)} multiline={false} selectTextOnFocus={true} maxLength={100}
                                placeholder="Comma-separated list"
                                onChangeText={(newText)=>{
                                    if (newText.length<=100)
                                    {
                                        let newArr = str2arr(newText)
                                        setState({...state, field_tags:newArr});
                                    }
                                }}
                                onKeyPress={(e)=>{
                                    if(e.nativeEvent.key == "Enter"){
                                    }
                                }}
                                ref={(input) => { textInput6 = input; }}
                                />

                        </View>

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   Protected   ----- */}

                    <View style={{marginLeft:163,height:24+8,marginTop:4,flexDirection:'row'}} >

                        <MyCheckbox style={{marginLeft:0,marginTop:0,position:'absolute'}} 
                            title={LocStr('Protected', language)}
                            value={state.field_protected} 
                            onValueChanged={(value) => {
                                setState({...state, field_protected:value});
                            }} 
                            />

                    </View>

                    <View style={{alignSelf:'center',width:420,marginTop:12,height:1,backgroundColor:'#e2e8f0'}} />

                    {/* -----   buttons   ----- */}

                    <View style={{alignSelf:'center',marginTop:40,height:36,flexDirection:'row'}} >

                        <TouchableOpacity style={{width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onCanceled()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Cancel',language)}</Text>
                            </View>
                        </TouchableOpacity>

                        {((isNew) && (!isCopy))?
                        <TouchableOpacity style={{marginLeft:40,width:140,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onImportPressed()}}
                            >
                            <View style={{ width:136,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7,marginLeft:20}]}>{LocStr('Import CSV',language)}</Text>
                            </View>
                        </TouchableOpacity>:<View style={{marginLeft:40,width:140,height:36}} />}

                        <TouchableOpacity style={{marginLeft:40,width:100,height:36,backgroundColor:'#008cff',borderRadius:8}} 
                            onPress={() => {onUpdatePressed()}}
                            >
                            <View style={{ width:96,height:32, position:'absolute',margin:2,backgroundColor: '#008cff',borderRadius:6}}>
                                <Text style={[styles.lblButton,{marginTop:7}]}>{LocStr('Update',language)}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>    

                    <View style={{width:1,height:20}} />

                </View> 
            </TouchableOpacity> 

            {messagePopupState.isMessageVisible?
                <MyMessagePopup 
                    parentWidth={screenWidth}
                    onOkPressed={() => setMessagePopupState({isMessageVisible:false,title:''})}
                    okTitle={LocStr('Ok',language)}
                    message={messagePopupState.title}
                    language={language}
                    />:null
                }


            {questionPopupState.isQuestionVisible?
                <MyAskQuestionPopup 
                    parentWidth={screenWidth}
                    onYesPressed={(questionId,userId) => {onQuestionAnswerWasYes(questionId,userId)}}
                    onNoPressed={(questionId,userId) => {onQuestionAnswerWasNo(questionId,userId)}}
                    questionId={questionPopupState.questionId}
                    auxString={questionPopupState.userId}
                    yesTitle={LocStr('Yes',language)}
                    noTitle={LocStr('No',language)}
                    question={questionPopupState.title}
                    language={language}
                    />:null
                }

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    lblButton: {
        width: 96,
        fontFamily: "Rubik-Medium",
        fontSize: 16,
        color: "#fff",
        textAlign: 'center',
        position: 'absolute',
    },
    lblTitle: {
        width:120,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        //position: 'absolute',
    },
    lblSubTitle: {
        width:100,
        marginTop:5,
        fontFamily: "Rubik-Regular",
        fontSize: 14,
        color: "#001a2f",
        textAlign: 'right',
        //position: 'absolute',
    },
    lblDialogTitle: {
        width:500,
        marginTop:20,
        fontFamily: "Rubik-Medium",
        fontSize: 18,
        color: "#001a2f",
        textAlign: 'center',
        //position: 'absolute',
    },
});

export default MyEditProtocolPopup;